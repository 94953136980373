import React, { useState } from 'react'
import Chart from 'react-apexcharts';

const SalesChart = ({ labels, sales, cost, costRate }) => {
  const series = [{
    name: '売上',
    type: "bar",
    data: sales
  }, {
    name: '仕入',
    type: "bar",
    data: cost
  }, {
    name: '仕入率',
    type: "area",
    data: costRate
  }];

  const options = {
    chart: {
        type: 'bar',
        height: 300,
        fontFamily: 'inherit',
        toolbar: {
            show: false
        }
    },
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: [6],
        columnWidth: '35%',
        endingShape: 'rounded'
      },
    },
    legend: {
      show: false
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: "smooth",
      show: true,
      width: 2,
      colors: ['transparent']
    },
    xaxis: {
      categories: labels,
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      },
      labels: {
        style: {
          fontFamily: 'inherit',
          colors: KTUtil.getCssVariableValue("--bs-gray-500"),
          fontSize: "12px"
        }
      }
    },
    yaxis: [
      {
        seriesName: '売上',
        show: false
      },
      {
        seriesName: '売上',
        show: false
      },
      {
        opposite: true,
        seriesName: '仕入率',
          show: false
      }
    ],
    fill: {
      opacity: 1
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: [{
        formatter: (y) => {
          if (typeof y !== "undefined" && y !== null) {
            return "¥" + Number(y.toFixed(0)).toLocaleString();
          }
          return y;
        }
      }, {
        formatter: (y) => {
          if (typeof y !== "undefined" && y !== null) {
            return "¥" + Number(y.toFixed(0)).toLocaleString();
          }
          return y;
        }
      }, {
        formatter: (y) => {
          if (typeof y !== "undefined" && y !== null) {
            return Number(y.toFixed(2)).toLocaleString() + "%";
          }
          return y;
        }
      }]
    },
    colors: [KTUtil.getCssVariableValue("--bs-primary"), KTUtil.getCssVariableValue("--bs-danger"), KTUtil.getCssVariableValue("--bs-gray-200")],
    grid: {
      borderColor: KTUtil.getCssVariableValue("--bs-gray-200"),
      strokeDashArray: 2,
      yaxis: {
        lines: {
          show: true
        }
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    }
  };

  return (
    <div className="sales-chart">
      <Chart options={options} series={series} type="bar" height="300px" />
    </div>
  );
}

export default SalesChart;
