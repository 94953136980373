export function calcTax(num, price, tax_type, tax_rate) {
  let value;
  if (tax_type == 'internal' || tax_type == 'internal_reduced') {
    value = (num * price) - (num * price)/(1 + tax_rate);
  } else if (tax_type == 'external' || tax_type == 'external_reduced') {
    value = (num * price) * tax_rate;
  } else {
    value = 0
  }

  return Math.round(value * 100) / 100;
}

export function calcSubtotalTaxExcluded(num, price, tax_type, tax_rate) {
  let value;
  if (tax_type == 'internal' || tax_type == 'internal_reduced') {
    value = (num * price) - calcTax(num, price, tax_type, tax_rate);
  } else if (tax_type == 'external' || tax_type == 'external_reduced') {
    value = (num * price)
  } else {
    value = (num * price)
  }

  return Math.round(value * 100) / 100;
}

export function calcSubtotal(num, price, tax_type, tax_rate) {
  if(tax_type == 'internal' || tax_type == 'internal_reduced' || tax_type == 'exclude') {
    return num * price
  } else {
    return Math.round(calcSubtotalTaxExcluded(num, price, tax_type, tax_rate) + calcTax(num, price, tax_type, tax_rate))
  }  
}