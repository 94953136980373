import React from 'react';
import Chart from 'react-apexcharts';

const DonutChart = ({title, series, labels, valuePrefix}) => {
  const options = {
    chart: {
      height: '290',
      width: '100%',
      fontFamily: 'Open Sans, Noto Sans Japanese, Sans-Serif',
      type: 'donut',
      toolbar: {
        show: false
      }
    },
    dataLabels: {
      enabled: true,
      style: {
        fontFamily: 'Open Sans, Noto Sans Japanese, Sans-Serif',
        fontWeight: 700,
        colors: [KTUtil.getCssVariableValue("--bs-white")]
      }
    },
    tooltip: {
      enabled: false,
    },
    legend: {
      show: true,
      position: 'bottom',
      fontFamily: 'Open Sans, Noto Sans Japanese, Sans-Serif',
      labels: {
      colors: KTUtil.getCssVariableValue("--bs-gray-600"),
      }
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            name: {
              show: true,
              fontSize: '1rem',
              fontFamily: 'Open Sans, Noto Sans Japanese, Sans-Serif',
              fontWeight: 600,
              formatter: (val) => {
                return val;
              }
            },
            value: {
              show: true,
              fontSize: '1.8rem',
              fontFamily: 'Open Sans, Noto Sans Japanese, Sans-Serif',
              fontWeight: 600,
              color: undefined,
              offsetY: 0,
              formatter: (y) => {
                return valuePrefix + y.toLocaleString();
              }
            },
            total: {
              show: true,
              showAlways: false,
              label: title,
              fontSize: '1rem',
              fontFamily: 'Open Sans, Noto Sans Japanese, Sans-Serif',
              fontWeight: 600,
              color:  KTUtil.getCssVariableValue("--bs-gray-600"),
              formatter: (w) => {
                return valuePrefix + w.globals.seriesTotals.reduce((a, b) => {
                  return a + b;
                }, 0);
              },
            }
          }
        }
      }
    },
    labels: labels,
    colors: [KTUtil.getCssVariableValue("--bs-info"),KTUtil.getCssVariableValue("--bs-orange"), KTUtil.getCssVariableValue("--bs-teal"), KTUtil.getCssVariableValue("--bs-danger"), KTUtil.getCssVariableValue("--bs-yellow"), KTUtil.getCssVariableValue("--bs-cyan"), KTUtil.getCssVariableValue("--bs-red")],
    responsive: [{
      breakpoint: 576,
      options: {
        legend: {
          position: 'bottom'
        }
      }
    }]
  };

  return (
    <div className="p-0 py-5">
      <Chart options={options} series={series} type="donut" height="340" />
    </div>
  );
};

export default DonutChart;
