import React, { useState, useMemo } from 'react';
import Calendar from 'react-calendar';
import Modal from './modal';
import moment from "moment";

const BudgetControlCalendar = ({ year, month, dailyBudgets, dailyTrackRecords, dailyTrackRecordsIncludeTax, sales }) => {
  const [dailyBudget, setDailyBudget] = useState(null);
  const [dailyTrack, setDailyTrack] = useState(null);
  const [dailyTrackIncludeTax, setDailyTrackIncludeTax] = useState(null);
  const [currentDate, setCurrentDate] = useState(null);

  const currentMonth = useMemo(() => moment({year: year, month: month - 1, date: 1 }));

  const clickDateHandler = (date) => {
    setCurrentDate(date);
    setDailyBudget(dailyBudgets[date]);
    setDailyTrack(dailyTrackRecords[date]);
    setDailyTrackIncludeTax(dailyTrackRecordsIncludeTax[date]);
  };

  const getTileContent = ({ date, view }) => {
    if (view !== "month") {
      return null;
    }

    const day = moment(date);
    // 違う月の場合は tile を表示しない
    if (currentMonth.month() != day.month()) {
        return null;
    }

    const key = day.format("YYYY-MM-DD");
    const budget = dailyBudgets[key] ? dailyBudgets[key] : {
      customers: 0,
      sales: 0
    };

    const isFuture = moment({hour: 0}) <= day; // 当日は確定していないので、未来扱い
    // 予算
    let customers = parseInt(budget.customers);
    let sales = "¥" + Number(budget.sales.toFixed(0)).toLocaleString();

    // 差分
    let diffCustomers = 0; // 差
    let diffSales = 0; // 差
    let changeCustomers = "-"; // 差分表示
    let changeSales = "-"; // 差分表示
    if (!isFuture) {
      diffCustomers = dailyTrackRecords[key].customers - customers;
      changeCustomers = (diffCustomers >= 0 ? "+" : "") + diffCustomers.toString();

      diffSales = dailyTrackRecords[key].sales - budget.sales;
      changeSales = (diffSales >= 0 ? "+" : "-") + "¥" + Number(Math.abs(diffSales).toFixed(0)).toLocaleString();

      // 未来でなければ表示する数値を実績に上書き
      customers = dailyTrackRecords[key].customers;
      sales = "¥" + Number(dailyTrackRecords[key].sales.toFixed(0)).toLocaleString();
    }

    // font color
    let colorBudget = "text-muted";
    let colorDiffCustomers = "text-muted";
    let colorDiffSales = "text-muted";
    if (!isFuture) {
      colorBudget = "text-gray-700";
      colorDiffCustomers = diffCustomers >= 0 ? "badge badge-light-success day_diff fs-7" : "badge badge-light-danger day_diff";
      colorDiffSales = diffSales >= 0 ? "badge badge-light-success day_diff fs-7" : "badge badge-light-danger day_diff";
    }

    return (
      <div
        className="fc-daygrid-day-frame fc-scrollgrid-sync-inner"
        data-bs-toggle={ isFuture ? "" : "modal" }
        data-bs-target="#kt_modal_daily_detail"
        onClick={() => {clickDateHandler(key)}}
      >
        <div className="bg-hover-light overlay pb-3 px-3 d-flex-mobile">
          <div className="flex-stack-mobile">
            <div className="d-flex flex-stack mb-0 fs-8 text-gray-500">
              <div className="align-items-center">客数</div>
              <div className="align-items-center">売上</div>
            </div>
            <div className="d-flex flex-stack">
              <div className={colorBudget}>{customers}</div>
              <div className={colorBudget}>{sales}</div>
            </div>
            <div className="d-flex flex-stack mt-1">
              <div className={colorDiffCustomers}>
                {changeCustomers}
              </div>
              <div className={colorDiffSales}>
                {changeSales}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const formatDay = (locale, date) => {
    const day = moment(date);
    const dayOfWeeks = ["日", "月", "火", "水", "木", "金", "土"];
    const dayOfWeek = dayOfWeeks[day.weekday()];
    return (
      <>
        {day.format('D')}
        <small>日</small>
        <span className="day-mobile"><small>({dayOfWeek})</small></span>
      </>
    );
  };

  return (
    <>
      <div className="calendar">
        <Calendar
          calendarType="US"
          className="is-fullwidth"
          locale="ja-JP"
          minDetail="month"
          defaultActiveStartDate={new Date(currentMonth.year(), currentMonth.month(), 1)}
          tileContent={getTileContent.bind(this)}
          minDate={currentMonth.startOf('month').toDate()}
          maxDate={currentMonth.endOf('month').toDate()}
          formatDay={formatDay}
          prevLabel={null}
          prev2Label={null}
          nextLabel={null}
          next2Label={null}
          showNavigation={false}
        >
        </Calendar>
      </div>
      <Modal date={currentDate} dailyBudget={dailyBudget} dailyTrackRecord={dailyTrack} dailyTrackRecordIncludeTax={dailyTrackIncludeTax} sales={sales} />
    </>
  );
};

export default BudgetControlCalendar;
