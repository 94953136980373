import React, { useState } from "react";
import arrayMove from "array-move";
import axios from "../../../lib/axios";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  useReactTable
} from "@tanstack/react-table";
import {DndProvider, useDrag, useDrop} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";

const columnHelper = createColumnHelper();

const columns = [
  columnHelper.accessor(row => row.name, {
    id: 'name',
    cell: props => props.getValue(),
    header: '商品名',
    headerClass: 'min-w-150px pt-5',
    bodyClass: 'fw-bold',
    filterFn: 'itemFilter'
  }),
  columnHelper.accessor(row => row.code, {
    id: 'code',
    cell: props => props.getValue(),
    header: '商品コード',
    headerClass: 'min-w-100px pt-5',
  }),
  columnHelper.accessor(row => row.status, {
    id: 'status',
    cell: props => {
      return (
        <div className='badge badge-light fw-bolder'>{props.getValue()}</div>
      )
    },
    header: 'ステータス',
    headerClass: 'min-w-75px pt-5',
    filterFn: 'itemFilter'
  }),
  columnHelper.accessor(row => row.unit, {
    id: 'unit',
    cell: props => {
      return (
        <div className='badge badge-light fw-bolder'>{props.getValue()}</div>
      )
    },
    header: '単位',
    headerClass: 'min-w-75px pt-5',
  }),
  columnHelper.accessor(row => row.tax_type, {
    id: 'tax_type',
    cell: props => {
      return (
        <div className='badge badge-light fw-bolder'>{props.getValue()}</div>
      )
    },
    header: '税区分',
    headerClass: 'min-w-75px pt-5',
  }),
  columnHelper.accessor(row => row.id, {
    id: 'action',
    cell: props => {
      return (
        <a href={`/shop_vendors/${props.row.original.shop_vendor_id}/items/${props.row.original.id}/edit`}>
          <button className="btn btn-sm btn-light-primary">
            編集
          </button>
        </a>
      )
    },
    header: 'アクション',
    headerClass: 'min-w-100px text-end pt-5',
    bodyClass: 'text-end'
  }),
]

const DraggableRow = ({ row, reorderRow }) => {
  const [, dropRef] = useDrop({
    accept: 'row',
    drop: (draggedRow) => reorderRow(draggedRow.index, row.index),
  })

  const [{ isDragging }, dragRef, previewRef] = useDrag({
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
    item: () => row,
    type: 'row',
  })

  return (
    <tr
      ref={previewRef} //previewRef could go here
      style={{ opacity: isDragging ? 0.5 : 1 }}
    >
      <td ref={dropRef}>
        <a className="btn btn-icon btn-sm btn-hover-light-primary draggable-handle" href="#">
          <i className="ki-solid ki-burger-menu-3 fs-4 text-muted" ref={dragRef} />
        </a>
      </td>
      {row.getVisibleCells().map(cell => (
        <td key={cell.id} className={cell.column.columnDef.bodyClass}>
          {flexRender(cell.column.columnDef.cell, cell.getContext())}
        </td>
      ))}
    </tr>
  )
}

const List = ({ defaultList, shop_vendor_id, is_infomart }) => {
  const [data, setData] = useState(() => [...defaultList])
  const [filterConditions, setFilterConditions] = useState([]);

  const ItemFilter = (row, id) => {
    const condition = filterConditions.find((condition) => condition.id === id && condition.value);

    if (!condition || condition.value === null) {
      return true;
    }

    if (id === 'name') {
      return row.getValue(id).includes(condition.value);
    } else {
      return row.getValue(id) === condition.value;
    }
  };

  const table = useReactTable({
    data,
    columns: is_infomart ? columns.slice(0, -1) : columns,
    getCoreRowModel: getCoreRowModel(),
    filterFns: {
      itemFilter: ItemFilter
    },
    state: {
      columnFilters: filterConditions,
    },
    getFilteredRowModel: getFilteredRowModel(),
  })

  const handleReorder = (oldIndex, newIndex) => {
    const newItems = arrayMove(table.getRowModel().rows, oldIndex, newIndex);
    const ids = newItems.map((item) => item.original.id);
    axios.post(`/api/shop_vendors/${shop_vendor_id}/shop_vendor_items/reorder`, { ids: ids })
      .then((response) => {
        // Nothing todo
      })
      .catch((error) => {
        alert('並び替えの保存に失敗しました');
      });
  }

  const reorderRow = (draggedRowIndex, targetRowIndex) => {
    data.splice(targetRowIndex, 0, data.splice(draggedRowIndex, 1)[0])
    handleReorder(draggedRowIndex, targetRowIndex);
    setData([...data])
  }

  const onChangeCondition = (id, value) => {
    setFilterConditions([
      ...filterConditions.filter((condition) => condition.id !== id),
      { id, value }
    ]);
  }

  return (
    <div className="card mb-5 mb-xl-10">
      <div className="card-header align-items-center py-5 gap-2 gap-md-5">
        <div className="card-title">
          <div className="d-flex align-items-center position-relative my-1">
            <i className="ki-outline ki-magnifier fs-3 text-gray-500 position-absolute ms-4">
            </i>
            <input type="text" className="form-control form-control-solid w-250px ps-12" placeholder="商品名で検索" onChange={(e) => onChangeCondition('name', e.target.value)} />
          </div>
        </div>
        <div className="card-toolbar flex-row-fluid justify-content-end gap-5">
          <div className="w-100 d-flex justify-content-end align-items-center">
            <div className="fw-bold text-gray-500 fs-7 me-5">ステータス:</div>
            <div className="form-check form-check-custom form-check-solid me-5">
              <input className="form-check-input" type="radio" id="all" value="" checked={(filterConditions.find((condition) => condition.id === 'status')?.value ?? "") === ""} onChange={(e) => onChangeCondition('status', e.target.value)} />
              <label className="form-check-label text-gray-600" htmlFor="all">
                すべて
              </label>
            </div>
            <div className="form-check form-check-custom form-check-solid me-5">
              <input className="form-check-input" type="radio" id="show" value="表示" checked={(filterConditions.find((condition) => condition.id === 'status')?.value ?? "") === "表示"} onChange={(e) => onChangeCondition('status', e.target.value)} />
              <label className="form-check-label text-gray-600" htmlFor="show">
                表示
              </label>
            </div>
            <div className="form-check form-check-custom form-check-solid">
              <input className="form-check-input" type="radio" id="hide" value="非表示" checked={(filterConditions.find((condition) => condition.id === 'status')?.value ?? "") === "非表示"} onChange={(e) => onChangeCondition('status', e.target.value)} />
              <label className="form-check-label text-gray-600" htmlFor="hide">
                非表示
              </label>
            </div>
          </div>
        </div>
      </div>
      <div className="card-body p-0">
        <div className="table-responsive">
          <DndProvider backend={HTML5Backend}>
            <table className="table align-middle table-row-bordered gy-4 gs-7">
              <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id} className="fw-bolder fx-7 text-muted">
                  <th key='id' className="w-50px">
                  </th>
                  {headerGroup.headers.map((header) => (
                    <th key={header.id} className={header.column.columnDef.headerClass}>
                      {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                    </th>
                  ))}
                </tr>
              ))}
              </thead>
              <tbody>
                {table.getRowModel().rows.map(row => (
                  <DraggableRow key={row.id} row={row} reorderRow={reorderRow} />
                ))}
              </tbody>
            </table>
          </DndProvider>
        </div>
      </div>
    </div>
  );
};

export default List;
