import React, { useState } from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';

const InputNumberFormat = ({ name, value, ...props }) => {
  const [originalValue, setOriginalValue] = useState(value);

  return (
    <>
      <NumberFormat
        name={'formatted-' + name}
        value={originalValue}
        {...props}
        thousandSeparator={true}
        onValueChange={(values) => {
          const {formattedValue, value} = values;
          setOriginalValue(value);
        }}
        style={{textAlign: 'right'}}
      />
      <input type="hidden" name={name} value={originalValue} />
    </>
  )
};

export default InputNumberFormat;
