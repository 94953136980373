import React, { Component } from 'react'
import PropTypes from 'prop-types'
import AddOrderButton from './components/add_order_button'
import OrderSearch from './components/order_search'
import OrderTabs from './components/order_tabs'
import OrderDetail from './components/order_detail'
import OrderFormData from './components/order_form_data'
import OrderInfo from "./components/order_info";
import EditorStyle from './editor_style';
import { calcTax, calcSubtotalTaxExcluded, calcSubtotal } from './tax_calculator';

export default class DeliveryNotePhotoEditor extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      shopVendor: this.props.deliveryNotePhoto.shopVendor ? this.props.deliveryNotePhoto.shopVendor : this.props.arrivalShopVendor,
      baskets: this.props.baskets,
      currentBasketIndex: 0,
      isOrderSearchVisible: false,
      isAddItemVisible: false,
      isAddItemFromOrderVisible: false,
      isEditable: this.props.isEditable
    }

    this.changeResisterButtonState(this.state.shopVendor == null)

    this.onShopVendorSelected = this.onShopVendorSelected.bind(this)
    this.editorStyle = new EditorStyle(this.props.app);
  }

  // 注文追加Viewの表示／非表示
  showOrderSearch = (e) => {
    e.preventDefault();
    this.setState({isOrderSearchVisible: true});
  }

  hideOrderSearch = (e) => {
    e.preventDefault();
    this.setState({isOrderSearchVisible: false});
  }

  // 現在選択されている注文の切り替え
  changeCurrentBasket = (index, e) => {
    this.setState({
      currentBasketIndex: index,
      isAddItemVisible: false,
      isAddItemFromOrderVisible: false
    })
  }

  // 注文の追加・削除
  addNewBasket = (order) => {
    if (this.state.baskets.findIndex((b) => (b.order ? b.order.id == order.id : false)) >= 0){
      alert('この注文はすでに登録対象です。');
      return
    }

    const newBasket = {
      arrivalType: "internal",
      order: order,
      arrivalItems: []
    }
    let baskets = this.state.baskets;
    baskets.push(newBasket);
    this.setState({baskets: baskets, isOrderSearchVisible: false});
  }

  removeBasket = (index, e) => {
    e.preventDefault();
    let result = confirm("本当に削除してよろしいですか？");
    if(result){
      let newBaskets = this.state.baskets.filter((basket, i) => i != index);
      let newIndex = this.state.currentBasketIndex > 0 ? this.state.currentBasketIndex - 1 : 0;
      this.setState({baskets: newBaskets, currentBasketIndex: newIndex});
    }
  }

  // 商品追加Viewの表示／非表示
  showAddItem = (e) => {
    e.preventDefault();
    this.setState({isAddItemVisible: true});
  }

  hideAddItem = (e) => {
    e.preventDefault();
    this.setState({isAddItemVisible: false});
  }

  // 商品の追加・削除
  handleAddItem = (officialItem, arrivalNum, price, subtotalTaxExcluded, tax, subtotal, e) => {
    e.preventDefault();
    const arrivalItem = this.createArrivalItem(officialItem.id,
                                              officialItem.name,
                                              officialItem.code,
                                              officialItem.taxTypeName,
                                              "",
                                              arrivalNum,
                                              price,
                                              officialItem.taxRate,
                                              subtotalTaxExcluded,
                                              officialItem.taxType,
                                              tax,
                                              subtotal,
                                              officialItem.unofficialItemId)
    let baskets = this.state.baskets;
    let basket = baskets[this.state.currentBasketIndex];
    basket.arrivalItems.push(arrivalItem);
    this.setState({baskets: baskets, isAddItemVisible: false})
  }

  // 発注情報から追加Viewの表示／非表示
  showAddItemFromOrder = (e) => {
    e.preventDefault();
    this.setState({isAddItemFromOrderVisible: true})
  }

  // 発注情報から追加Viewの表示／非表示
  hideAddItemFromOrder = (e) => {
    e.preventDefault();
    this.setState({isAddItemFromOrderVisible: false})
  }

  handleAddItemFromOrder = (shippingItem, subtotalTaxExcluded, tax, subtotal, e) => {
    e.preventDefault();
    const arrivalItem = this.createArrivalItem(shippingItem.officialItem.id,
                                `${shippingItem.officialItem.name}（${shippingItem.officialItem.unitName}）`,
                                                shippingItem.officialItem.code,
                                                shippingItem.officialItem.taxTypeName,
                                                shippingItem.num,
                                                shippingItem.shippingNum,
                                                shippingItem.officialItem.latestPrice,
                                                shippingItem.officialItem.taxRate,
                                                subtotalTaxExcluded,
                                                shippingItem.officialItem.taxType,
                                                tax,
                                                subtotal, // handleAddItemFromOrderは廃止予定なので仮の値を入れている
                                                shippingItem.officialItem.unofficialItemId)
    let baskets = this.state.baskets;
    let basket = baskets[this.state.currentBasketIndex];
    basket.arrivalItems.push(arrivalItem)
    this.setState({baskets: baskets})
  }

  createArrivalItem = (officialItemId, officialItemName, officialItemCode, officialItemTaxTypeName, orderNum, arrivalNum, price, taxRate, subtotalTaxExcluded, taxType, tax, subtotal, unofficialItemId) => {

    const arrivalItem = {
      id: "",
      arrivalId: "",
      name: officialItemName,
      orderNum: orderNum ? orderNum: "",
      arrivalNum: arrivalNum,
      price: price,
      taxRate: taxRate,
      subtotal: subtotal,
      subtotalTaxExcluded: subtotalTaxExcluded,
      taxType: taxType,
      tax: tax,
      subtotal: subtotal,
      officialItemId: officialItemId,
      officialItemName: officialItemName,
      officialItemCode: officialItemCode,
      officialItemTaxTypeName: officialItemTaxTypeName,
      unofficialItemId: unofficialItemId
    }

    return arrivalItem
  }

  handleArrivalNumChange = (itemIndex, e) => {
    e.preventDefault();
    let baskets = this.state.baskets;
    let arrivalItems = baskets[this.state.currentBasketIndex].arrivalItems;
    arrivalItems[itemIndex].arrivalNum = e.target.value;
    if (arrivalItems[itemIndex].price && e.target.value) {
      arrivalItems[itemIndex].subtotalTaxExcluded = calcSubtotalTaxExcluded(e.target.value, arrivalItems[itemIndex].price, arrivalItems[itemIndex].taxType, arrivalItems[itemIndex].taxRate);

      arrivalItems[itemIndex].tax = calcTax(e.target.value, arrivalItems[itemIndex].price, arrivalItems[itemIndex].taxType, arrivalItems[itemIndex].taxRate);
      arrivalItems[itemIndex].subtotal = calcSubtotal(e.target.value, arrivalItems[itemIndex].price, arrivalItems[itemIndex].taxType, arrivalItems[itemIndex].taxRate);
    }
    baskets[this.state.currentBasketIndex].arrivalItems = arrivalItems
    this.setState({baskets: baskets})
  }

  handlePriceChange = (itemIndex, e) => {
    e.preventDefault()
    let baskets = this.state.baskets
    let arrivalItems = baskets[this.state.currentBasketIndex].arrivalItems
    arrivalItems[itemIndex].price = e.target.value
    if (arrivalItems[itemIndex].arrivalNum && e.target.value) {
      arrivalItems[itemIndex].subtotalTaxExcluded = calcSubtotalTaxExcluded(arrivalItems[itemIndex].arrivalNum, e.target.value, arrivalItems[itemIndex].taxType, arrivalItems[itemIndex].taxRate);
      arrivalItems[itemIndex].tax = calcTax(arrivalItems[itemIndex].arrivalNum, e.target.value, arrivalItems[itemIndex].taxType, arrivalItems[itemIndex].taxRate);
      arrivalItems[itemIndex].subtotal = calcSubtotal(arrivalItems[itemIndex].arrivalNum, e.target.value, arrivalItems[itemIndex].taxType, arrivalItems[itemIndex].taxRate);
    }
    baskets[this.state.currentBasketIndex].arrivalItems = arrivalItems
    this.setState({baskets: baskets})
  }

  handleSubtotalTaxExcludedChange = (itemIndex, e) => {
    e.preventDefault();
    let baskets = this.state.baskets;
    let arrivalItems = baskets[this.state.currentBasketIndex].arrivalItems;
    arrivalItems[itemIndex].subtotalTaxExcluded = e.target.value;
    baskets[this.state.currentBasketIndex].arrivalItems = arrivalItems
    this.setState({baskets: baskets})
  }

  handleTaxChange = (itemIndex, e) => {
    e.preventDefault();
    let baskets = this.state.baskets;
    let arrivalItems = baskets[this.state.currentBasketIndex].arrivalItems;
    arrivalItems[itemIndex].tax = e.target.value;
    baskets[this.state.currentBasketIndex].arrivalItems = arrivalItems
    this.setState({baskets: baskets})
  }

  handleSubtotalChange = (itemIndex, e) => {
    e.preventDefault();
    let baskets = this.state.baskets;
    let arrivalItems = baskets[this.state.currentBasketIndex].arrivalItems;
    arrivalItems[itemIndex].subtotal = e.target.value;
    baskets[this.state.currentBasketIndex].arrivalItems = arrivalItems
    this.setState({baskets: baskets})
  }

  handleRemoveItem = (removeItemIndex, e) => {
    e.preventDefault();
    let result = confirm("本当に削除してよろしいですか？");
    if(result){
      let baskets = this.state.baskets;
      let arrivalItems = baskets[this.state.currentBasketIndex].arrivalItems;
      arrivalItems = arrivalItems.filter((item, i) => i != removeItemIndex);
      baskets[this.state.currentBasketIndex].arrivalItems = arrivalItems
      this.setState({baskets: baskets})
    }
  }

  changeResisterButtonState(disabled) {
    $('.is-link').prop('disabled', disabled)
  }

  onShopVendorSelected(selectedShopVendor) {
    let currentShopVendorId = this.state.shopVendor ? this.state.shopVendor.id : null
    if (selectedShopVendor.id != currentShopVendorId) {
      let basket = { 'arrivalType': 'external', 'order': null, 'arrivalItems': [] }
      this.setState({
        shopVendor: selectedShopVendor,
        baskets: [basket],
        currentBasketIndex: 0,
        isAddItemVisible: false,
        isAddItemFromOrderVisible: false
      });
    }
    this.changeResisterButtonState(selectedShopVendor == null)
  }

  render() {
    const { shopVendor,
            baskets,
            currentBasketIndex,
            isOrderSearchVisible,
            isAddItemVisible,
            isAddItemFromOrderVisible,
            isEditable
          } = this.state;
          
    const { deliveryNotePhoto, latestDeliveryNotePhotoEditLog } = this.props;
    return (
      <>
        <OrderInfo
          deliveryNotePhoto={deliveryNotePhoto}
          latestDeliveryNotePhotoEditLog={latestDeliveryNotePhotoEditLog}
          defaultShopVendor={shopVendor}
          onShopVendorSelected={this.onShopVendorSelected}
          editorStyle={this.editorStyle}
          isEditable={isEditable}
        />        
        <div className={`${this.editorStyle.box()} ${this.editorStyle.marginTop()}`}>
          <div className={this.editorStyle.boxDetail()}>
            { baskets.length == 0 ?
              "納品明細がまだ登録されていません。"
              :
              <>
                <OrderTabs
                  baskets={baskets}
                  currentBasketIndex={currentBasketIndex}
                  changeCurrentBasket={this.changeCurrentBasket}
                  editorStyle={this.editorStyle}
                />
                <OrderDetail
                  deliveryNotePhoto={deliveryNotePhoto}
                  shopVendor={shopVendor}
                  basket={baskets[currentBasketIndex]}
                  currentBasketIndex={currentBasketIndex}
                  isAddItemButtonDisabled={this.state.shopVendor == null}
                  isAddItemVisible={isAddItemVisible}
                  isAddItemFromOrderVisible={isAddItemFromOrderVisible}
                  removeBasket={this.removeBasket}
                  showAddItem={this.showAddItem}
                  hideAddItem={this.hideAddItem}
                  showAddItemFromOrder={this.showAddItemFromOrder}
                  hideAddItemFromOrder={this.hideAddItemFromOrder}
                  handleAddItem={this.handleAddItem}
                  handleRemoveItem={this.handleRemoveItem}
                  handleArrivalNumChange={this.handleArrivalNumChange}
                  handlePriceChange={this.handlePriceChange}
                  handleSubtotalTaxExcludedChange={this.handleSubtotalTaxExcludedChange}
                  handleTaxChange={this.handleTaxChange}
                  handleSubtotalChange={this.handleSubtotalChange}
                  handleAddItemFromOrder={this.handleAddItemFromOrder}
                  editorStyle={this.editorStyle}
                  isEditable={isEditable}
                />
                <OrderFormData
                  shopVendor={shopVendor}
                  baskets={baskets}
                />
              </>               
              }
            </div>
        </div>
      </>
    )
  }
}

DeliveryNotePhotoEditor.propTypes = {
  deliveryNotePhoto: PropTypes.object.isRequired,
  baskets: PropTypes.array.isRequired,
  arrivalShopVendor: PropTypes.object
}
