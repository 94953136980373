import React, { Component } from 'react';
import PropTypes from "prop-types";

export default class AddOrderButton extends Component {
  constructor(props) {
    super(props)

    this.editorStyle = this.props.editorStyle
  }

  render () {
    const { showOrderSearch, disabled } = this.props;
    return (
      <>
        <div className={this.editorStyle.marginTop()} align="right">
          <button className={this.editorStyle.buttonLight()} disabled={disabled} onClick={showOrderSearch}>＋注文を追加</button>
        </div>
      </>
    )
  }
}

AddOrderButton.propTypes = {
  showOrderSearch: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired
}