import React, { useMemo } from 'react';

const Margin = ({ marginRate, price, trialMarginRate, trialPrice, editable, style }) => {
  const margin = useMemo(() => {
    return Math.round(price * marginRate / 100);
  });

  const trialMargin = useMemo(() => {
    return Math.round(trialPrice * trialMarginRate / 100);
  });

  return (
    <div class="card card-bordered rounded-0 min-h-350px">
      <div class="card-header px-4 pt-1 border-bottom-0">
        <div class="card-title fs-9 text-gray-400">
          <div class="symbol symbol-30px me-3">
            <div class="symbol-label fs-6 fw-bolder bg-gray text-white">M</div>
          </div>
          <div class="flex-column">
            <div class="text-gray-500 fs-9">Margin</div>
            <div class="text-gray-600 fs-8">粗利単価</div>
          </div>
        </div>
      </div>
      <div class="card-body px-4 pb-4 pt-0">        
        <div class="fs-4 fw-bolder mb-2 text-gray-600">
          ¥{margin.toLocaleString()}
        </div>
        <div class="fs-1 fw-bolder my-6 pb-0">
          ¥{trialMargin != null ? trialMargin.toLocaleString() : '--'}
        </div>
        <div class="separator mt-5 mb-5"></div>
        <div class="fs-8 fw-bolder text-gray-600 mb-2">粗利率
        </div>
        <div class="d-flex justify-content-between mobile-flex-column">
          <div>
            <div class="fs-4 fw-bolder mb-6 text-gray-600 mt-3">
              {marginRate}%
            </div>
          </div>
          <div>
            <div class="fs-1 fw-bolder mb-4 py-2">
              {trialMarginRate != null ? trialMarginRate.toLocaleString() : '--'}%
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Margin;
