import React from 'react';
import Chart from 'react-apexcharts';

const ColumnAreaCenterZeroChart = ({categories, trackName, ratioName, tracks, ratios}) => {
  const roundUpToNextNiceNumber = (num) => {
    // 桁数に基づいた10のべき乗の最小値を見つける
    // e.g. 223812 -> 100000
    let magnitude = Math.pow(10, Math.floor(Math.log10(num)));

    // 最高位の数字を取得
    // e.g. 223812 -> 2
    let firstDigit = Math.floor(num / magnitude);

    // 最高位の数字をインクリメントしてその桁で丸める
    return (firstDigit + 1) * magnitude;
  };

  const range = (values) => {
    const min = Math.abs(Math.min(...values));
    const max = Math.abs(Math.max(...values));

    // 絶対値が大きい方に合わせる
    const range =  min > max ? min : max;

    return range === 0 ? 1 : roundUpToNextNiceNumber(range); // もし、0 だったら範囲がなくなってしまうので 1 にする
  };

  const tracksRange = range(tracks);
  const ratiosRange = range(ratios);

  const series = [
    {
      name: trackName,
      type: 'column',
      data: tracks
    },
    {
      name: ratioName,
      type: 'area',
      data: ratios
    }
  ];
  const options = {
    chart: {
      type: 'area',
      height: '200',                                                                                                                 fontFamily: 'Open Sans, Noto Sans Japanese, Sans-Serif',                                                                       toolbar: {
        show: false
      }
    },
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: [2],
        columnWidth: '50%',
        endingShape: 'rounded'
      }
    },
    stroke: {
      curve: 'smooth',
      width: [0],
    },
    fill: {
      type:'solid',
      opacity: [0.9, 0.9],
    },
    legend: {
      show: false
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: categories,
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      },
      labels: {
        show: true,
        rotate: 0,
        hideOverlappingLabels: true,
        style: {
          fontFamily: 'Open Sans, Noto Sans Japanese, Sans-Serif',
          colors: KTUtil.getCssVariableValue("--bs-gray-500"),
          fontSize: "11px"
        }
      }
    },
    yaxis: [
      {
        labels: {
          show: true,
          rotate: 0,
          hideOverlappingLabels: true,
          style: {
            fontFamily: 'Open Sans, Noto Sans Japanese, Sans-Serif',
            colors: KTUtil.getCssVariableValue("--bs-gray-500"),
            fontSize: "11px"
          }
        },
        min: -1 * tracksRange,
        max: tracksRange,
        stepSize: 10000
      },
      {
        opposite: true,
        labels: {
          style: {
            fontFamily: 'Open Sans, Noto Sans Japanese, Sans-Serif',
            colors: KTUtil.getCssVariableValue("--bs-gray-500"),
            fontSize: "11px"
          }
        },
        min: -1 * ratiosRange,
        max: ratiosRange
      }
    ],
    tooltip: {
      shared: true,
      intersect: false,
      y: [{
        formatter: (y) => {
          if (typeof y !== "undefined") {
            return "¥" + y.toLocaleString();
          }
          return y;
        }
      }, {
        formatter: (y) => {
          if (typeof y !== "undefined") {
            return y.toFixed(1) + "%";
          }
          return y;
        }
      }]
    },
    colors: [KTUtil.getCssVariableValue("--bs-primary"),'#C8F1E3'],
    grid: {
      borderColor: KTUtil.getCssVariableValue("--bs-gray-200"),
      strokeDashArray: 2,
      yaxis: {
        lines: {
          show: true
        }
      },
      padding: {
        top: 0,
        right: 10,
        bottom: 0,
        left: 10
      }
    }
  };

  return (
    <div className="mx-n9 pt-0 pb-0">
      <Chart options={options} series={series} type="area" height="200" />
    </div>
  );
};

export default ColumnAreaCenterZeroChart;
