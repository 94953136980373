import React from 'react'
import PropTypes from 'prop-types'

export default class Form extends React.Component {
  constructor(props) {
    super(props)

    this._handleClickRemove = this._handleClickRemove.bind(this)
  }

  render() {
    // 数が多い場合のスクロール
    return (
      <div className="box">
        <h2 className="title is-4">発注書（登録商品一覧）</h2>
        <table className="table no-footer is-striped is-fullwidth">
          <thead>
            <tr>
              <th>商品名</th>
              <th>商品コード</th>
              <th>単位</th>
              <th>価格</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {this._renderItems()}
          </tbody>
        </table>
      </div>
    )
  }

  /**
   * 商品一覧を表示
   */
  _renderItems() {
    return this.props.items.map((item, i) => {
      // 削除処理
      return (
        <tr key={i}>
          <td>{item.name}</td>
          <td>{item.code}</td>
          <td>{item.unit_name}</td>
          <td>{item.price}</td>
          <td>
            <button className="button is-danger" data-index={i} onClick={this._handleClickRemove}>削除</button>
          </td>
        </tr>
      )
    })
  }

  /**
   * 削除ボタンを押した
   */
  _handleClickRemove(e) {
    const index = e.currentTarget.dataset.index

    this.props.onRemove(e, this.props.items[index])
  }
}

Form.propTypes = {
  items: PropTypes.array.isRequired,
  onRemove: PropTypes.func.isRequired
}
