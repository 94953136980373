import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { PieChart, Pie, Sector, Cell,Tooltip, Legend, ResponsiveContainer, Label } from 'recharts';

const COLORS = ['#0088FE', '#FFFFFF'];

export default class AchievementChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      values: [
        { name: 'Group A', value: props.value },
        { name: 'Group B', value: 100 - props.value }
      ]
    };
  }

  render() {
    return (
      <ResponsiveContainer height={200} width="100%">
        <PieChart width="100%" onMouseEnter={this.onPieEnter}>
          <Pie
            data={this.state.values}
            innerRadius={60}
            outerRadius={80}
            fill="#8884d8"
            paddingAngle={5}
            dataKey="value"
            startAngle={-270}
          >
            {this.state.values.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
            <Label
              value={`${this.props.value}%`}
              position="center"
              fill="grey"
              style={{
                fontSize: "28px",
                fontWeight: "bold",
                fontFamily: "Roboto"
              }}
            />
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    );
  }
}
