import React, { useState } from "react";
import { ReactSortable } from "react-sortablejs";
import margin from "../monthly_budget_setting/cards/margin";
import arrayMove from "array-move";
import axios from "../../../lib/axios";

const List = ({ defaultList }) => {
  const [state, setState] = useState(defaultList);

  const handleReorder = (evt) => {
    const newItems = arrayMove(state, evt.oldIndex, evt.newIndex);
    const ids = newItems.map((item) => item.id);
    axios.post('/api/shop_vendors/reorder', { ids: ids })
      .then((response) => {
        // Nothing todo
      })
      .catch((error) => {
        alert('並び替えの保存に失敗しました');
      });
  }

  const renderBody = () => {
    return state.map((item) => {
      return (
        <tr className="col draggable" key={item.id}>
          <td>
            <a className="btn btn-icon btn-sm btn-hover-light-primary draggable-handle" href="#">
              <i className="ki-solid ki-burger-menu-3 fs-4 text-muted" />
            </a>
          </td>
          <td className="fw-bold">
            {item.name}
            {item.is_infomart ? <span className="badge badge-primary fw-bolder ms-2">インフォマート連携</span> : null}
          </td>
          <td>
            <div className="badge badge-light fw-bolder">{item.category}</div>
          </td>
          <td className="text-end">
            <a href={`/shop_vendors/${item.id}/items`} className="d-inline p-2">
              <button className="btn btn-sm btn-light-primary">
                商品管理
              </button>
            </a>
            { item.is_infomart ? null : (
              <a href={`/shop_vendors/${item.id}`}>
                <button className="btn btn-sm btn-light-primary">
                  編集
                </button>
              </a>
            ) }            
          </td>
        </tr>
      );
    });
  }

  return (
    <div className="table-responsive">
      <table className="table align-middle table-row-bordered gy-4 gs-7">
        <thead>
          <tr className="fw-bolder fx-7 text-muted">
            <th className="w-50px"></th>
            <th className="min-w-150px pt-5">発注先名</th>
            <th className="min-w-150px pt-5">カテゴリ</th>
            <th className="min-w-100px text-end pt-5">アクション</th>
          </tr>
        </thead>
        <ReactSortable
          tag="tbody"
          className="draggable-zone"
          list={state}
          setList={setState}
          onUpdate={handleReorder}
        >
          {renderBody()}
        </ReactSortable>
      </table>
    </div>
  );
};

export default List;
