import React, { useState } from 'react';
import Select from 'react-select';

const formatGroupLabel = () => (
  <div>
    <span>選択してください</span>
  </div>
);

const SelectItem = ({ name, options, defaultValue, isDisabled }) => {
  const [selectedOption, setSelectedOption] = useState(defaultValue || null);

  return (
    <div className="field">
      <Select
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            minHeight: 'calc(1.5em + 1.65rem + 2px)',
            padding: '0.425rem 1.5rem',
            fontSize: '1.15rem',
            borderRadius: '0.625rem',
            borderColor: '#e4e6ef',
          }),
          placeholder: (baseStyles, state) => ({
            ...baseStyles,
            color: '#b5b5c2',
            fontWeight: 500,
          }),
          valueContainer: (baseStyles, state) => ({
            ...baseStyles,
            padding: '0px',
          }),
        }}
        name={name}
        className="basic-single"
        classNamePrefix="select"
        isDisabled={isDisabled}
        isClearable={true}
        isSearchable={true}
        placeholder="選択してください"
        options={options}
        defaultValue={selectedOption}
        components={{
          IndicatorSeparator: () => null,
        }}
        formatGroupLabel={formatGroupLabel}
      />
    </div>
  );
};

export default SelectItem;
