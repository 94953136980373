import React from 'react'
import PropTypes from 'prop-types'
import Form from './form'
import Items from './items'
import axios from 'axios'

export default class OfficialOrderFormEditor extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      officialOrderFormItems: props.officialOrderFormItems, // 発注書の商品
      officialItems: [], // 全デジタル連携商品
      officialItemsTotal: 0
    }

    this._handleSearchItems = this._handleSearchItems.bind(this)
    this._handleAddItem = this._handleAddItem.bind(this)
    this._handleRemoveItem = this._handleRemoveItem.bind(this)

    this._handleSearchItems('', 1) // 初期化
  }

  render() {
    return (
      <div className="columns">
        <div className="column is-half">
          <Form items={this.state.officialOrderFormItems} onRemove={this._handleRemoveItem} />
        </div>
        <div className="column is-half">
          <Items
            items={this.state.officialOrderFormItems}
            officialItems={this.state.officialItems}
            officialItemsTotal={this.state.officialItemsTotal}
            onAdd={this._handleAddItem}
            onSearch={this._handleSearchItems}
          />
        </div>
      </div>
    )
  }

  /**
   * 商品検索
   */
  _handleSearchItems(keyword, page) {
    const params = {
      'q[name_or_code_cont]': keyword,
      page: page
    }
    axios.get('/cs/vendors/' + this.props.vendorId + '/official_items/search.json', {params: params})
      .then((response) => {
        this.setState({officialItems: response.data.official_items, officialItemsTotal: response.data.total})
      })
      .catch((e) => {
        alert('商品の読み込みに失敗しました')
      })
  }

  /**
   * 商品を発注書に追加
   */
  _handleAddItem(e, item) {
    // すでにある場合は何もしない
    const isUsed = this.state.officialOrderFormItems.some((i) => {
      return i.id == item.id
    })
    if (isUsed) {
      return null
    }

    // ajax 商品追加
    const token = document.getElementsByName('csrf-token').item(0).content

    // TODO: js-routes 入れても良さそう
    let params = new FormData()
    params.append('official_order_form_item[official_order_form_id]', this.props.officialOrderFormId)
    params.append('official_order_form_item[official_item_id]', item.id)
    params.append("authenticity_token", token)
    return axios.post('/cs/official_order_form_items', params)
      .then((response) => {
        // 追加処理
        let officialOrderFormItems = this.state.officialOrderFormItems
        officialOrderFormItems.push(item)
        this.setState({officialOrderFormItems: officialOrderFormItems})
      })
      .catch((e) => {
        alert('追加に失敗しました')
      })
  }

  /**
   * 商品を発注書から削除する
   */
  _handleRemoveItem(e, item) {
    const token = document.getElementsByName('csrf-token').item(0).content
    let params = new FormData()
    params.append("_method", 'delete')
    params.append("authenticity_token", token)
    params.append("official_order_form_id", this.props.officialOrderFormId)
    return axios.post('/cs/official_order_form_items/' + item.id, params)
      .then((response) => {
        // 追加処理
        const officialOrderFormItems = this.state.officialOrderFormItems.filter((i) => i.id != item.id);
        this.setState({officialOrderFormItems: officialOrderFormItems})
      })
      .catch((e) => {
        alert('削除に失敗しました')
      })
  }
}

OfficialOrderFormEditor.propTypes = {
  shopId: PropTypes.number.isRequired,
  vendorId: PropTypes.number.isRequired,
  officialOrderFormId: PropTypes.number.isRequired,
  officialOrderFormItems: PropTypes.array.isRequired
}
