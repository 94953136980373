import React from 'react';
import DonutChart from './donut_chart';

const SalesRate = ({ foodSales, drinkSales }) => {
  return (
    <div className="col-xl-6 col-lg-6 col-sm-12 col-xs-12">
      <div className="card rounded card-bordered">
        <div className="card-body d-flex justify-content-between flex-column pb-2 px-9 pt-10">
          <div className="d-flex flex-stack pb-0">
            <div className="symbol symbol-30px me-4">
              <span className="symbol-label bg-light-primary">
                <span className="svg-icon svg-icon-2 svg-icon-primary">
                  <span className="section-icon">
                    <i className="ki-duotone ki-abstract-22">
                      <i className="path1"></i>
                      <i className="path2"></i>
                    </i>
                  </span>
                </span>
              </span>
            </div>
            <div className="d-flex align-items-center flex-stack flex-wrap flex-row-fluid d-grid gap-2">
              <div className="me-5">
                <span className="text-gray-800 fw-bolder fs-5 nowrap">FD売上構成比</span>
              </div>
            </div>
          </div>
          <div className="px-0 pt-2 pt-lg-0 mb-2">
            <DonutChart title={'売上合計'} series={[foodSales, drinkSales]} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SalesRate;
