import React from 'react';
import Chart from 'react-apexcharts';

const RadialBarChart = ({label, ratio}) => {
  const series = [
    ratio
  ];
  const options = {
    chart: {
      type: 'radialBar',
      height: 250
    },
    plotOptions: {
      radialBar: {
        startAngle: -135,
        endAngle: 135,
        offsetY: -10,
        dataLabels: {
          name: {
            fontSize: '12px',
            color: KTUtil.getCssVariableValue("--bs-gray-500"),
            offsetY: 60
          },
          value: {
            offsetY: 76,
            fontSize: '24px',
            color: KTUtil.getCssVariableValue("--bs-dark"),
            fontWeight: 700,
            formatter: (val) => {
              return val + "%";
            },
            offsetY: -10
          }
        }
      }
    },
    fill: {
      colors: [KTUtil.getCssVariableValue("--bs-primary")],
    },
    stroke: {
      dashArray: 4
    },
    labels: [label]
  };

  return (
    <div className="mx-n9 pt-0 pb-0">
      <Chart options={options} series={series} type="radialBar" height="250" />
    </div>
  );
};

export default RadialBarChart;
