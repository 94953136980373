import React, { useState } from 'react'
import PropTypes from 'prop-types'
import axios from '../../../lib/axios'
import ItemRow from './item_row'
import ItemNewRow from './item_new_row'

const List = ({ monthlyCostItems }) => {
  const [items, setItems] = useState(monthlyCostItems);
  const [newItems, setNewItems] = useState([]);
  const [countNewItem, setCountNewItem] = useState(0);

  const handleNew = () => {
    const newItem = {
      id: countNewItem,
      name: ''
    };
    setNewItems([...newItems, newItem]);
    setCountNewItem(countNewItem + 1);
  }

  const handleCancelNewItem = (index) => {
    setNewItems(newItems.filter((item, i) => i != index));
  }

  const handleSaveNewItem = (draftItem) => {
    axios.post('/api/shops/monthly_cost_items', { name: draftItem.name })
      .then((response) => {
        setNewItems(newItems.filter(item => item.id != draftItem.id));
        const addedItem = response.data.body.monthly_cost_item;
        setItems([...items, addedItem]);
      })
      .catch((error) => {
        if (error.response.data.error_message) {
          alert(error.response.data.error_message[0]);
        } else {
          alert('追加に失敗しました');
        }
      })
  }

  const handleSaveEditItem = (draftItem, callback) => {
    axios.patch(`/api/shops/monthly_cost_items/${draftItem.id}`, { name: draftItem.name })
      .then((response) => {
        const nextItems = items.map((item) => {
          if (item.id == draftItem.id) {
            return draftItem;
          }

          return item;
        });
        setItems(nextItems);
        callback();
      })
      .catch((error) => {
        if (error.response.data.error_message) {
          alert(error.response.data.error_message[0]);
        } else {
          alert('更新に失敗しました');
        }
      })
  }

  const handleRemoveItem = (removingItem) => {
    if (! confirm(`${removingItem.name}を削除します。集計結果からも削除されますが、よろしいですか？`)) {
      return;
    }
    axios.delete(`/api/shops/monthly_cost_items/${removingItem.id}`)
      .then(() => {
        setItems(items.filter(item => item.id != removingItem.id));
      })
      .catch((error) => {
        alert('削除に失敗しました');
      })
  }

  return (
    <div>
      {items && (items.length === 0 && newItems.length === 0) ? (
        <div className="card-body border-top p-9">費目がありません</div>
      ) : (
        <div className="card-body p-0">
          <div className="table-responsive">
            <table className="table align-middle table-row-bordered gy-5 gs-7">
              <thead>
                <tr className="fw-bolder fs-7 text-muted">
                  <th className="min-w-100px">費目名</th>
                  <th className="min-w-100px text-end">アクション</th>
                </tr>
              </thead>
              <tbody>
                {items.map(item => (
                  <ItemRow
                    item={item}
                    saveHandler={handleSaveEditItem}
                    removeHandler={handleRemoveItem}
                    key={item.id}
                  />
                ))}
                {newItems.map((newItem, index) => (
                  <ItemNewRow
                    item={newItem}
                    cancelHandler={() => handleCancelNewItem(index)}
                    saveHandler={handleSaveNewItem}
                    key={`newItem-${newItem.id}`}
                  />
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
      <div className="card-footer d-flex justify-content-end py-6 px-9">
        <button className="btn btn-primary" onClick={handleNew}>追加</button>
      </div>
    </div>
  )
}

List.propTypes = {
  monthlyCostItems: PropTypes.array.isRequired
}

export default List
