import React from 'react';

const MessageAboutCost = ({ otherCost, otherCostRate }) => {
  return (
    <div className="d-flex mb-5 flex-column">
      {otherCost == null && otherCostRate == null ? (
        <div className="alert alert-danger d-flex align-items-center p-5">
          <i className="ki-duotone ki-information-5 me-3 fs-2 text-danger">
            <span className="path1"></span>
            <span className="path2"></span>
            <span className="path3"></span>
          </i>
          <div className="d-flex flex-column fs-7">
            <span>
              <a href="/shops/monthly_costs" className="fw-bolder text-danger text-decoration-underline">費用設定ページ</a>
              および
              <a href="/shops/holidays" className="fw-bolder text-danger text-decoration-underline">休業日設定ページ</a>
              の設定が完了していないため、その他変動費率および固定費が算出できません。正しく予算設定するためには､これらの設定を完了した上で次のステップへ進んでください｡
            </span>
          </div>
        </div>
      ) : (
        <div className="alert alert-primary d-flex align-items-center p-5">
          <i className="ki-duotone ki-information-2 me-3 fs-2 text-primary">
              <span className="path1"></span>
              <span className="path2"></span>
              <span className="path3"></span>
          </i>
          <div className="d-flex flex-column fs-7">
            <span>
              予算設定ウィザードを開始するには､
              <a href="/shops/monthly_costs" className="fw-bolder text-primary text-decoration-underline">費用設定ページ</a>
              および
              <a href="/shops/holidays" className="fw-bolder text-primary text-decoration-underline">休業日設定ページ</a>
              での設定が完了しているかを確認してください
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default MessageAboutCost;
