import React, { useState } from 'react'
import PropTypes from 'prop-types'

const ItemNewRow = ({ item, cancelHandler, saveHandler }) => {
  const [draftItem, setDraftItem] = useState(item);

  const handleCancel = () => {
    cancelHandler();
  }

  const handleAdd = (e) => {
    saveHandler(draftItem);
  }

  const handleNameChange = (e) => {
    const newItem = {
      ...draftItem,
      name: e.currentTarget.value
    };
    setDraftItem(newItem);
  }

  return (
    <tr>
      <td className="fw-bold">
        <input type="text" className="form-control" value={draftItem.name} onChange={handleNameChange} />
      </td>
      <td className="text-end">
        <button className="btn btn-sm btn-secondary me-2" onClick={handleCancel}>キャンセル</button>
        <button className="btn btn-sm btn-primary" onClick={handleAdd}>追加</button>
      </td>
    </tr>
  )
}

ItemNewRow.propTypes = {
  item: PropTypes.object.isRequired,
  cancelHandler: PropTypes.func.isRequired,
  saveHandler: PropTypes.func.isRequired
}

export default ItemNewRow
