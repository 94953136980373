import React, { useState, useMemo } from 'react';

const Track = ({
  sales,
  customers,
  foodCost,
  foodCostRate,
  laborCost,
  laborCostRate,
  otherRatioCostRate,
  otherRatioCost,
  otherCost,
  goalSales,
  goalCustomers,
  goalFoodCostRate,
  goalLaborCostRate,
  goalOtherRatioCostRate,
  goalOtherRatioCost,
  goalOtherCost,
  goalPrice
}) => {
  /**
   * 試算用の値
   */
  // 仮の客単価
  const trialPrice = useMemo(() => {
    return goalPrice;
  }, []);
  // 仮の客数
  const trialQty = useMemo(() => {
    return goalCustomers;
  }, []);
  // 仮の仕入費率
  const trialFoodCostRate = useMemo(() => {
    return goalFoodCostRate;
  }, []);
  // 仮のPA人件費率
  const trialLaborCostRate = useMemo(() => {
    return goalLaborCostRate;
  }, []);
  // 仮のその他変動費率
  const trialOtherRatioCostRate = useMemo(() => {
    return goalOtherRatioCostRate;
  }, []);
  // 仮の固定費
  const trialFixedCost = useMemo(() => {
    return goalOtherCost;
  }, []);
  // 仮の変動費率
  const trialVariableRate = useMemo(() => {
    if (trialFoodCostRate != null && trialLaborCostRate != null && trialOtherRatioCostRate!= null ) {
      return trialFoodCostRate + trialLaborCostRate + trialOtherRatioCostRate;
    }
    return null;
  }, [trialFoodCostRate, trialLaborCostRate, trialOtherRatioCostRate]);
  // 仮の変動費単価
  const trialVariableCost = useMemo(() => {
    return Math.round(trialPrice * trialVariableRate / 100);
  }, []);
  // 仮の売上
  const trialPriceQty = useMemo(() => {
    return goalSales;
  }, []);
  // 仮の変動費
  const trialVariableQty = useMemo(() => {
    return Math.round(trialPriceQty * trialVariableRate / 100);
  }, [trialPriceQty, trialVariableRate]);
  // 仮の粗利
  const trialMarginQty = useMemo(() => {
    return trialPriceQty - trialVariableQty;
  }, [trialPriceQty, trialVariableQty]);
  // 仮の利益
  const trialGain = useMemo(() => {
    return goalSales - goalOtherRatioCost - goalOtherCost;
  }, []);    

  const trialMarginRate = useMemo(() => {
    return (1000 - (trialVariableRate * 10)) / 10
  });

  const trialMargin = useMemo(() => {
    return Math.round(trialPrice * trialMarginRate / 100);
  });

  /**
   * 実績
   */
  const price = useMemo(() => {
    if (! customers) {
      return 0;
    }
    const value = Math.round(sales / customers);
    return value ? value : 0;
  }, []);

  // 変動費率
  const variableRate = useMemo(() => {
    return foodCostRate + laborCostRate + otherRatioCostRate;
  }, []);

  const marginRate = useMemo(() => {
    // jsの小数計算の誤差対応(その他変動費が小数点２位まで)
    return (1000 - (variableRate * 10)) / 10;
  }, []);

  // 変動費(実績値) = 仕入費 + PA人件費 + その他変動費
  const variableQty = useMemo(() => {
    return foodCost + laborCost + otherRatioCost;
  }, []);

  // 変動費単価 = 変動費/客数
  const variableCost = useMemo(() => {
    if (! customers) {
      return 0;
    }
    return Math.round(variableQty / customers);
  }, []);

  // 粗利単価 = 客単価 - 変動費単価
  const margin = useMemo(() => {
    return price - variableCost;
  });

  // 粗利 = 売上- 変動費
  const marginQty = useMemo(() => {
    return sales - variableQty;
  }, []);

  // 利益 = 粗利 - 固定費
  const gain = useMemo(() => {
    return marginQty - otherCost;
  }, []);

  return (
    <div className="card-body pt-0">
      <div className="row g-0 gap-5 gap-lg-0 justify-content-evenly">
        <div className="col-md-12 col-lg-6 col-xl-6 col-xxl-6 row gy-2 gx-0 mt-0 justify-content-evenly me-0 pe-n2">
          <div className="col-4 px-0 gy-0 mt-0">
            <div className="card card-stretch card-bordered rounded-0">
              <div className="card-header px-4 pt-1 border-bottom-0">
                <div className="card-title fs-9 text-gray-500">
                  <div className="symbol symbol-30px me-3">
                    <div className="symbol-label fs-6 fw-bolder bg-gray text-white">P</div>
                  </div>
                  <div className="flex-column">
                    <div className="text-gray-500 fs-9">Price</div>
                    <div className="text-gray-600 fs-8">客単価（税抜）</div>
                  </div>
                </div>
              </div>
              <div className="card-body px-4 pb-4 pt-0">
                <div className="fs-1 fw-bolder">
                  ¥{price.toLocaleString()}
                </div>
                <div className="badge badge-light fs-6 fw-bolder mt-2">¥{trialPrice.toLocaleString()}</div>
              </div>
            </div>
          </div>
          <div className="col-4 px-0 mt-0">
            <div className="card min-h-350px card-bordered rounded-0">
              <div className="card-header px-4 pt-1 border-bottom-0">
                <div className="card-title fs-9 text-gray-400">
                  <div className="symbol symbol-30px me-3">
                    <div className="symbol-label bg-gray fs-6 fw-bolder text-white">V</div>
                  </div>
                  <div className="flex-column">
                    <div className="text-gray-500 fs-9">Variable</div>
                    <div className="text-gray-600 fs-8">変動費単価</div>
                  </div>
                </div>
              </div>
              <div className="card-body px-4 pb-4 pt-0">
                <div className="fs-1 fw-bolder">
                  ¥{variableCost.toLocaleString()}
                </div>
                <div className="badge badge-light fs-6 fw-bolder mt-2 mb-5">¥{trialVariableCost.toLocaleString()}</div>
                <div className="fs-9 fw-bold text-gray-600 mt-1 mb-0">変動費率
                </div>
                <div className="d-flex mb-4 justify-content-between">
                  <div className="fs-2 fw-bolder mt-1">
                    {variableRate.toLocaleString()}%
                  </div>
                  <div className="badge badge-light fs-6 fw-bolder mt-3">{trialVariableRate.toLocaleString()}%</div>
                </div>
                <div className="fs-9 text-gray-600 mb-0">仕入費率
                </div>
                <div className="d-flex mb-2 justify-content-between">
                  <div className="fs-4 fw-bolder text-gray-600 mt-1">
                    {foodCostRate.toLocaleString()}%
                  </div>
                  <div className="badge badge-light fs-7 fw-bolder mt-2">{trialFoodCostRate.toLocaleString()}%</div>
                </div>
                <div className="fs-9 text-gray-600 mb-0">PA人件費率
                </div>
                <div className="d-flex mb-2 justify-content-between">
                  <div className="fs-4 fw-bolder text-gray-600 mt-1">
                    {laborCostRate.toLocaleString()}%
                  </div>
                  <div className="badge badge-light fs-7 fw-bolder mt-2">{trialLaborCostRate.toLocaleString()}%</div>
                </div>
                <div className="fs-9 text-gray-600 mb-0">その他変動費率
                </div>
                <div className="d-flex mb-0 justify-content-between">
                  <div className="fs-5 fw-bolder text-gray-600 mt-1">
                    {otherRatioCostRate.toLocaleString()}%
                  </div>
                  <div className="badge badge-light fs-7 fw-bolder mt-2">{trialOtherRatioCostRate.toLocaleString()}%</div>
                </div>
              </div>
            </div>
            <div className="card  card-bordered rounded-0 min-h-300px">
              <div className="card-header px-4 pt-1 border-bottom-0">
                <div className="card-title fs-9 text-gray-400">
                  <div className="symbol symbol-30px me-3">
                    <div className="symbol-label fs-6 fw-bolder bg-gray text-white">M</div>
                  </div>
                  <div className="flex-column">
                    <div className="text-gray-500 fs-9">Margin</div>
                    <div className="text-gray-600 fs-8">粗利単価</div>
                  </div>
                </div>
              </div>
              <div className="card-body px-4 pb-4 pt-0">
                <div className="fs-1 fw-bolder">
                  ¥{margin.toLocaleString()}
                </div>
                <div className="badge badge-light fs-6 fw-bolder mt-2 mb-5">¥{trialMargin.toLocaleString()}</div>
                <div className="fs-9 fw-bold text-gray-600 mb-0">粗利率
                </div>
                <div className="fs-2 fw-bolder">
                  {marginRate.toLocaleString()}%
                </div>
                <div className="badge badge-light fs-6 fw-bolder mt-2 mb-5">{trialMarginRate}%</div>
              </div>
            </div>
          </div>
          <div className="col-4 ps-2 mt-0">
            <div className="card card-stretch-33 card-bordered rounded-0 me-0 me-lg-2">
              <div className="card-header px-4 pt-1 border-bottom-0">
                <div className="card-title fs-9 text-gray-400">
                  <div className="symbol symbol-30px me-3">
                    <div className="symbol-label fs-6 fw-bolder bg-gray text-white">Q</div>
                  </div>
                  <div className="flex-column">
                    <div className="text-gray-500 fs-9">Qty</div>
                    <div className="text-gray-600 fs-8">客数</div>
                  </div>
                </div>
              </div>
              <div className="card-body px-4 pb-4 pt-0">
                
                <div className="fs-1 fw-bolder">
                  {customers.toLocaleString()}
                </div>
                <div className="badge badge-light fs-6 fw-bolder mt-2 mb-5">{trialQty}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12 col-lg-6 col-xl-6 col-xxl-6 row g-0 mt-0 justify-content-evenly pe-1">
          <div className="col-4 justify-content-evenly px-0 gy-0 mt-0 pe-1">
            <div className="card card-stretch card-bordered rounded-0">
              <div className="card-header px-4 pt-1 border-bottom-0">
                <div className="card-title fs-9 text-gray-400">
                  <div className="symbol symbol-30px me-3">
                    <div className="symbol-label fs-6 fw-bolder bg-gray text-white">P<small className="text-white">×</small>Q</div>
                  </div>
                  <div className="flex-column">
                    <div className="text-gray-500 fs-9">Price × Qty</div>
                    <div className="text-gray-600 fs-8 lh-sm">売上（税抜）</div>
                  </div>
                </div>
              </div>
              <div className="card-body px-4 pb-4 pt-0">                
                <div className="fs-1 fw-bolder">
                  ¥{sales.toLocaleString()}
                </div>
                <div className="badge badge-light fs-6 fw-bolder mt-2 mb-5">¥{goalSales.toLocaleString()}</div>
              </div>
            </div>
          </div>
          <div className="col-8 justify-content-evenly px-0">
            <div className="row gx-2">
              <div className="col-12 gx-0 mt-0">
                <div className="card min-h-350px card-bordered rounded-0">
                  <div className="card-header px-4 pt-1 border-bottom-0">
                    <div className="card-title fs-9 text-gray-400">
                      <div className="symbol symbol-30px me-3">
                        <div className="symbol-label fs-6 fw-bolder bg-gray text-white">V<small className="text-white">×</small>Q</div>
                      </div>
                      <div className="flex-column">
                    <div className="text-gray-500 fs-9">Variable × Qty</div>
                    <div className="text-gray-600 fs-8 lh-sm">変動費</div>
                  </div>
                    </div>
                  </div>
                  <div className="card-body px-4 pb-4 pt-0">
                    <div className="fs-1 fw-bolder">
                      ¥{Math.round(variableQty).toLocaleString()}
                    </div>
                    <div className="badge badge-light fs-6 fw-bolder mt-2 mb-5">¥{trialVariableQty.toLocaleString()}</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row gx-2 justify-content-evenly">
              <div className="col-6 gx-3 mt-0 gy-0 px-0">
                <div className="card card-stretch card-bordered rounded-0">
                  <div className="card-header px-4 pt-1 border-bottom-0">
                    <div className="card-title fs-9 text-gray-400">
                      <div className="symbol symbol-30px me-3">
                        <div className="symbol-label fs-6 fw-bolder  bg-gray text-white">M<small className="text-white">×</small>Q</div>
                      </div>
                      <div className="flex-column">
                    <div className="text-gray-500 fs-9">Margin × Qty</div>
                    <div className="text-gray-600 fs-8 lh-sm">粗利</div>
                  </div>
                    </div>
                  </div>
                  <div className="card-body px-4 pb-4 pt-0">
                    <div className="fs-1 fw-bolder">
                      ¥{Math.round(marginQty).toLocaleString()}
                    </div>
                    <div className="badge badge-light fs-6 fw-bolder mt-2 mb-5">¥{trialMarginQty.toLocaleString()}</div>
                  </div>
                </div>
              </div>
              <div className="col-6 gx-3 flex-column px-0">
                <div className="card card-stretch card-bordered rounded-0 min-h-150px">
                  <div className="card-header px-4 pt-1 border-bottom-0">
                    <div className="card-title fs-9 text-gray-400">
                      <div className="symbol symbol-30px me-3">
                        <div className="symbol-label fs-6 fw-bolder bg-gray text-white">F</div>
                      </div>
                      <div className="flex-column">
                    <div className="text-gray-500 fs-9">Fixed Cost</div>
                    <div className="text-gray-600 fs-8 lh-sm">固定費</div>
                  </div>
                    </div>
                  </div>
                  <div className="card-body px-4 pb-5 pt-0">
                    <div className="fs-1 fw-bolder">
                      ¥{otherCost.toLocaleString()}
                    </div>
                    <div className="badge badge-light fs-6 fw-bolder mt-2">¥{trialFixedCost.toLocaleString()}</div>
                  </div>
                </div>
                <div className="card card-stretch card-bordered rounded-0 min-h-150px">
                  <div className="card-header px-4 pt-1 border-bottom-0">
                    <div className="card-title fs-9 text-gray-400">
                      <div className="symbol symbol-30px me-3">
                        <div className="symbol-label fs-6 fw-bolder bg-gray text-white">G</div>
                      </div>
                      <div className="flex-column">
                    <div className="text-gray-500 fs-9">Gain</div>
                    <div className="text-gray-600 fs-8 lh-sm">利益</div>
                  </div>
                    </div>
                  </div>
                  <div className="card-body px-4 pb-5 pt-0">
                    <div className="fs-1 fw-bolder">
                      ¥{Math.round(gain).toLocaleString()}
                    </div>
                    <div className="badge badge-light fs-6 fw-bolder mt-2">¥{trialGain.toLocaleString()}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Track;
