import React, { useEffect, useState } from 'react';
import axios from '../../../lib/axios';
import Loading from '../spinners/loading';
import List from './list';

const Summary = ({ labels, size, from, to }) => {
  const [shopLabelSummaries, setShopLabelSummaries] = useState([]);
  const [labelIds, setLabelIds] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);

  const getShopLabelSummaries = (from, to, label_id, size) => {
    if(isInitialized) {    
      setLoading(true);
      axios.get(`shop_label_summaries/list`, {params: { size: size, label_id: labelIds, from: from, to: to }})
        .then((response) => {
          setShopLabelSummaries(response.data);
          setLoading(false);
        });
    } else {
      setTimeout(function() {
        setIsInitialized(true);
        setLoading(true);
        axios.get(`shop_label_summaries/list`, {params: { size: size, label_id: labelIds, from: from, to: to }})
          .then((response) => {
            setShopLabelSummaries(response.data);
            setLoading(false);
          });
      }, 500);
    }
  };

  const numbering = () => {
    $('#datatable_fixed_header2 tbody tr td:first-child').each((index, td) => {
      td.innerText = index + 1;
    });
  };

  useEffect(() => {
    getShopLabelSummaries(from, to, labelIds, size);
  }, [labelIds]);

  useEffect(() => {
    if (shopLabelSummaries.length === 0 || $("#datatable_fixed_header2").hasClass('dataTable')) {
      return;
    }

    const datatable = new DataTable("#datatable_fixed_header2", {
      paging: false,
      info : false,
      scrollX:        false,
      scrollCollapse: false,
      order: [[ 2, "desc" ]],
      columns: [
        null,
        null,
        { orderSequence: ['desc', 'asc'] },
        { orderSequence: ['desc', 'asc'] },
        { orderSequence: ['desc', 'asc'] },
        { orderSequence: ['desc', 'asc'] },
        { orderSequence: ['desc', 'asc'] },
        { orderSequence: ['desc', 'asc'] },
        { orderSequence: ['desc', 'asc'] },
      ],
      columnDefs: [{
        targets: [0, 1],
        orderable: false
      }],
      fixedHeader:{
        header: true,
        headerOffset: 0
      },
      fixedColumns: {
        leftColumns: 2,
      }
    });
    datatable.on('order', () => {
      numbering();
    });
  }, [shopLabelSummaries, loading]);

  return (
    <div className="col-xl-12 col-lg-12 col-sm-12 col-xs-12">
      <div className="card">
        <div className="card-header border-0 pt-8 pb-2 gap-6">
          <div className="d-flex flex-stack">
            <div className="symbol symbol-30px me-4">
              <span className="symbol-label bg-light-primary">
                <span className="svg-icon svg-icon-2 svg-icon-primary">
                  <span className="section-icon">
                    <i className="ki-duotone ki-abstract-12">
                      <i className="path1"></i>
                      <i className="path2"></i>
                    </i>
                  </span>
                </span>
              </span>
            </div>
            <div className="d-flex align-items-center flex-stack flex-wrap flex-row-fluid d-grid gap-2">
              <div className="me-5">
                <span className="text-gray-800 fw-bolder fs-5">店舗ラベル別サマリー</span>
              </div>
            </div>
          </div>
          <div className="card-title my-0 mobile-flex-column gap-4 mobile-w-100 me-0">
            <a href="/shop_label_summaries" className="btn btn-sm btn-primary fs-7 align-items-center mobile-w-100">
              <i className="ki-outline ki-down fs-6 text-gray ms-n1 mb-1"></i>
              すべて表示
            </a>
          </div>
        </div>
        {loading ? (
          <Loading />
        ) : (
          <List shopLabelSummaries={shopLabelSummaries} />
        )}
      </div>
    </div>
  )
}

export default Summary;
