import React, { useMemo } from 'react';

const Step4 = ({ step, price, changeHandler, setStep }) => {
  const completedClass = useMemo(() => {
    if (step == 4) {
      return 'completed';
    }

    return '';
  }, [step, price]);

  const changePriceHandler = (e) => {
    const newValue = e.currentTarget.value;
    changeHandler(newValue ? parseInt(newValue) : null);
  };

  const clickHandler = (e) => {
    e.preventDefault();
    setStep(4);
  };

  const renderStep = () =>  {
    return (
      <>
        <div className="stepper-wrapper align-items-start">
          <div className="stepper-icon min-w-40px min-h-40px">
            <i className="ki-duotone ki-check fs-2 stepper-check"></i>
            <span className="stepper-number">4</span>
          </div>
          <div className="flex-column">
            <div className="stepper-label">
              <h3 className="stepper-title my-3">目標客数を算出</h3>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      {step == 4 ? (
        <div className={`stepper-item current pb-40 ${completedClass}`}>
          {renderStep()}
        </div>
      ) : (
        <a href="#" className={`stepper-item pb-40 ${completedClass}`} onClick={clickHandler}>
          {renderStep()}
        </a>
      )}
    </>
  );
};

export default Step4;
