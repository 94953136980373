import React from 'react';
import Chart from 'react-apexcharts';

const DailyBarChart = ({categories, sales, foodCosts, laborCosts}) => {
  const series = [
    {
      name: '売上（税抜）',
      type: 'bar',
      data: sales,
    },
    {
      name: '仕入費',
      type: 'bar',
      data: foodCosts
    },
    {
      name: 'PA人件費',
      type: 'bar',
      data: laborCosts
    }
  ];
  const options = {
    chart: {
      type: 'bar',
      height: '200',
      fontFamily: 'Open Sans, Noto Sans Japanese, Sans-Serif',
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: [4],
        columnWidth: '80%',
        endingShape: 'rounded'
      }
    },
    stroke: {
      show: true,
      width: 0,
      colors: ['transparent']
    },
    fill: {
      type:'solid',
      opacity: [1, 1, 1],
    },
    legend: {
      position: 'top'
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: categories,
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      },
      labels: {
        show: true,
        rotate: 0,
        hideOverlappingLabels: true,
        style: {
          fontFamily: 'Open Sans, Noto Sans Japanese, Sans-Serif',
          colors: KTUtil.getCssVariableValue("--bs-gray-500"),
          fontSize: "11px"
        }
      }
    },
    yaxis: {
      labels: {
        show: true,
        rotate: 0,
        hideOverlappingLabels: true,
        style: {
          fontFamily: 'Open Sans, Noto Sans Japanese, Sans-Serif',
          colors: KTUtil.getCssVariableValue("--bs-gray-500"),
          fontSize: "11px"
        }
      }
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: [{
        formatter: (y) => {
          return "¥" + y.toLocaleString();
        }
      }, {
        formatter: (y) => {
          return "¥" + y.toLocaleString();
        }
      }, {
      formatter: (y) => {
        return "¥" + y.toLocaleString();
      }
      }]
    },
    colors: [KTUtil.getCssVariableValue("--bs-primary"),KTUtil.getCssVariableValue("--bs-gray-600"), KTUtil.getCssVariableValue("--bs-gray-400")],
    grid: {
      borderColor: KTUtil.getCssVariableValue("--bs-gray-200"),
      strokeDashArray: 2,
      yaxis: {
        lines: {
          show: true
        }
      },
      padding: {
        top: 0,
        right: 24,
        bottom: 0,
        left: 24
      }
    }
  };

  return (
    <div className="mx-n9 pt-0 pb-0">
      <Chart options={options} series={series} type="bar" height="200" />
    </div>
  );
};

export default DailyBarChart;
