import React, { Component } from 'react';
import AddItem from './add_item'
import AddItemFromOrder from './add_item_from_order'
import PropTypes from "prop-types";

export default class OrderDetail extends Component {
  constructor(props) {
    super(props)

    this.editorStyle = this.props.editorStyle
  }

  render () {
    const { deliveryNotePhoto,
            shopVendor,
            basket,
            currentBasketIndex,
            isAddItemButtonDisabled,
            isAddItemVisible,
            isAddItemFromOrderVisible,
            removeBasket,
            showAddItem,
            hideAddItem,
            showAddItemFromOrder,
            hideAddItemFromOrder,
            handleAddItem,
            handleRemoveItem,
            handleArrivalNumChange,
            handlePriceChange,
            handleSubtotalTaxExcludedChange,
            handleTaxChange,
            handleSubtotalChange,
            handleAddItemFromOrder,
            isEditable
          } = this.props;
    return (
      <>
        <div className={this.editorStyle.isManager() ? this.editorStyle.marginTop() : '' }>
          <label className={this.editorStyle.label()}>注文情報</label>
          <div>
            注文番号： { basket.order ? (isEditable ? <a href={`/cs/orders/${basket.order.id}`} target="blank">{basket.order.id}</a> : basket.order.order_code) : ""}
            <br />
            注文日時： { basket.order ? basket.order.created_at : ""}
          </div>
        </div>
        <div className={this.editorStyle.marginTop()}>
          <label className={this.editorStyle.label()}>納品明細</label>
        </div>
        <div className={this.editorStyle.marginTop()}>
          { basket.arrivalItems.map((arrivalItem, i) => {
            return (
              <div key={i} className={this.editorStyle.box()}>
                <div key={i} data-index={i} className={this.editorStyle.boxDetail()}>
                  <div className={this.editorStyle.columns()}>
                    <div className={this.editorStyle.column()}>
                      <label className={this.editorStyle.label()}>

                        {
                          isEditable ? (
                            <strong>
                              {i + 1}. 
                              {
                                this.editorStyle.isCs() ? (
                                  <a target="blank" href={`/cs/shops/${deliveryNotePhoto.shop.id}/unofficial_items/${arrivalItem.unofficialItemId}/edit`}>
                                    {arrivalItem.officialItemName}{arrivalItem.officialItemCode}
                                  </a>
                                ) : (
                                  <>{arrivalItem.officialItemName}{arrivalItem.officialItemCode}</>
                                )
                              }
                                                            
                            </strong>
                          ) : (
                            <strong>
                              {i + 1}. {arrivalItem.officialItemName}{arrivalItem.officialItemCode}
                            </strong>
                          )
                        }                        
                      </label>
                      <div>
                        <label className={this.editorStyle.joinClassNames(this.editorStyle.label(), this.editorStyle.small())}>
                          税区分： {arrivalItem.officialItemTaxTypeName}
                        </label>
                      </div>                      
                    </div>
                    {
                      isEditable && (
                        <div className={this.editorStyle.column()} align="right">
                          <button className={this.editorStyle.joinClassNames(this.editorStyle.buttonDanger(), this.editorStyle.buttonSmall())} onClick={(e) => handleRemoveItem(i, e)}>削除</button>
                        </div>
                      )
                    }                    
                  </div>
                  <div className={this.editorStyle.block()}>
                    <div className={this.editorStyle.columns()}>
                      <div className={this.editorStyle.joinClassNames(this.editorStyle.field(), this.editorStyle.column())}>
                        <label className={this.editorStyle.joinClassNames(this.editorStyle.label(), this.editorStyle.small())}>
                          注文数
                        </label>
                        <div className={this.editorStyle.control()}>
                          <input className={this.editorStyle.joinClassNames(this.editorStyle.input(), this.editorStyle.small())} type="number" defaultValue={arrivalItem.orderNum} disabled readOnly/>
                        </div>
                      </div>
                      <div className={this.editorStyle.joinClassNames(this.editorStyle.field(), this.editorStyle.column())}>
                      <label className={this.editorStyle.joinClassNames(this.editorStyle.label(), this.editorStyle.small())}>
                          納品数
                        </label>
                        <div className={this.editorStyle.control()}>
                          <input className={this.editorStyle.joinClassNames(this.editorStyle.input(), this.editorStyle.small())} type="number" value={arrivalItem.arrivalNum} onChange={(e) => handleArrivalNumChange(i, e)} required/>
                        </div>
                      </div>
                      <div className={this.editorStyle.joinClassNames(this.editorStyle.field(), this.editorStyle.column())}>
                      <label className={this.editorStyle.joinClassNames(this.editorStyle.label(), this.editorStyle.small())}>
                          納品単価
                        </label>
                        <div className={this.editorStyle.control()}>
                        < input className={this.editorStyle.joinClassNames(this.editorStyle.input(), this.editorStyle.small())} type="number" value={arrivalItem.price} onChange={(e) => handlePriceChange(i, e)} required/>
                        </div>
                      </div>
                      <div className={this.editorStyle.joinClassNames(this.editorStyle.field(), this.editorStyle.column())}>
                      <label className={this.editorStyle.joinClassNames(this.editorStyle.label(), this.editorStyle.small())}>
                          小計（税別）
                        </label>
                        <div className={this.editorStyle.control()}>
                          <input className={this.editorStyle.joinClassNames(this.editorStyle.input(), this.editorStyle.small())} type="number" value={arrivalItem.subtotalTaxExcluded} onChange={(e) => handleSubtotalTaxExcludedChange(i, e)} required/>
                        </div>
                      </div>
                      <div className={this.editorStyle.joinClassNames(this.editorStyle.field(), this.editorStyle.column())}>
                        <label className={this.editorStyle.joinClassNames(this.editorStyle.label(), this.editorStyle.small())}>
                          小計（税額）
                        </label>
                        <div className={this.editorStyle.control()}>
                          <input className={this.editorStyle.joinClassNames(this.editorStyle.input(), this.editorStyle.small())} type="number" value={arrivalItem.tax} onChange={(e) => handleTaxChange(i, e)} required/>
                        </div>
                      </div>
                      <div className={this.editorStyle.joinClassNames(this.editorStyle.field(), this.editorStyle.column())}>
                        <label className={this.editorStyle.joinClassNames(this.editorStyle.label(), this.editorStyle.small())}>
                          小計（税込）
                        </label>
                        <div className={this.editorStyle.control()}>
                          <input className={this.editorStyle.joinClassNames(this.editorStyle.input(), this.editorStyle.small())} type="number" value={arrivalItem.subtotal} onChange={(e) => handleSubtotalChange(i, e)} required/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          })}

          { (basket.arrivalItems.length === 0 && !isAddItemVisible) &&
            <>納品明細がまだ登録されていません。</>
          }
        </div>

        { isEditable && isAddItemVisible ?
          <AddItem
            shop={deliveryNotePhoto.shop}
            shopVendor={shopVendor}
            hideAddItem={hideAddItem}
            handleAddItem={handleAddItem}
            editorStyle={this.editorStyle}
           /> : ""
        }

        { isEditable && isAddItemFromOrderVisible ?
          <AddItemFromOrder
            shop={deliveryNotePhoto.shop}
            order={basket.order}
            arrivalItems={basket.arrivalItems}
            handleAddItemFromOrder={handleAddItemFromOrder}
            hideAddItemFromOrder={hideAddItemFromOrder}
            editorStyle={this.editorStyle}
          /> : ""
        }        

        {
          (isAddItemVisible || isAddItemFromOrderVisible) ? "" :
            (
              isEditable && (
                <div align="right" className={this.editorStyle.marginTop()}>
                  <div className={this.editorStyle.buttons()}>
                    <button className={this.editorStyle.joinClassNames(this.editorStyle.buttonPrimary(), this.editorStyle.isManager() ? this.editorStyle.marginRight() : '')} disabled={isAddItemButtonDisabled} onClick={showAddItem}>
                      商品を検索から追加
                    </button>
                    {basket.order
                      ? <button className={this.editorStyle.joinClassNames(this.editorStyle.buttonLink())} onClick={showAddItemFromOrder}>商品を発注情報から追加</button>
                      : ""
                    }
                  </div>
                </div>
              )
            )            
        }            
      </>
    )
  }
}

OrderDetail.propTypes = {
    deliveryNotePhoto: PropTypes.object.isRequired,
    shopVendor: PropTypes.object,
    basket: PropTypes.object.isRequired,
    currentBasketIndex: PropTypes.number.isRequired,
    isAddItemButtonDisabled: PropTypes.bool.isRequired,
    isAddItemVisible: PropTypes.bool.isRequired,
    isAddItemFromOrderVisible: PropTypes.bool.isRequired,
    removeBasket: PropTypes.func.isRequired,
    showAddItem: PropTypes.func.isRequired,
    hideAddItem: PropTypes.func.isRequired,
    showAddItemFromOrder: PropTypes.func.isRequired,
    hideAddItemFromOrder: PropTypes.func.isRequired,
    handleAddItem: PropTypes.func.isRequired,
    handleRemoveItem: PropTypes.func.isRequired,
    handleArrivalNumChange: PropTypes.func.isRequired,
    handlePriceChange: PropTypes.func.isRequired,
    handleSubtotalTaxExcludedChange: PropTypes.func.isRequired,
    handleTaxChange: PropTypes.func.isRequired,
    handleSubtotalChange: PropTypes.func.isRequired,
    handleAddItemFromOrder: PropTypes.func.isRequired
}
