import React, { useEffect, useState } from 'react';
import moment from "moment";

const Modal = ({ date, dailyBudget, dailyTrackRecord, dailyTrackRecordIncludeTax, sales }) => {
  const [diffSales, setDiffSales] = useState(null);
  const [diffCustomers, setDiffCustomers] = useState(null);
  const [diffTotalUpSales, setDiffTotalUpSales] = useState(null);
  const [diffTotalUpCustomers, setDiffTotalUpCustomers] = useState(null);

  useEffect(() => {
    if (dailyTrackRecord) {
      setDiffSales(dailyTrackRecord.sales - dailyBudget.sales);
      setDiffCustomers(dailyTrackRecord.customers - dailyBudget.customers);
      setDiffTotalUpSales(dailyTrackRecord.total_up_sales - dailyBudget.total_up_sales);
      setDiffTotalUpCustomers(dailyTrackRecord.total_up_customers - dailyBudget.total_up_customers);
    }
  }, [dailyBudget, dailyTrackRecord]);

  const dateString = () => {
    const d = moment(date);

    const dayOfWeeks = ['日', '月', '火', '水', '木', '金', '土'];

    return d.format('Y年 M月D日') + ` (${dayOfWeeks[d.weekday()]})`;
  };

  const changeSales = () => {
    if (diffSales != null) {
      return (diffSales >= 0 ? "+" : "-") + "¥" + Number(Math.abs(diffSales).toFixed(0)).toLocaleString();
    }

    return "-";
  };

  const changeCustomers = () => {
    if (diffCustomers != null) {
      return (diffCustomers >= 0 ? "+" : "-") + Number(Math.abs(diffCustomers).toFixed(0)).toLocaleString() + "人";
    }

    return "-";
  };

  const changeTotalUpSales = () => {
    if (diffTotalUpSales != null) {
      return (diffTotalUpSales >= 0 ? "+" : "-") + "¥" + Number(Math.abs(diffTotalUpSales).toFixed(0)).toLocaleString();
    }

    return "-";
  };

  const changeTotalUpCustomers = () => {
    if (diffTotalUpCustomers != null) {
      return (diffTotalUpCustomers >= 0 ? "+" : "-") + Number(Math.abs(diffTotalUpCustomers).toFixed(0)).toLocaleString() + "人";
    }

    return "-";
  };

  const changeBadgeClass = (diff) => {
    if (diff < 0) {
      return "badge-light-danger";
    }

    return "badge-light-success";
  };

  return (
    <div className="modal fade" id="kt_modal_daily_detail" tabIndex="-1" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered mw-650px">
        <div className="modal-content rounded">
          <div className="modal-header pb-0 border-0 justify-content-end">
            <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
              <span className="svg-icon svg-icon-1">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="currentColor" />
                  <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="currentColor" />
                </svg>
              </span>
            </div>
          </div>
          <div className="modal-body scroll-y px-10 px-lg-15 pt-0 pb-8">
            <div className="mb-13 d-flex align-items-center">
              <i className="ki-outline ki-calendar text-primary fs-1 me-3"></i>
              <div className="flex-column">
                <div className="fs-9 text-gray-500 fw-bolder">日次実績:</div>
                <div className="fs-3 fw-bolder">{dateString()}</div>
              </div>
            </div>
            <div className="d-flex flex-wrap d-grid mb-0 row">
              <div className="col-md-6 pb-6">
                <div className="d-flex row">
                  <div className="col-6">
                    <span className="fs-7 fw-bold text-gray-600">当日売上 (税抜)</span>
                    <div className="d-flex mt-2">
                      <span className="fs-3 fw-boldest text-gray-800 me-1 lh-1">¥</span>
                      <span className="fs-2x fw-boldest text-gray-800 me-2 lh-1 ls-0">
                        {dailyTrackRecord ? Number(dailyTrackRecord.sales.toFixed(0)).toLocaleString() : '-'}
                      </span>
                    </div>
                    <div className="fs-8 fw-bold text-gray-600 mt-4">当日売上 (税込)</div>
                    <div className="fs-5 fw-bolder lh-1 ls-0 mt-1">
                      ¥
                      {dailyTrackRecordIncludeTax ? Number(dailyTrackRecordIncludeTax.sales.toFixed(0)).toLocaleString() : '-'}
                    </div>
                  </div>
                  <div className="col-6 pb-5 text-end">
                    <div className="d-flex mt-8 flex-row-reverse">
                      <span className={`badge ${changeBadgeClass(diffSales)} fs-5 fw-boldest ms-2`}>{changeSales()}</span>
                    </div>
                  </div>
                </div>
                <div className="separator mt-4 mb-3"></div>
              </div>
              <div className="col-md-6 pb-6">
                <div className="d-flex row">
                  <div className="col-6">
                    <span className="fs-7 fw-bold text-gray-600">累計売上 (税抜)</span>
                    <div className="d-flex mt-2">
                      <span className="fs-3 fw-boldest text-gray-800 me-1 lh-1">¥</span>
                      <span className="fs-2x fw-boldest text-gray-800 me-2 lh-1 ls-0">
                        {dailyTrackRecord ? Number(dailyTrackRecord.total_up_sales.toFixed(0)).toLocaleString() : '-'}
                      </span>
                    </div>
                    <div className="fs-8 fw-bold text-gray-600 mt-4">累計売上 (税込)</div>
                    <div className="fs-5 fw-bolder lh-1 ls-0 mt-1">
                      ¥
                      {dailyTrackRecordIncludeTax ? Number(dailyTrackRecordIncludeTax.total_up_sales.toFixed(0)).toLocaleString() : '-'}
                    </div>
                  </div>
                  <div className="col-6 pb-5 text-end">
                    <div className="d-flex mt-8 flex-row-reverse">
                      <span className={`badge ${changeBadgeClass(diffTotalUpSales)} fs-5 fw-boldest ms-2`}>{changeTotalUpSales()}</span>
                    </div>
                  </div>
                </div>
                <div className="separator mt-4 mb-3"></div>
              </div>
              <div className="col-md-6 pb-6">
                <div className="d-flex row">
                  <div className="col-6">
                    <span className="fs-7 fw-bold text-gray-600">当日客数</span>
                    <div className="d-flex mt-2 align-items-end">
                      <span className="fs-2x fw-boldest text-gray-800 lh-1 ls-0">
                        {dailyTrackRecord ? Number(dailyTrackRecord.customers.toFixed(0)).toLocaleString() : '-'}
                      </span>
                      <span className="fs-5 fw-boldest text-gray-800 lh-1">人</span>
                    </div>
                  </div>
                  <div className="col-6 pb-5 text-end">
                    <div className="d-flex mt-8 flex-row-reverse">
                      <span className={`badge ${changeBadgeClass(diffCustomers)} fs-5 fw-boldest ms-2`}>{changeCustomers()}</span>
                    </div>
                  </div>
                </div>
                <div className="separator mt-4 mb-3"></div>
              </div>
              <div className="col-md-6 pb-6">
                <div className="d-flex row">
                  <div className="col-6">
                    <span className="fs-7 fw-bold text-gray-600">累計客数</span>
                    <div className="d-flex mt-2 align-items-end">
                      <span className="fs-2x fw-boldest text-gray-800 lh-1 ls-0">
                        {dailyTrackRecord ? Number(dailyTrackRecord.total_up_customers.toFixed(0)).toLocaleString() : '-'}
                      </span>
                      <span className="fs-5 fw-boldest text-gray-800 lh-1">人</span>
                    </div>
                  </div>
                  <div className="col-6 pb-5 text-end">
                    <div className="d-flex mt-8 flex-row-reverse">
                      <span className={`badge ${changeBadgeClass(diffTotalUpCustomers)} fs-5 fw-boldest ms-2`}>{changeTotalUpCustomers()}</span>
                    </div>
                  </div>
                </div>
                <div className="separator mt-4 mb-3"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
