import React from 'react';
import Chart from 'react-apexcharts';

const LineChart = ({id, dataArray, dayArray}) => {
  const series = dataArray;
  const options = {
    chart: {
      type: 'line',
      height: '330',
      fontFamily: 'Open Sans, Noto Sans Japanese, Sans-Serif',
      toolbar: {
        show: false
      }
    },
    stroke: {
      show: true,
      width: 2,
    },
    markers: {
      size: 5,
      hover: {
        size: 6
      }
    },
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: [4],
        columnWidth: '80%',
        endingShape: 'rounded'
      },
    },
    legend: {
      position: 'top',
      fontSize: "11px",
      fontFamily: 'Open Sans, Noto Sans Japanese, Sans-Serif',
      labels: {
        colors: KTUtil.getCssVariableValue("--bs-gray-600"),
      }
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: dayArray,
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      },
      labels: {
        show: true,
        rotate: 0,
        hideOverlappingLabels: true,
        style: {
          fontFamily: 'Open Sans, Noto Sans Japanese, Sans-Serif',
          colors: KTUtil.getCssVariableValue("--bs-gray-500"),
          fontSize: "11px"
        }
      }
    },
    yaxis: {
      labels: {
        show: true,
        rotate: 0,
        hideOverlappingLabels: true,
        style: {
          fontFamily: 'Open Sans, Noto Sans Japanese, Sans-Serif',
          colors: KTUtil.getCssVariableValue("--bs-gray-500"),
          fontSize: "11px"
        }
      }
    },
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        inverseColors: false,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [100, 100, 100, 100]
      },
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: [{
        formatter: function(y) {
          return y.toLocaleString() + "人";
        }
      }, {
        formatter: function(y) {
          return y.toLocaleString() + "人";
        }
      }]
    },
    colors: [KTUtil.getCssVariableValue("--bs-teal"), KTUtil.getCssVariableValue("--bs-blue")],
    grid: {
      borderColor: KTUtil.getCssVariableValue("--bs-gray-200"),
      strokeDashArray: 2,
      yaxis: {
        lines: {
          show: true
        }
      },
      padding: {
        top: 0,
        right: 24,
        bottom: 0,
        left: 10
      }
    },
  };

  return (
    <div className="mx-n5 mt-n2 mb-0">
      <Chart id={id} options={options} series={series} type="line" className="px-n8 py-6" height="330" />
    </div>
  );
};

export default LineChart;
