import React, { Component } from 'react';
import PropTypes from "prop-types";
import { calcTax, calcSubtotalTaxExcluded, calcSubtotal } from '../tax_calculator';

export default class AddItemFromOrder extends Component {
  constructor(props) {
    super(props)

    this.state = {
      shippingItems: (this.props.order ? this.props.order.shippingItems : [])
    }

    this.editorStyle = this.props.editorStyle;
  }

  calcSubtotalTaxExcluded = (shippingItem) => {
    let taxRate = shippingItem.officialItem.taxRate
    let taxType = shippingItem.officialItem.taxType
    let latestPrice = shippingItem.officialItem.latestPrice == null ? 0 : shippingItem.officialItem.latestPrice
    return calcSubtotalTaxExcluded(shippingItem.shippingNum, latestPrice, taxType, taxRate)
  }

  calcTax = (shippingItem) => {
    let latestPrice = shippingItem.officialItem.latestPrice == null ? 0 : shippingItem.officialItem.latestPrice
    let taxRate = shippingItem.officialItem.taxRate
    let taxType = shippingItem.officialItem.taxType
    return calcTax(shippingItem.shippingNum, latestPrice, taxType, taxRate)
  }

  calcSubtotal = (shippingItem) => {
    let latestPrice = shippingItem.officialItem.latestPrice == null ? 0 : shippingItem.officialItem.latestPrice
    let taxRate = shippingItem.officialItem.taxRate
    let taxType = shippingItem.officialItem.taxType
    return calcSubtotal(shippingItem.shippingNum, latestPrice, taxType, taxRate)
  }


  render () {
    const { shippingItems } = this.state;
    const { shop,
            arrivalItems,
            handleAddItemFromOrder,
            hideAddItemFromOrder } = this.props;
    return (
      <div className={this.editorStyle.joinClassNames(this.editorStyle.card(), this.editorStyle.marginTop())}>
        <div className={this.editorStyle.cardContent()}>
          <h5 className={this.editorStyle.title()}>商品を発注情報から追加</h5>
          <ul>
            {shippingItems.map((shippingItem, index) => {
              return(
                shippingItem.num === 0 ? "" : <li key={index}>
                  <div className={this.editorStyle.columns()}>
                    <div className={this.editorStyle.column()}>
                      <label className={this.editorStyle.label()}>
                        <strong>{index + 1}. <a target="blank" href={`/cs/shops/${shop.id}/unofficial_items/${shippingItem.officialItem.unofficialItemId}/edit`}>
                          {shippingItem.officialItem.name}（{shippingItem.officialItem.unitName}）{shippingItem.officialItem.code}
                        </a></strong>
                      </label>
                      <label className={this.editorStyle.joinClassNames(this.editorStyle.label(), this.editorStyle.small())}>
                        税区分： {shippingItem.officialItem ? shippingItem.officialItem.taxTypeName : '-'}
                      </label>
                    </div>
                    <div className={this.editorStyle.column()} align="right">
                      { arrivalItems.map(arrivalItem => arrivalItem.officialItemId).indexOf(shippingItem.officialItem.id) >= 0 ?
                          <button style={{float: "right"}} className={this.editorStyle.buttonLight()} disabled={true}>追加済み</button> :
                          <button
                              style={{float: "right"}}
                              className={this.editorStyle.buttonLight()}
                              onClick={(e) => handleAddItemFromOrder(shippingItem, this.calcSubtotalTaxExcluded(shippingItem), this.calcTax(shippingItem), this.calcSubtotal(shippingItem), e)}>
                            追加する
                          </button>
                      }
                    </div>
                  </div>
                  <div className={this.editorStyle.joinClassNames(this.editorStyle.block(), this.editorStyle.clearfix())}>
                    <div className={this.editorStyle.columns()}>
                      <div className={this.editorStyle.joinClassNames(this.editorStyle.field(), this.editorStyle.column())}>
                        <label className={this.editorStyle.joinClassNames(this.editorStyle.label(), this.editorStyle.small())}>
                          注文数
                        </label>
                        <div className={this.editorStyle.control()}>
                          <input className={this.editorStyle.joinClassNames(this.editorStyle.input(), this.editorStyle.small())} type="number" defaultValue={shippingItem.num} disabled readOnly/>
                        </div>
                      </div>
                      <div className={this.editorStyle.joinClassNames(this.editorStyle.field(), this.editorStyle.column())}>
                        <label className={this.editorStyle.joinClassNames(this.editorStyle.label(), this.editorStyle.small())}>
                          納品数
                        </label>
                        <div className={this.editorStyle.control()}>
                          <input className={this.editorStyle.joinClassNames(this.editorStyle.input(), this.editorStyle.small())} type="number" defaultValue={shippingItem.shippingNum} disabled readOnly/>
                        </div>
                      </div>
                      <div className={this.editorStyle.joinClassNames(this.editorStyle.field(), this.editorStyle.column())}>
                        <label className={this.editorStyle.joinClassNames(this.editorStyle.label(), this.editorStyle.small())}>
                          納品単価
                        </label>
                        <div className={this.editorStyle.control()}>
                          <input className={this.editorStyle.joinClassNames(this.editorStyle.input(), this.editorStyle.small())} type="number" value={shippingItem.officialItem.latestPrice} disabled readOnly/>
                        </div>
                      </div>
                      <div className={this.editorStyle.joinClassNames(this.editorStyle.field(), this.editorStyle.column())}>
                        <label className={this.editorStyle.joinClassNames(this.editorStyle.label(), this.editorStyle.small())}>
                          小計（税別）
                        </label>
                        <div className={this.editorStyle.control()}>
                          <input className={this.editorStyle.joinClassNames(this.editorStyle.input(), this.editorStyle.small())} type="number" value={this.calcSubtotalTaxExcluded(shippingItem)} disabled readOnly/>
                        </div>
                      </div>
                      <div className={this.editorStyle.joinClassNames(this.editorStyle.field(), this.editorStyle.column())}>
                        <label className={this.editorStyle.joinClassNames(this.editorStyle.label(), this.editorStyle.small())}>
                          小計（税額）
                        </label>
                        <div className={this.editorStyle.control()}>
                          <input className={this.editorStyle.joinClassNames(this.editorStyle.input(), this.editorStyle.small())} type="number" value={this.calcTax(shippingItem)} disabled readOnly/>
                        </div>
                      </div>
                    </div> 
                  </div>
                  <hr />
                </li>
              )
            })}            
          </ul>
          <div>
            <div className={this.editorStyle.buttons()}>
              <button className={this.editorStyle.buttonLight()} onClick={hideAddItemFromOrder}>キャンセル</button>
            </div>
          </div>          
        </div> 
      </div>
    )
  }
}

AddItemFromOrder.propTypes = {
  shop: PropTypes.object.isRequired,
  order: PropTypes.object,
  arrivalItems: PropTypes.array.isRequired,
  handleAddItemFromOrder: PropTypes.func.isRequired,
  hideAddItemFromOrder: PropTypes.func.isRequired
}
