import React, { useEffect, useState } from 'react';
import axios from "../../../lib/axios";

const ModalForm = ({ shops, stockingTransfer }) => {
  const [draftStockingTransfer, setDraftStockingTransfer] = useState({...stockingTransfer});

  useEffect(() => {
    flatpickr("#datepicker1", {
      onChange: (selectedDates, dateStr, instance) => {
        setDraftStockingTransfer({
          ...draftStockingTransfer,
          transfer_date: dateStr
        });
      },
    });
  }, [draftStockingTransfer]);

  useEffect(() => {
    setDraftStockingTransfer(stockingTransfer);
  }, [stockingTransfer]);

  const onChangeValue = (e) => {
    const { name, value } = e.currentTarget;

    const newStockingTransfer = {
      ...draftStockingTransfer,
      [name]: value
    };
    setDraftStockingTransfer(newStockingTransfer);
  };

  const onClickSubmit = () => {
    if (draftStockingTransfer.id) {
      // 更新
      axios.patch(`/stocking_transfers/${draftStockingTransfer.id}`, {
        stocking_transfer: draftStockingTransfer
      }).then((response) => {
        window.location.reload();
      }).catch((error) => {
        if (error.response.status === 422) {
          alert(error.response.data.errors.join("\n"));
        } else {
          alert('保存に失敗しました');
        }
      });
    } else {
      // 新規登録
      axios.post('/stocking_transfers', {
        stocking_transfer: draftStockingTransfer
      }).then((response) => {
        window.location.reload();
      }).catch((error) => {
        if (error.response.status === 422) {
          alert(error.response.data.errors.join("\n"));
        } else {
          alert('登録に失敗しました');
        }
      });
    }
  };

  const renderCounterParty = () => {
    return (
      <select
        className="form-select"
        data-control="select2"
        data-placeholder="選択してください"
        data-hide-search="true"
        name="counter_party_shop_id"
        value={draftStockingTransfer?.counter_party_shop_id ?? ''}
        onChange={onChangeValue}
      >
        <option value="">選択してください</option>
        {shops.map((shop) => {
          return (
            <option key={`counter_party_shop_id_${shop.id}`} value={shop.id}>{shop.name}</option>
          );
        })}
      </select>
    );
  };

  return (
    <div className="modal fade" id="modal_add_transaction" tabIndex="-1" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered mobile-w-auto">
        <div className="modal-content">
          <div className="modal-header align-items-start p-8">
            <div className="flex-column">
              <div className="fs-3 fw-bolder">店舗間商品移動登録</div>
            </div>
            <div className="btn btn-icon btn-sm ms-2 me-n4 mt-n2" data-bs-dismiss="modal">
              <i className="ki-outline ki-cross text-gray-500 fs-1"></i>
            </div>
          </div>
          <div className="modal-body p-8">
            <div className="fv-row d-flex flex-column mb-6">
              <label className="required d-flex align-items-center fs-7 fw-bold mb-2 text-gray-600">
                日付
              </label>
              <input
                name="transfer_date"
                className="form-control form-control-solid fs-6"
                placeholder="日付を選択してください"
                defaultValue={draftStockingTransfer?.transfer_date ?? ''}
                id="datepicker1"
              />
            </div>
            <div className="fv-row d-flex flex-column mb-6">
              <label className="required d-flex align-items-center fs-7 fw-bold mb-2 text-gray-600">
                相手店舗
              </label>
              <div className="input-group input-group-lg me-4">
                {renderCounterParty()}
              </div>
            </div>
            <div className="fv-row d-flex flex-column mb-6">
              <label className="required d-flex align-items-center fs-7 fw-bold mb-4 text-gray-600">
                移動種別
              </label>
              <div className="d-flex align-items-center">
                <div className="form-check form-check-custom form-check-solid me-6">
                  <input
                    className="form-check-input transaction_transferout"
                    type="radio"
                    name="transfer_type"
                    value="out"
                    checked={draftStockingTransfer?.transfer_type === 'out'}
                    onChange={onChangeValue}
                  />
                  <label className="form-check-label">
                    提供
                  </label>
                </div>
                <div className="form-check form-check-custom form-check-solid me-6">
                  <input
                    className="form-check-input transaction_transferin"
                    type="radio"
                    name="transfer_type"
                    value="in"
                    checked={draftStockingTransfer?.transfer_type === 'in'}
                    onChange={onChangeValue}
                  />
                  <label className="form-check-label">
                    仕入
                  </label>
                </div>
              </div>
            </div>
            <div className={draftStockingTransfer?.transfer_type === 'out' ? 'transferout_elements' : 'transferin_elements'}>
              <div className="fv-row d-flex flex-column mb-6">
                <label className="d-flex align-items-center fs-7 fw-bold mb-4 text-gray-600">
                  {draftStockingTransfer?.transfer_type === 'out' ? '提供' : '仕入'}商品金額（税抜）
                </label>
                <div className="d-flex align-items-center">                                                        
                  <div className="input-group">
                    <span className="input-group-text">¥</span>
                    <input type="text" className="form-control fs-6" name="price_excluded_tax" value={draftStockingTransfer?.price_excluded_tax ?? ''} onChange={onChangeValue} />
                  </div>
                </div>
              </div>
              <div className="fv-row d-flex flex-column mb-6">
                <label className="required d-flex align-items-center fs-7 fw-bold mb-2 text-gray-600">
                  商品タイプ
                </label>
                <div className="input-group input-group-lg me-4">
                  <select
                    name="item_type"
                    className="form-select"
                    data-control="select2"
                    data-placeholder="選択してください"
                    data-hide-search="true"
                    value={draftStockingTransfer?.item_type}
                    onChange={onChangeValue}
                  >
                    <option value="">選択してください</option>
                    <option value="food">フード</option>
                    <option value="drink">ドリンク</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-light" data-bs-dismiss="modal">キャンセル</button>
            <button type="button" className="btn btn-primary" onClick={onClickSubmit}>
              {draftStockingTransfer?.id ? '保存する' : '登録する'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalForm;
