import React from 'react';

const PriceQty = ({ active, priceQty, additionalClassName, monthlyBudget, showCompositionRatio=false }) => {
  return (
    <div className={`card h-475px card-stretch card-bordered rounded-0 ${additionalClassName}`}>
      <div className="card-header px-4 pt-1 border-bottom-0">
        <div className="card-title fs-9 text-gray-400">
          <div className="symbol symbol-30px me-3">
            <div className={`symbol-label fs-6 fw-bolder ${active ? "bg-gray" : ""} text-white`}>P<small className="text-white">×</small>Q</div>
          </div>
          <div className="flex-column">
            <div className="text-gray-500 fs-9">Price × Qty</div>
            <div className={`${active ? "text-gray-600" : "text-gray-500"} fs-8 lh-sm`}>売上（税抜）</div>
          </div>
        </div>
      </div>
      <div className="card-body px-4 pb-4 pt-0">
        <div className={`fs-3 fw-bolder mb-4 ${priceQty == null ? "text-gray-500" : ""}`}>
          ¥{priceQty == null ? '--' : priceQty.toLocaleString()}
        </div>
        {showCompositionRatio && (
            <>
                <div className="mb-4">
                  <div className="fs-9 text-gray-600 mb-0">ランチ売上比</div>
                  <div className="fs-6 fw-bolder text-gray-600 mb-1">
                    {monthlyBudget.sales_ratio_lunch}%
                  </div>
                  <div className="fs-9 text-gray-600 mb-0">ディナー売上比</div>
                  <div className="fs-6 fw-bolder text-gray-600 mb-1">
                    {monthlyBudget.sales_ratio_dinner}%
                  </div>
                </div>
                <div>
                  <div className="fs-9 text-gray-600 mb-0">フード売上比</div>
                  <div className="fs-6 fw-bolder text-gray-600 mb-1">
                    {monthlyBudget.sales_ratio_food}%
                  </div>
                  <div className="fs-9 text-gray-600 mb-0">ドリンク売上比</div>
                  <div className="fs-6 fw-bolder text-gray-600 mb-1">
                    {monthlyBudget.sales_ratio_drink}%
                  </div>
                  <div className="fs-9 text-gray-600 mb-0">その他売上比</div>
                  <div className="fs-6 fw-bolder text-gray-600 mb-1">
                    {monthlyBudget.sales_ratio_other}%
                  </div>
                </div>
            </>
        )}
      </div>
    </div>
  );
};

export default PriceQty;
