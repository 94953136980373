import React, { useCallback, useMemo, useState } from "react";
import axios from "../../../lib/axios";

const LabelSelect = ({shopItemId, initialLabelId, labels}) => {
  const [loading, setLoading] = useState(false);
  const [currentLabelId, setCurrentLabelId] = useState(initialLabelId || "");
  const apiPath = useMemo(() => `/api/shop_items/${shopItemId}/labels`, [shopItemId])

  const handleChangeSelect = useCallback((e) => {
    const value = e.target.value;
    if( loading || value === currentLabelId ) { return; }
    setLoading(true);
    
    axios({
      method: value === "" ? "delete" : "put",
      url: apiPath,
      data: value === "" ? null : { label_id: value }
    })
    .then((res) => {
      res.status === 200 && setCurrentLabelId(value); 
    })
    .catch((e) => {
      console.log(e);
      window.alert("ラベルの変更に失敗しました");
    })
    .then(() => setLoading(false));
  }, [labels, currentLabelId, loading]);

  return (
    <select className="form-select" value={currentLabelId} disabled={loading} onChange={handleChangeSelect}>
      <option value="">未設定</option>
      {
        labels.map((label) => {
          return (
            <option key={`label-${label.id}`} value={label.id}>{label.name}</option>
          )
        })
      }
    </select>
  )
}

export default LabelSelect;
