import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { ReactSortable } from "react-sortablejs";
import moment from "moment";
import arrayMove from 'array-move';
import axios from '../../../lib/axios'

const List = ({ defaultLabels }) => {
  const [labels, setLabels] = useState(defaultLabels);

  const updateList = (newLabels) => {
    setLabels(newLabels);
  }

  const handleReorder = (evt) => {
    const newLabels = arrayMove(labels, evt.oldIndex, evt.newIndex);
    const ids = newLabels.map((label) => label.id);
    axios.post('/api/labels/reorder', { ids: ids })
      .then((response) => {
        // Nothing todo
      })
      .catch((error) => {
        alert('並び替えの保存に失敗しました');
      });
  }

  const renderLabels = () => {
    return labels.map((label, index) => {
      const createdAt = moment(label.created_at);
      const updatedAt = moment(label.updated_at);

      return (
        <tr key={label.id}>
          <td>
            <i className="fas fa-list sort-handle" style={{ cursor: 'grab' }}></i>
          </td>
          <td className="text-gray-800 fw-bolder">
            {label.name}
          </td>
          <td>
            {label.default && (
              <span>デフォルト</span>
            )}
          </td>
          <td>{createdAt.format("YYYY-MM-DD")}</td>
          <td>{updatedAt.format("YYYY-MM-DD")}</td>
          <td className="text-end">
            <a href={`/labels/${label.id}`} className="btn btn-sm btn-light-primary">詳細</a>
          </td>
        </tr>
      )
    });
  }

  return (
    <div className="table-responsive">
      <table className="table align-middle table-row-bordered gy-3 gs-7">
        <thead>
          <tr className="fw-bolder fs-7 text-muted">
            <th width="20px;"></th>
            <th className="min-w-150px pt-5">ラベル名</th>
            <th className="min-w-100px pt-5">デフォルトラベル</th>
            <th className="min-w-100px pt-5">ラベル作成日時</th>
            <th className="min-w-100px pt-5">ラベル編集日時</th>
            <th className="min-w-100px text-end pt-5">アクション</th>
          </tr>
        </thead>
        <ReactSortable
          tag="tbody"
          list={labels}
          setList={updateList}
          handle=".sort-handle"
          animation={150}
          ghostClass="has-background-success-light"
          onUpdate={handleReorder}
        >
          {renderLabels()}
        </ReactSortable>
      </table>
    </div>
  );
}

List.propTypes = {
  defaultLabels: PropTypes.array.isRequired
}

export default List;
