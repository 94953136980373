import React from 'react'
import PropTypes from 'prop-types'
import {SortableContainer, SortableElement, SortableHandle} from 'react-sortable-hoc'

// ソート用ハンドル
const DragHandle = SortableHandle(() => <span><i className="fas fa-list" /></span>);

// ソート商品の１つ
const SortableItem = SortableElement(({item}) => {
  // Drag&Drop のときの見た目の関係でcssを変更してます
  return (
    <div className="table__row">
      <div className="table__row__col table__row__col-handle">
        <DragHandle />
      </div>
      <div className="table__row__col table__row__col-name">
        {item.name}
        <br />
        <small className="has-text-grey">{item.hiragana_name}</small>
      </div>
      <div className="table__row__col table__row__col-code">{item.code}</div>
      <div className="table__row__col">{item.status_name}</div>
      <div className="table__row__col">{item.unit_name}</div>
      <div className="table__row__col table__row__col-tax">{item.tax_type_name}</div>
      <div className="table__row__col">{item.price}</div>
      <div className="table__row__col table__row__col-edit">
        <a href={Routes.edit_cs_shop_unofficial_item_path(item.shop_id, item.id)}>編集</a>
      </div>
    </div>
  );
});

// ソートの一覧
const SortableList = SortableContainer(({items}) => {
  return (
    <div className="table__body">
      {items.map((item, index) => (
        <SortableItem key={`item-${index}`} index={index} item={item} />
      ))}
    </div>
  );
});

class UnsortableList extends React.Component {
  render () {
    return (
      <div className="table__body">
        {this.props.items.map((item, index) => (
          <div className="table__row">
            <div className="table__row__col table__row__col-handle">
            </div>
            <div className="table__row__col table__row__col-name">
                {item.name}
                <br />
                <small className="has-text-grey">{item.hiragana_name}</small>
            </div>            
            <div className="table__row__col table__row__col-code">{item.code}</div>
            <div className="table__row__col">{item.status_name}</div>
            <div className="table__row__col">{item.unit_name}</div>
            <div className="table__row__col table__row__col-tax">{item.tax_type_name}</div>
            <div className="table__row__col">{item.price}</div>
            <div className="table__row__col table__row__col-edit">
              <a href={Routes.edit_cs_shop_unofficial_item_path(item.shop_id, item.id)}>編集</a>
            </div>
          </div>
        ))}
      </div>
    ); 
  }  
}

export default class Form extends React.Component {
  constructor(props) {
    super(props)

    this._handleSortEnd = this._handleSortEnd.bind(this)
  }

  render() {
    return (
      <div>
        <div className="box">
          <div className="table is-fullwidth">
            <div className="table__header">
              <div className="table__row">
                <div className="table__row__col table__row__col-handle"></div>
                <div className="table__row__col table__row__col-name">商品名</div>
                <div className="table__row__col table__row__col-code">商品コード</div>
                <div className="table__row__col">ステータス</div>
                <div className="table__row__col">単位</div>
                <div className="table__row__col table__row__col-tax">税区分</div>
                <div className="table__row__col">価格</div>
                <div className="table__row__col table__row__col-edit"></div>
              </div>
            </div>
            <div id="scroll_container" style={{height: 422, overflow: 'hidden scroll'}}>
              { this.props.sortable ? 
                <SortableList items={this.props.items} onSortEnd={this._handleSortEnd} getContainer={() => document.getElementById('scroll_container')} useDragHandle />
                : 
                <UnsortableList items={this.props.items} onSortEnd={this._handleSortEnd}></UnsortableList>
              }
            </div>
          </div>
        </div>
      </div>      
    )
  }

  // 並び替え完了
  _handleSortEnd({oldIndex, newIndex}) {
    this.props.onSortEnd(oldIndex, newIndex)
  }
}

Form.propTypes = {
  items: PropTypes.array.isRequired,
  onSortEnd: PropTypes.func.isRequired,
  sortable: PropTypes.bool.isRequired
}
