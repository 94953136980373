import React, { useMemo } from 'react';

const Step0 = ({ step, otherCost, otherCostRate, setStep }) => {
  const completedClass = useMemo(() => {
    if (step != 0 && (otherCost != null || otherCostRate != null)) {
      return 'completed';
    }

    return '';
  }, [step, otherCost, otherCostRate]);

  const clickHandler = (e) => {
    e.preventDefault();
    setStep(0);
  };

  const renderStep = () =>  {
    return (
      <>
        <div className="stepper-wrapper align-items-start">
          <div className="stepper-icon min-w-40px min-h-40px">
            <i className="ki-duotone ki-check fs-2 stepper-check"></i>
            <span className="stepper-number">0</span>
          </div>
          <div className="flex-column">
            <div className="stepper-label">
              <h3 className="stepper-title my-3">初期設定値確認</h3>
            </div>
            {step == 0 && (
              <>
                <div className="card-title mt-8">
                  <h3 className="fw-bold me-2 fs-7 text-gray-600 my-0">その他変動費率</h3>
                  <div className="fs-2 fw-bolder mt-1">
                    {otherCostRate ?? 0}%
                  </div>
                </div>
                <div className="card-title mt-4">
                  <h3 className="fw-bold me-2 fs-7 text-gray-600 my-0">固定費</h3>
                  <div className="fs-2 fw-bolder mt-1">
                    ¥{otherCost == null ? 0 : otherCost.toLocaleString()}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        <div className={`stepper-line ${step == 0 ? 'h-175px' : 'h-40px'}`}></div>
      </>
    );
  };

  return (
    <>
      {step == 0 ? (
        <div className={`stepper-item current pb-40 ${completedClass}`}>
          {renderStep()}
        </div>
      ) : (
        <a href="#" className={`stepper-item pb-40 ${completedClass}`} onClick={clickHandler}>
          {renderStep()}
        </a>
      )}
    </>
  );
};

export default Step0;
