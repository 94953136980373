import React, { Component } from 'react';
import OrderSelector from './order_selector'
import PropTypes from "prop-types";

export default class OrderSearch extends Component {  
  constructor(props) {
    super(props)

    this.editorStyle = this.props.editorStyle
  }

  render () {
    const { shop, shopVendor, addNewBasket, hideOrderSearch } = this.props;
    return (
      <>
        <div className={this.editorStyle.joinClassNames(this.editorStyle.box(), this.editorStyle.marginTop())}>
          <div className={this.editorStyle.boxHeader()}>
            <h3 className={this.editorStyle.title()}>注文を追加</h3>
          </div>      
          
          <div className={this.editorStyle.boxDetail()}>
            <OrderSelector
              shop={shop}
              shopVendor={shopVendor}
              addNewBasket={addNewBasket}
              editorStyle={this.editorStyle}
            />
            <div className={this.editorStyle.marginTop()} align="right">
              <button
                className={this.editorStyle.buttonLight()}
                onClick={hideOrderSearch}>
                キャンセル
              </button>
            </div>
          </div>                    
        </div>
      </>
    )
  }
}

OrderSearch.propTypes = {
  shop: PropTypes.object.isRequired,
  shopVendor: PropTypes.object.isRequired,
  addNewBasket: PropTypes.func.isRequired,
  hideOrderSearch: PropTypes.func.isRequired
}
