import React from 'react';

const VariableQty = ({ variableQty, trialVariableQty, editable, heightClass='h-350px' }) => {
  return (
    <div class="card min-h-250px card-bordered rounded-0">
      <div class="card-header px-4 pt-1 border-bottom-0">
        <div class="card-title fs-9 text-gray-400">
          <div class="symbol symbol-30px me-3">
            <div class="symbol-label fs-6 fw-bolder bg-gray text-white">V<small class="text-white">×</small>Q</div>
          </div>
          <div class="flex-column">
        <div class="text-gray-500 fs-9">Variable × Qty</div>
        <div class="text-gray-600 fs-8 lh-sm">変動費</div>
      </div>
        </div>
      </div>
      <div class="card-body px-4 pb-4 pt-0">
        <div class="fs-4 fw-bolder mb-2 pb-1 text-gray-600">
          ¥{Math.round(variableQty).toLocaleString()}
        </div>
        <div class="fs-1 fw-bolder my-6 pb-0">
          ¥{trialVariableQty != null ? Math.round(trialVariableQty).toLocaleString() : '--'}
        </div>
      </div>
    </div>
  );
};

export default VariableQty;
