import React, { useEffect, useState, useMemo } from 'react';
import axios from '../../../lib/axios';
import moment from "moment";

const ModalCompositionRatio = ({ monthlyBudget }) => {
  const [draftMonthlyBudget, setDraftMonthlyBudget] = useState(monthlyBudget);

  const currentMonth = useMemo(() => moment(`${monthlyBudget.year_month}01`));

  useEffect(() => {
    setDraftMonthlyBudget({
      ...monthlyBudget,
      sales_ratio_lunch: monthlyBudget.sales_ratio_lunch ?? 0,
      sales_ratio_dinner: monthlyBudget.sales_ratio_dinner ?? 100,
      sales_ratio_food: monthlyBudget.sales_ratio_food ?? 100,
      sales_ratio_drink: monthlyBudget.sales_ratio_drink ?? 0,
      sales_ratio_other: monthlyBudget.sales_ratio_other ?? 0
    });
  }, []);

  const onChangeValue = (e) => {
    const { name, value } = e.currentTarget;

    setDraftMonthlyBudget({
      ...draftMonthlyBudget,
      [name]: value,
    });
  };

  // 保存
  const onClickSave = () => {
    // validation
    if (+draftMonthlyBudget.sales_ratio_lunch + +draftMonthlyBudget.sales_ratio_dinner !== 100) {
        alert('ランチ/ディナー比率は合計100%にしてください');
        return;
    }
    if (+draftMonthlyBudget.sales_ratio_food + +draftMonthlyBudget.sales_ratio_drink + +draftMonthlyBudget.sales_ratio_other !== 100) {
        alert('FD構成比は合計100%にしてください');
        return;
    }

    const year = currentMonth.year();
    const month = currentMonth.month() + 1;
    const url = `/shops/monthly_budgets/${year}/${month}/setting/composition_ratio`;

    axios.post(url, {
      monthly_budget: {
        sales_ratio_lunch: draftMonthlyBudget.sales_ratio_lunch,
        sales_ratio_dinner: draftMonthlyBudget.sales_ratio_dinner,
        sales_ratio_food: draftMonthlyBudget.sales_ratio_food,
        sales_ratio_drink: draftMonthlyBudget.sales_ratio_drink,
        sales_ratio_other: draftMonthlyBudget.sales_ratio_other
      }
    })
    .then((_response) => {
      window.location.reload();
    })
    .catch((_error) => {
      alert('保存に失敗しました。設定内容をご確認ください');
    });
  };

  return (
    <div className="modal fade" id="modal_revenue" tabIndex="-1" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered mobile-w-auto">
        <div className="modal-content">
          <div className="modal-header align-items-start p-8">
            <div className="flex-column">
              <div className="fs-4 fw-bolder">売上構成比設定</div>
            </div>
            <div className="btn btn-icon btn-sm ms-2 me-n4 mt-n2" data-bs-dismiss="modal">
              <i className="ki-outline ki-cross text-gray-500 fs-1"></i>
            </div>
          </div>
          <div className="modal-body py-8 px-7">
            <div className="mb-4">
              <label className="fs-7 fw-semibold form-label text-muted">ランチ/ディナー比率</label>
            </div>
            <div className="d-flex gap-6 mb-6">
              <div className="pt-0 pb-0">
                <div className="card-title fw-bolder me-2 fs-8">
                  ランチ
                </div>
                <div className="input-group input-group-solid">
                  <input
                    type="number"
                    className="form-control number-input"
                    placeholder="数値を入力"
                    name="sales_ratio_lunch"
                    value={draftMonthlyBudget.sales_ratio_lunch ?? 0}
                    onChange={onChangeValue}
                  />
                  <span className="input-group-text">%</span>
                </div>
              </div>
              <div className="pt-0 pb-0">
                <div className="card-title fw-bolder me-2 fs-8">
                  ディナー
                </div>
                <div className="input-group input-group-solid">
                  <input
                    type="number"
                    className="form-control number-input"
                    placeholder="数値を入力"
                    name="sales_ratio_dinner"
                    value={draftMonthlyBudget.sales_ratio_dinner ?? 100}
                    onChange={onChangeValue}
                  />
                  <span className="input-group-text">%</span>
                </div>
              </div>
            </div>
            <div className="mb-4">
              <label className="fs-7 fw-semibold form-label text-muted">FD構成比</label>
            </div>
            <div className="d-flex gap-6">
              <div className="pt-0 pb-0">
                <div className="card-title fw-bolder me-2 fs-8">
                  フード
                </div>
                <div className="input-group input-group-solid">
                  <input
                    type="number"
                    className="form-control number-input"
                    placeholder="数値を入力"
                    name="sales_ratio_food"
                    value={draftMonthlyBudget.sales_ratio_food ?? 100}
                    onChange={onChangeValue}
                  />
                  <span className="input-group-text">%</span>
                </div>
              </div>
              <div className="pt-0 pb-0">
                <div className="card-title fw-bolder me-2 fs-8">
                  ドリンク
                </div>
                <div className="input-group input-group-solid">
                  <input
                    type="number"
                    className="form-control number-input"
                    placeholder="数値を入力"
                    name="sales_ratio_drink"
                    value={draftMonthlyBudget.sales_ratio_drink ?? 0}
                    onChange={onChangeValue}
                  />
                  <span className="input-group-text">%</span>
                </div>
              </div>
              <div className="pt-0 pb-0">
                <div className="card-title fw-bolder me-2 fs-8">
                  その他
                </div>
                <div className="input-group input-group-solid">
                  <input
                    type="number"
                    className="form-control number-input"
                    placeholder="数値を入力"
                    name="sales_ratio_other"
                    value={draftMonthlyBudget.sales_ratio_other ?? 0}
                    onChange={onChangeValue}
                  />
                  <span className="input-group-text">%</span>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer justify-content-between">
            <button type="button" className="btn btn-light" data-bs-dismiss="modal">キャンセル</button>
            <button type="button" className="btn btn-primary" onClick={onClickSave}>保存する</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalCompositionRatio;
