import React from 'react';

const MarginQty = ({ marginQty, trialMarginQty, editable }) => {
  return (
    <div class="card card-stretch card-bordered rounded-0">
      <div class="card-header px-4 pt-1 border-bottom-0">
        <div class="card-title fs-9 text-gray-400">
          <div class="symbol symbol-30px me-3">
            <div class="symbol-label fs-6 fw-bolder  bg-gray text-white">M<small class="text-white">×</small>Q</div>
          </div>
          <div class="flex-column">
        <div class="text-gray-500 fs-9">Margin × Qty</div>
        <div class="text-gray-600 fs-8 lh-sm">粗利</div>
      </div>
        </div>
      </div>
      <div class="card-body px-4 pb-4 pt-0">
        <div class="fs-4 fw-bolder mb-2 text-gray-600">
          ¥{Math.round(marginQty).toLocaleString()}
        </div>
        <div class="fs-1 fw-bolder my-6 pb-0">
          ¥{trialMarginQty != null ? Math.round(trialMarginQty).toLocaleString() : '--'}
        </div>
      </div>
    </div>
  );
};

export default MarginQty;
