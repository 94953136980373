import React from 'react';
import Chart from 'react-apexcharts';

const LineChart = ({ categories, foodSeries, drinkSeries }) => {
  const series = [{
      name: 'フード仕入比率',
      data: foodSeries
      }, {
      name: 'ドリンク仕入比率',
      data: drinkSeries
  }];
  const options = {
    chart: {
      type: 'line',
      height: '240',
      fontFamily: 'Open Sans, Noto Sans Japanese, Sans-Serif',
      toolbar: {
        show: false
      }
    },
    stroke: {
      show: true,
      width: 2,
    },
    markers: {
      size: 5,
      hover: {
      size: 6
      }
    },
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: [4],
        columnWidth: '80%',
        endingShape: 'rounded'
      },
    },
    legend: {
      position: 'top',
      fontSize: "11px",
      fontFamily: 'Open Sans, Noto Sans Japanese, Sans-Serif',
      labels: {
        colors: KTUtil.getCssVariableValue("--bs-gray-600"),
      }
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories,
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      },
      labels: {
        show: true,
        rotate: 0,
        hideOverlappingLabels: true,
        style: {
          fontFamily: 'Open Sans, Noto Sans Japanese, Sans-Serif',
          colors: KTUtil.getCssVariableValue("--bs-gray-500"),
          fontSize: "11px"
        }
      }
    },
    yaxis: {
      labels: {
        show: true,
        rotate: 0,
        hideOverlappingLabels: true,
        style: {
          fontFamily: 'Open Sans, Noto Sans Japanese, Sans-Serif',
          colors: KTUtil.getCssVariableValue("--bs-gray-500"),
          fontSize: "11px"
        }
      }
    },
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        inverseColors: false,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [100, 100, 100, 100]
      },
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: [{
        formatter: function(y) {
          return y.toLocaleString() + "%";
        }
      }, {
        formatter: function(y) {
          return y.toLocaleString() + "%";
        }
      }]
    },
    colors: [KTUtil.getCssVariableValue("--bs-orange"),KTUtil.getCssVariableValue("--bs-cyan")],
    grid: {
      borderColor: KTUtil.getCssVariableValue("--bs-gray-200"),
      strokeDashArray: 2,
      yaxis: {
        lines: {
          show: true
        }
      },
      padding: {
        top: 0,
        right: 24,
        bottom: 0,
        left: 10
      }
    },
  };

  return (
    <div id="fd_monthly_trend" className="mx-n5 pt-4 pb-4">
      <Chart options={options} series={series} type="line" height="240" />
    </div>
  );
};

export default LineChart;
