import React from 'react';
import Chart from 'react-apexcharts';

const Item = ({ sales, categories, label }) => {
  const series = [{
    name: label,
    type: "bar",
    data: sales
  }];
  const options = {
    chart: {
      type: 'bar',
      height: 45 * sales.length,
      fontFamily: 'inherit',
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      bar: {
        horizontal: true,
        borderRadius: [6],
        columnWidth: '30%',
        endingShape: 'rounded'
      },
    },
    legend: {
      show: false
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: "smooth",
      show: true,
      width: 2,
      colors: ['transparent']
    },
    responsive: [{
      breakpoint: 576,
      options: {
        chart: {
          height: 500,
        },
        xaxis: {
            labels: {
            show: false
          }
        }
      }
    }],
    xaxis: {
      categories: categories,
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
        labels: {
          style: {
            fontFamily: 'inherit',
            colors: KTUtil.getCssVariableValue("--bs-gray-500"),
            fontSize: "12px"
          }
        }
    },
    yaxis: {
      labels: {
        style: {
          colors: KTUtil.getCssVariableValue("--bs-gray-600"),
          fontSize: "10px"
        }
      }
    },
    fill: {
      opacity: 1
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: [{
        formatter: function(y) {
          if (typeof y !== "undefined") {
            return "¥" + y.toFixed(0);
          }
          return y;
        }
      }]
    },
    colors: [KTUtil.getCssVariableValue("--bs-primary")],
    grid: {
      borderColor: KTUtil.getCssVariableValue("--bs-gray-200"),
      strokeDashArray: 2,
      yaxis: {
        lines: {
          show: true
        }
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    }
  };

  return (
    <div id="chart_analysis_sales_item_ratio" className="pe-md-4 pb-2">
      <Chart options={options} series={series} type="bar" height={45 * sales.length} />
    </div>
  );
};

export default Item;
