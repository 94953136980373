import React, { useState } from 'react'
import PropTypes from 'prop-types'

const ItemRow = ({ item, removeHandler, saveHandler }) => {
  const [editting, setEditting] = useState(false);
  const [draftItem, setDraftItem] = useState(item);

  const handleEdit = () => {
    setEditting(true);
  }

  const handleCancel = () => {
    setDraftItem(item);
    setEditting(false);
  }

  const handleNameChange = (e) => {
    const newItem = {
      ...draftItem,
      name: e.currentTarget.value
    };
    setDraftItem(newItem);
  }

  const handleUpdate = () => {
    saveHandler(draftItem, () => {
      setEditting(false);
    });
  }

  const handleRemove = () => {
    removeHandler(item);
  }

  return (
    <tr>
      <td className="fw-bold">
        {editting ? (
          <input type="text" className="form-control" value={draftItem.name} onChange={handleNameChange}/>
        ) : (
          <span>{item.name}</span>
        )}
      </td>
      <td className="text-end">
        {!item.immutable &&
          <>
            {editting ? (
              <>
                <button className="btn btn-sm btn-secondary me-2" onClick={handleCancel}>キャンセル</button>
                <button className="btn btn-sm btn-primary" onClick={handleUpdate}>変更</button>
              </>
            ) : (
              <>
                <button className="btn btn-sm btn-danger me-2" onClick={handleRemove}>削除</button>
                <button className="btn btn-sm btn-light-primary" onClick={handleEdit}>名前の変更</button>
              </>
            )}
          </>
        }
      </td>
    </tr>
  )
}

ItemRow.propTypes = {
  item: PropTypes.object.isRequired,
  removeHandler: PropTypes.func.isRequired,
  saveHandler: PropTypes.func.isRequired
}

export default ItemRow
