import React, { useMemo } from 'react';

const Variable = ({
  foodCostRate,
  laborCostRate,
  otherRatioCostRate,
  cost,
  trialVariableRate,
  trialCost,
  editable,
  changeTrialVariableRateHandler
}) => {
  const variableRate = useMemo(() => {
    return Math.round((foodCostRate + laborCostRate + otherRatioCostRate) * 10) / 10;
  }, []);

  const changeHandler = (e) => {
    if (e.currentTarget.value == '') {
      changeTrialVariableRateHandler(null);
    } else {
      changeTrialVariableRateHandler(Math.round(+e.currentTarget.value * 10) / 10);
    }
  };

  return (
    <div class="card min-h-250px card-bordered rounded-0">
      <div class="card-header px-4 pt-1 border-bottom-0">
        <div class="card-title fs-9 text-gray-400">
          <div class="symbol symbol-30px me-3">
            <div class="symbol-label bg-primary fs-6 fw-bolder text-white">V</div>
          </div>
          <div class="flex-column">
            <div class="text-gray-500 fs-9">Variable</div>
            <div class="text-gray-600 fs-8">変動費単価</div>
          </div>
        </div>
      </div>
      <div class="card-body px-4 pb-0 pt-0">        
        <div class="fs-4 fw-bolder mb-2 pb-1 text-gray-600">
          ¥{cost.toLocaleString()}
        </div>
        <div class="fs-1 fw-bolder my-6 pb-0">
          ¥{trialCost != null ? trialCost.toLocaleString() : '--'}
        </div>
        <div class="fs-8 fw-bolder text-gray-600 mb-0 mt-4">変動費率
        </div>
        <div class="d-flex mt-0 mb-0 justify-content-between mobile-flex-column gap-4">
          <div>
            <div class="fs-4 fw-bolder text-gray-600 mt-4">
              {variableRate}%
            </div>
          </div>
          <div class="pt-0 pb-4">
            <div class="mt-2 mb-2">
              <div class="input-group input-group-solid">
                <input
                    type="number"
                    className="form-control"
                    placeholder="入力"
                    value={trialVariableRate ?? ''}
                    step="0.1"
                    onChange={changeHandler}
                  />
                <span class="input-group-text">%</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Variable;
