import React from 'react'
import PropTypes from 'prop-types'
import ReactPaginate from 'react-paginate'

export default class Items extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      keyword: '',
      page: 1
    }

    this._handleClickAdd = this._handleClickAdd.bind(this)
    this._handleClickPage = this._handleClickPage.bind(this)
    this._handleChangeKeyword = this._handleChangeKeyword.bind(this)
    this._handleClickSearch = this._handleClickSearch.bind(this)
  }

  render() {
    return (
      <div className="box">
        <h2 className="title is-4">商品検索</h2>

        {this._renderSearchForm()}

        <table className="table no-footer is-striped is-fullwidth">
          <thead>
            <tr>
              <th>商品名</th>
              <th>商品コード</th>
              <th>単位</th>
              <th>価格</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {this._renderItems()}
          </tbody>
        </table>

        {this._renderPagination()}
      </div>
    )
  }

  /**
   * 検索フォーム
   */
  _renderSearchForm() {
    return (
      <div className="field has-addons">
        <div className="control">
          <input
            type="search"
            name="name_or_code_cont"
            value={this.state.keyword}
            className="input"
            onChange={this._handleChangeKeyword}
          />
        </div>
        <div className="control">
          <button className="button is-info" onClick={this._handleClickSearch}>絞り込み</button>
        </div>
      </div>
    )
  }

  /**
   * 商品一覧を表示
   */
  _renderItems() {
    return this.props.officialItems.map((item, i) => {
      return (
        <tr key={i}>
          <td>{item.name}</td>
          <td>{item.code}</td>
          <td>{item.unit_name}</td>
          <td>{item.price}</td>
          <td>{this._renderButton(i)}</td>
        </tr>
      )
    })
  }

  /**
   * 操作ボタン(追加 OR 済）
   */
  _renderButton(index) {
    // 追加済み
    const isUsed = this.props.items.some((item) => {
      return item.id == this.props.officialItems[index].id
    })
    if (isUsed) {
      return (
        <button className="button is-light" data-index={index} disabled={true}>済み</button>
      )
    }

    return (
      <button className="button is-primary" data-index={index} onClick={this._handleClickAdd}>追加</button>
    )
  }

  _renderPagination() {
    return (
      <div className="pagination is-centered">
        <ReactPaginate
          previousLabel={'previous'}
          nextLabel={'next'}
          breakLabel={'...'}
          breakClassName={'break-me'}
          pageCount={this.props.officialItemsTotal / 25}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={this._handleClickPage}
          containerClassName={'pagination-list'}
          subContainerClassName={'pages pagination'}
          activeClassName={'active'}
          activeLinkClassName={'is-current'}
          previousLinkClassName={'pagination-previous'}
          nextLinkClassName={'pagination-next'}
          pageLinkClassName={'pagination-link'}
          forcePage={this.state.page - 1}
        />
      </div>
    )
  }

  /**
   * キーワード変更
   */
  _handleChangeKeyword(e) {
    const keyword = e.currentTarget.value
    this.setState({keyword: keyword})
  }

  /**
   * 追加ボタンを押した
   */
  _handleClickAdd(e) {
    const index = e.currentTarget.dataset.index

    this.props.onAdd(e, this.props.officialItems[index])
  }

  /**
   * キーワード検索
   */
  _handleClickSearch(e) {
    this._handleClickPage({selected: 0})
  }

  /**
   * click pager
   */
  _handleClickPage(page) {
    this.setState({page: page.selected + 1})
    this.props.onSearch(this.state.keyword, page.selected + 1)
  }
}

Items.propTypes = {
  officialItems: PropTypes.array.isRequired,
  officialItemsTotal: PropTypes.number.isRequired,
  items: PropTypes.array.isRequired,
  onAdd: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired
}
