import React, { useMemo } from 'react';

const Step2 = ({ step, foodCostRate, laborCostRate, changeFoodCostRateHandler, changeLaborCostRateHandler, setStep }) => {
  const completedClass = useMemo(() => {
    if (step != 2 && foodCostRate != null && laborCostRate != null) {
      return 'completed';
    }

    return '';
  }, [step, foodCostRate, laborCostRate]);

  const changeFoodHandler = (e) => {
    const newValue = e.currentTarget.value;
    changeFoodCostRateHandler(newValue ? parseFloat(newValue) : null);
  };

  const changeLaborHandler = (e) => {
    const newValue = e.currentTarget.value;
    changeLaborCostRateHandler(newValue ? parseFloat(newValue) : null);
  };

  const clickHandler = (e) => {
    e.preventDefault();
    setStep(2);
  };

  const renderStep = () =>  {
    return (
      <>
        <div className="stepper-wrapper align-items-start">
          <div className="stepper-icon min-w-40px min-h-40px">
            <i className="ki-duotone ki-check fs-2 stepper-check"></i>
            <span className="stepper-number">2</span>
          </div>
          <div className="flex-column">
            <div className="stepper-label">
              <h3 className="stepper-title my-1">仕入費率 &amp; <br />PA人件費率を設定</h3>
            </div>
            {step == 2 && (
              <>
                <div className="card-title mt-8">
                  <h3 className="fw-bolder me-2 fs-7 my-0">仕入費率<small> ※小数点以下1桁</small></h3>
                </div>
                <div className="pt-2 pb-0">
                  <div className="input-group input-group-solid">
                    <input
                      type="number"
                      step="0.1"
                      className="form-control"
                      placeholder="入力"
                      value={foodCostRate ?? ''}
                      onChange={changeFoodHandler}
                    />
                    <span className="input-group-text">%</span>
                  </div>
                </div>
                <div className="card-title mt-6">
                  <h3 className="fw-bolder me-2 fs-7 my-0">PA人件費率<small> ※小数点以下1桁</small></h3>
                </div>
                <div className="pt-2 pb-0">
                  <div className="input-group input-group-solid">
                    <input
                      type="number"
                      step="0.1"
                      className="form-control"
                      placeholder="入力"
                      value={laborCostRate ?? ''}
                      onChange={changeLaborHandler}
                    />
                    <span className="input-group-text">%</span>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        <div className={`stepper-line ${step == 2 ? 'h-225px' : 'h-40px'}`}></div>
      </>
    );
  };

  return (
    <>
      {step == 2 ? (
        <div className={`stepper-item current pb-40 ${completedClass}`}>
          {renderStep()}
        </div>
      ) : (
        <a href="#" className={`stepper-item pb-40 ${completedClass}`} onClick={clickHandler}>
          {renderStep()}
        </a>
      )}
    </>
  );
};

export default Step2;
