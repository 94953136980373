import React from 'react';
import PropTypes from 'prop-types';
const DAYS = [1,2,3,4,5,6,0,7];
const DAY_NAMES = ["日", "月", "火", "水", "木", "金", "土", "祝日"];

const RegularHolidayForm = ({days, onChange}) => {
  return (
    <div className="card-body border-top p-9 d-flex gap-5 flex-wrap">
      { DAYS.map(
        (day, index) => (
          <div key={`day-${index}`} className="form-check form-check-custom form-check-solid form-check-lg">
            <input type="checkbox" className="form-check-input" name="regular_holiday" checked={days.includes(day)} onChange={() => onChange(day)} />
            <label key={index} className="form-check-label">{DAY_NAMES[day]}</label>
          </div>
        )
      ) }
    </div>
  )
};

RegularHolidayForm.propTypes = {
  days: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired
}

export default RegularHolidayForm;
