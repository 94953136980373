import React, { Component } from 'react';
import PropTypes from 'prop-types'
import axios from 'axios';

class OrderSelector extends Component {
  constructor(props) {
    super(props)

    this.state = {
      orders: []
    }

    this.editorStyle = this.props.editorStyle
  }

  componentDidMount(){
    this.searchOrders();
  }

  searchOrders = () => {
    const token = document.getElementsByName('csrf-token').item(0).content
    const url = Routes.commons_api_delivery_note_photos_shop_vendor_orders_path(this.props.shopVendor.id)
    let params = new FormData()
    params.append("_method", "get")
    params.append("authenticity_token", token)

    axios.get(url, params)
      .then((response) => {
        console.log(response.data.orders);
        this.setState({orders: response.data.orders.orders})
      })
      .catch((e) => {
        alert('注文の検索に失敗しました')
      })
  }

  addNewOrder = (order, e) => {
    const token = document.getElementsByName('csrf-token').item(0).content
    const url = Routes.commons_api_delivery_note_photos_shop_vendor_order_official_order_shipping_items_path(this.props.shopVendor.id, {order_id: order.id})
    let params = new FormData()
    params.append("_method", "get")
    params.append("authenticity_token", token)

    axios.get(url, params)
        .then((response) => {
          order["shippingItems"] = response.data.officialOrderShippingItems
          this.props.addNewBasket(order)
        })
        .catch((e) => {
          alert('注文の検索に失敗しました')
        })
  }

  render () {
    const { orders } = this.state;
    return (
      <>
        <table className={this.editorStyle.table()}>
          <thead>
            <tr>
              <th>注文日時</th>
              <th>受注者</th>
              <th>ステータス</th>
              <th></th>
            </tr>
          </thead>
          <tbody style={{overflow: "auto"}}>
            { orders.map((order, i) => {
              return <OrderRow
                       key={i}
                       order={order}
                       addNewOrder={this.addNewOrder}
                       editorStyle={this.editorStyle}
                     />
            })} 
          </tbody>
        </table> 
      </>
    )
  }
}

class OrderRow extends Component {  
  render() {
    const { order, addNewOrder, editorStyle } = this.props;
    return (
      <tr key={order.id}>
        <td>{order.created_at}</td>
        <td>{order.vendor.name}</td>
        <td>{order.status}</td>
        <td>
          <div className={editorStyle.buttons()}>
            <a
              className={editorStyle.joinClassNames(editorStyle.buttonLight(), editorStyle.isManager() ? editorStyle.marginRight() : '')}
              href={`/cs/orders/${order.id}`}
              target="blank"
            >詳細</a>
            <a
              className={editorStyle.buttonLight()}
              onClick={(e) => addNewOrder(order, e)}
            >追加</a>
          </div>        
        </td>
      </tr>
    )
  }
}

export default OrderSelector;

OrderSelector.propTypes = {
  shop: PropTypes.object.isRequired,
  shopVendor: PropTypes.object.isRequired,
  addNewBasket: PropTypes.func.isRequired
}

OrderRow.propTypes = {
  order: PropTypes.object.isRequired,
  addNewOrder: PropTypes.func.isRequired
}