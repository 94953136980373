import React, { Component } from 'react';
import PropTypes from "prop-types";

export default class OrderTabs extends Component {
  constructor(props) {
    super(props)

    this.editorStyle = this.props.editorStyle
  }

  getTabName = (basket) => {
      if (basket.order) {
          return basket.order.created_at
      } else if (basket.arrivalType === 'external') {
          return 'ラクミー外発注'
      } else {
          return basket.arrivalType
      }
  }

  render() {
    const { baskets,
            currentBasketIndex,
            changeCurrentBasket } = this.props;
    return (
      <>
        <div className={this.editorStyle.tabItemGroup()}>
          {baskets.map((basket, i) => (
            <div key={i} className={this.editorStyle.tabItem()}>
              <a
                className={i === currentBasketIndex ? this.editorStyle.tagSuccess() : this.editorStyle.tagLight()}
                onClick={(e) => changeCurrentBasket(i, e)}
              >
                { this.getTabName(basket) }
              </a>
            </div>
          ))}
        </div>    
      </>
    )
  }
}

OrderTabs.propTypes = {
  baskets: PropTypes.array.isRequired,
  currentBasketIndex: PropTypes.number.isRequired,
  changeCurrentBasket: PropTypes.func.isRequired
}