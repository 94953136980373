import React, { useState } from 'react';
import Select from 'react-select';

const SelectVendor = ({ name, options, defaultValue, isDisabled }) => {
  const [selectedOption, setSelectedOption] = useState(defaultValue || null);

  const filterOptions = (candidate, input) => {
    return candidate['data']['fax'] === input;
  };

  return (
    <div className="field">
      <Select
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            minHeight: 'calc(1.5em + 1.65rem + 2px)',
            padding: '0.425rem 1.5rem',
            fontSize: '1.15rem',
            borderRadius: '0.625rem',
            borderColor: '#e4e6ef',
          }),
          placeholder: (baseStyles, state) => ({
            ...baseStyles,
            color: '#b5b5c2',
            fontWeight: 500,
          }),
          valueContainer: (baseStyles, state) => ({
            ...baseStyles,
            padding: '0px',
          }),
        }}
        name={name}
        className="basic-single"
        classNamePrefix="select"
        isDisabled={isDisabled}
        isClearable={true}
        isSearchable={true}
        placeholder="発注先を選択してください"
        options={options}
        filterOption={filterOptions}
        defaultValue={selectedOption}
        components={{
          IndicatorSeparator: () => null,
        }}
        onChange={(value) => {
          // app/assets/javascripts/manager/shop_vendors.js
          showSpecificTemplate(value === null ? '' : value.specific_template);
        }}
        noOptionsMessage={() => "FAX番号をすべて入力してください　※ハイフンなしで数字のみで入力してください　例：0312345678"}
      />
    </div>
  );
};

export default SelectVendor;
