import React, { useState, useMemo } from 'react';
import Price from './cards/price';
import Variable from './cards/variable';
import Margin from './cards/margin';
import Qty from './cards/qty';
import PriceQty from './cards/price_qty';
import VariableQty from './cards/variable_qty';
import MarginQty from './cards/margin_qty';
import FixedCost from './cards/fixed_cost';
import Gain from './cards/gain';

const Analysis = ({ sales, customers, foodCostRate, laborCostRate, otherRatioCostRate, otherRatioCost, otherCost }) => {
  /**
   * 試算用の値
   */
  // 仮の客単価
  const [trialPrice, setTrialPrice] = useState(customers ? Math.round(sales / customers) : 0);
  // 仮の客数
  const [trialQty, setTrialQty] = useState(customers);
  // 仮の仕入費率
  const [trialFoodCostRate, setTrialFoodCostRate] = useState(foodCostRate);
  // 仮のPA人件費率
  const [trialLaborCostRate, setTrialLaborCostRate] = useState(laborCostRate);
  // 仮のその他変動費率
  const [trialOtherRatioCostRate, setTrialOtherRatioCostRate] = useState(otherRatioCostRate);
  // 仮の固定費
  const [trialFixedCost, setTrialFixedCost] = useState(otherCost);

  // 仮の変動費率
  const [trialVariableRate, setTrialVariableRate] = useState(Math.round((foodCostRate + laborCostRate + otherRatioCostRate) * 10) / 10);

  // 仮の売上
  const trialPriceQty = useMemo(() => {
    if (trialPrice != null && trialQty != null) {
      return trialPrice * trialQty;
    }
    return null;
  }, [trialPrice, trialQty]);
  // 仮の変動費
  const trialVariableQty = useMemo(() => {
    if (trialPriceQty != null && trialVariableRate != null) {
      return trialPriceQty * trialVariableRate / 100;
    }
    return null;
  }, [trialPriceQty, trialVariableRate]);
  // 仮の粗利
  const trialMarginQty = useMemo(() => {
    if (trialPriceQty != null && trialVariableQty != null) {
      return trialPriceQty - trialVariableQty;
    }
    return null;
  }, [trialPriceQty, trialVariableQty]);
  // 仮の利益
  const trialGain = useMemo(() => {
    if (trialMarginQty != null && trialFixedCost != null) {
      return Math.round(trialMarginQty - trialFixedCost);
    }
    return null;
  }, [trialMarginQty, trialFixedCost]);

  const trialCost = useMemo(() => {
    const value = trialPrice * (trialVariableRate / 100);
    return value ? Math.round(value) : 0;
  }, [trialPrice, trialVariableRate]);

  const trialMarginRate = useMemo(() => {
    // jsの小数計算の誤差対応(その他変動費が小数点１位まで)
    return (1000 - (trialVariableRate * 10)) / 10;
  }, [trialVariableRate]);

  /**
   * 実績
   */
  const price = useMemo(() => {
    if (! customers) {
      return 0;
    }
    const value = Math.round(sales / customers);
    return value ? value : 0;
  }, []);

  // 変動費率
  const variableRate = useMemo(() => {
    return foodCostRate + laborCostRate + otherRatioCostRate;
  }, []);

  const variableCost = useMemo(() => {
    const value = price * (variableRate / 100);
    return value ? Math.round(value) : 0;
  }, []);

  const marginRate = useMemo(() => {
    // jsの小数計算の誤差対応(その他変動費が小数点１位まで)
    return (1000 - (variableRate * 10)) / 10;
  }, []);

  const variableQty = useMemo(() => {
    return sales * (variableRate / 100);
  }, []);

  const marginQty = useMemo(() => {
    return sales - variableQty;
  }, []);

  const gain = useMemo(() => {
    return marginQty - otherCost;
  }, []);

  return (
    <div className="card-body pt-0">
      <div className="row g-0 gap-5 gap-lg-0 justify-content-evenly">
        <div className="col-md-12 col-lg-6 col-xl-6 col-xxl-6 row gy-2 gx-0 mt-0 justify-content-evenly me-0 pe-n2">
          <div className="col-4 col-md-4 px-0 gy-0 mt-0">
            <Price
              price={price}
              trialPrice={trialPrice}
              editable={true}
              changeTrialHandler={setTrialPrice}
            />
          </div>
          <div className="col-4 col-md-4 px-0 mt-0">
            <Variable
              foodCostRate={foodCostRate}
              laborCostRate={laborCostRate}
              otherRatioCostRate={otherRatioCostRate}
              cost={variableCost}
              trialCost={trialCost}
              trialVariableRate={trialVariableRate}
              trialFoodCostRate={trialFoodCostRate}
              trialLaborCostRate={trialLaborCostRate}
              trialOtherRatioCostRate={trialOtherRatioCostRate}
              editable={true}
              changeTrialVariableRateHandler={setTrialVariableRate}
              changeTrialFoodCostRateHandler={setTrialFoodCostRate}
              changeTrialLaborCostRateHandler={setTrialLaborCostRate}
              changeTrialOtherRatioCostRateHandler={setTrialOtherRatioCostRate}
            />
            <Margin
              marginRate={marginRate}
              trialMarginRate={trialMarginRate}
              price={price}
              trialPrice={trialPrice}
              editable={true}
              style={{ height: '283px' }}
            />
          </div>
          <div className="col-4 col-md-4 ps-0 ps-md-2 mt-0">
            <Qty qty={customers} trialQty={trialQty} editable={true} changeTrialHandler={setTrialQty} />
          </div>
        </div>
        <div className="col-md-12 col-lg-6 col-xl-6 col-xxl-6 row g-0 mt-0 justify-content-evenly pe-0 pe-md-1">
          <div className="col-4 col-md-4 justify-content-evenly px-0 gy-0 mt-0 pe-0 pe-md-1">
            <PriceQty priceQty={sales} trialPriceQty={trialPriceQty} editable={true} />
          </div>
          <div className="col-8 col-md-8 justify-content-evenly px-0">
            <div className="row gx-0 gx-md-2">
              <div className="col-12 col-md-12 gx-0 mt-0">
                <VariableQty
                  variableQty={variableQty}
                  trialVariableQty={trialVariableQty}
                  editable={true}
                />
              </div>
            </div>
            <div className="row gx-0 gx-md-2 justify-content-evenly">
              <div className="col-6 col-md-6 gx-3 mt-0 gy-0 px-0">
                <MarginQty marginQty={marginQty} trialMarginQty={trialMarginQty} editable={true} />
              </div>
              <div className="col-6 col-md-6 gx-3 flex-column px-0">
                <FixedCost
                  fixedCost={otherCost}
                  trialFixedCost={trialFixedCost}
                  editable={true}
                  changeTrialHandler={setTrialFixedCost}
                />
                <Gain gain={gain} trialGain={trialGain} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Analysis;
