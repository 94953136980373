import React, { useState } from 'react';

const ModalLabels = ({ labels, checkedLabelIds, changeLabelsHandler }) => {
  const [draftLabelIds, setDraftLabelIds] = useState(checkedLabelIds);

  const onChangeLabelHandler = (e) => {
    const labelId = parseInt(e.currentTarget.value);
    if (draftLabelIds.includes(labelId)) {
      const newLabelIds = draftLabelIds.filter((draftLabelId) => {
        return draftLabelId !== labelId;
      });
      setDraftLabelIds(newLabelIds);
    } else {
      setDraftLabelIds([...draftLabelIds, labelId]);
    }
  };

  const onClickApply = () => {
    changeLabelsHandler(draftLabelIds);
  };

  return (
    <div className="modal fade" id="modal_labels" tabIndex="-1" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered mobile-w-auto">
        <div className="modal-content">
          <div className="modal-header align-items-start p-8">
            <div className="flex-column">
              <div className="fs-3 fw-bolder">仕入商品ラベル選択</div>
            </div>
            <div className="btn btn-icon btn-sm ms-2 me-n4 mt-n2" data-bs-dismiss="modal">
              <i className="ki-outline ki-cross text-gray-500 fs-1"></i>
            </div>
          </div>
          <div className="modal-body p-8">
            <div className="d-flex gap-6 flex-wrap mb-2">
              {labels.map((label) => {
                const id = `check-label-${label.id}`;
                return (
                  <div className="form-check form-check-custom form-check-solid" key={`modal-label-${label.id}`}>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value={label.id}
                      id={id}
                      checked={draftLabelIds.includes(label.id)}
                      onChange={onChangeLabelHandler}
                    />
                    <label className="form-check-label" htmlFor={id}>
                      {label.name}
                    </label>
                  </div>
                )
              })}
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-light" data-bs-dismiss="modal">キャンセル</button>
            <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={onClickApply}>適用する</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalLabels;
