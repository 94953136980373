import React, { useMemo } from 'react';

const Step3 = ({ step, price, changeHandler, setStep }) => {
  const completedClass = useMemo(() => {
    if (step != 3 && price != null) {
      return 'completed';
    }

    return '';
  }, [step, price]);

  const changePriceHandler = (e) => {
    const newValue = e.currentTarget.value;
    changeHandler(newValue ? parseInt(newValue) : null);
  };

  const clickHandler = (e) => {
    e.preventDefault();
    setStep(3);
  };

  const renderStep = () =>  {
    return (
      <>
        <div className="stepper-wrapper align-items-start">
          <div className="stepper-icon min-w-40px min-h-40px">
            <i className="ki-duotone ki-check fs-2 stepper-check"></i>
            <span className="stepper-number">3</span>
          </div>
          <div className="flex-column">
            <div className="stepper-label">
              <h3 className="stepper-title my-3">目標客単価（税抜）を設定</h3>
            </div>
            {step == 3 && (
              <>
                <div className="card-title mt-8">
                  <h3 className="fw-bolder me-2 fs-7 my-0">目標客単価（税抜）</h3>
                </div>
                <div className="pt-2 pb-4">
                  <div className="input-group input-group-solid">
                    <span className="input-group-text">¥</span>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="入力"
                      value={price ?? ''}
                      onChange={changePriceHandler}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        <div className={`stepper-line ${step == 3 ? 'h-150px' : 'h-40px'}`}></div>
      </>
    );
  };

  return (
    <>
      {step == 3 ? (
        <div className={`stepper-item current pb-40 ${completedClass}`}>
          {renderStep()}
        </div>
      ) : (
        <a href="#" className={`stepper-item pb-40 ${completedClass}`} onClick={clickHandler}>
          {renderStep()}
        </a>
      )}
    </>
  );
};

export default Step3;
