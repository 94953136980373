import React from 'react';

const Qty = ({ qty, trialQty, editable, changeTrialHandler }) => {
  const changeHandler = (e) => {
    if (e.currentTarget.value == '') {
      changeTrialHandler(null);
    } else {
      changeTrialHandler(parseInt(e.currentTarget.value));
    }
  };

  return (
    <div class="card mobile-h-100 card-bordered rounded-0 me-0 me-lg-2">
      <div class="card-header px-4 pt-1 border-bottom-0">
        <div class="card-title fs-9 text-gray-400">
          <div class="symbol symbol-30px me-3">
            <div class="symbol-label fs-6 fw-bolder bg-primary text-white">Q</div>
          </div>
          <div class="flex-column">
            <div class="text-gray-500 fs-9">Qty</div>
            <div class="text-gray-600 fs-8">客数</div>
          </div>
        </div>
      </div>
      <div class="card-body px-4 pb-6 pt-0">
        <div class="fs-4 fw-bolder text-gray-600 pb-4">
          {qty.toLocaleString()}
        </div>
        <div class="pt-0">
          <div class="input-group input-group-solid py-2 mb-2">
            <input
              type="number"
              className="form-control"
              placeholder="入力"
              value={trialQty ?? ''}
              step="1"
              onChange={changeHandler}
            />
            <span class="input-group-text">人</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Qty;
