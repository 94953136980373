import React, { useState } from 'react';

const EditablePrice = ({ price, changeHandler }) => {
  const changePriceHandler = (e) => {
    const newValue = e.currentTarget.value;
    changeHandler(newValue ? parseInt(newValue) : null);
  };

  return (
    <div className="card h-625px card-bordered rounded-0">
      <div className="card-header px-4 pt-1 border-bottom-0">
        <div className="card-title fs-9 text-gray-500">
          <div className="symbol symbol-30px me-3">
            <div className={`symbol-label fs-6 fw-bolder bg-gray text-white`}>P</div>
          </div>
          <div className="flex-column">
            <div className="text-gray-500 fs-9">Price</div>
            <div className={`text-gray-600 fs-8`}>客単価</div>
          </div>
        </div>
      </div>
      <div className="card-body px-4 pb-4 pt-2">
        <div className="card-title mt-0">
          <h3 className="fw-bolder me-2 fs-8">目標客単価（税抜）</h3>
        </div>
        <div className="pt-0 pb-0">
          <div className="input-group input-group-solid">
            <span className="input-group-text">¥</span>
            <input
              type="number"
              className="form-control text-end pe-4 number-input"
              placeholder="入力"
              value={price}
              onChange={changePriceHandler}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditablePrice;
