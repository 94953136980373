import React from 'react';
import Chart from 'react-apexcharts';

const ItemRatio = ({ sales, categories }) => {
  const series = sales;
  const options = {
    chart: {
      type: 'bar',
      height:  '900',
      stacked: true,
      stackType: '100%',
      fontFamily: 'inherit',
      toolbar: {
        show: false
      }
    },
    legend: {
      show: false
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: "smooth",
      show: true,
      width: 2,
      colors: ['transparent']
    },
    responsive: [{
      breakpoint: 576,
      options: {
        chart: {
          height: 350,
        }
      }
    }],
    xaxis: {
      categories: categories,
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      },
      labels: {
        style: {
          fontFamily: 'inherit',
          colors: KTUtil.getCssVariableValue("--bs-gray-500"),
          fontSize: "12px"
        }
      }
    },
    yaxis: {
      labels: {
        style: {
          colors: KTUtil.getCssVariableValue("--bs-gray-600"),
          fontSize: "10px"
        }
      }
    },
    fill: {
      opacity: 1
    },
    tooltip: {
      y: {
        formatter: function(y) {
          if (typeof y !== "undefined") {
            return y + "%";
          }
          return y;
        }
      }
    },
    colors: ['#F687B3', '#B794F4', '#63B3ED', '#76E4F7', '#4FD1C5', '#68D391', '#F6E05E', '#F6AD55', '#FC8181', '#CCD5DF', '#B2B2B2'],
    grid: {
      borderColor: KTUtil.getCssVariableValue("--bs-gray-200"),
      strokeDashArray: 2,
      yaxis: {
        lines: {
          show: true
        }
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    }
  };

  return (
    <div id="chart_analysis_sales_item_ratio" className="pe-md-4 pb-2">
      <Chart options={options} series={series} type="bar" height="900" />
    </div>
  );
};

export default ItemRatio;
