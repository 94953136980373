import React from 'react';

const FixedCost = ({ fixedCost, trialFixedCost, editable, changeTrialHandler }) => {
  const changeHandler = (e) => {
    if (e.currentTarget.value == '') {
        changeTrialHandler(null);
    } else {
        changeTrialHandler(parseInt(e.currentTarget.value));
    }
  };

  return (
    <div class="card card-stretch card-bordered rounded-0 min-h-175px">
      <div class="card-header px-4 pt-1 border-bottom-0">
        <div class="card-title fs-9 text-gray-400">
          <div class="symbol symbol-30px me-3">
            <div class="symbol-label fs-6 fw-bolder bg-primary text-white">F</div>
          </div>
          <div class="flex-column">
        <div class="text-gray-500 fs-9">Fixed Cost</div>
        <div class="text-gray-600 fs-8 lh-sm">固定費</div>
      </div>
        </div>
      </div>
      <div class="card-body px-4 pb-4 pt-0">
        <div class="fs-4 fw-bolder text-gray-600 pb-3">
          ¥{Math.round(fixedCost).toLocaleString()}
        </div>
        <div class="pt-0">
          <div class="input-group input-group-solid py-2 mb-2">
            <span class="input-group-text">¥</span>
            <input
                type="number"
                className="form-control"
                placeholder="入力"
                value={trialFixedCost ?? ''}
                step="1"
                onChange={changeHandler}
              />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FixedCost;
