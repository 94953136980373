import React, { useEffect, useState } from 'react'
import axios from '../../../lib/axios';

const LabelDataList = ({ scope }) => {
  const [labelType, setLabelType] = useState(scope == 'Company' ? 'shop' : 'item');
  const [labels, setLabels] = useState([]);

  useEffect(() => {
    // 月選択用
    $('#label-type').select2({
      minimumResultsForSearch: Infinity
    });
    $('#label-type').on('select2:select', (e) => {
        setLabelType(e.currentTarget.value);
    });
  }, []);

  const getLabels = (labelType) => {
    axios.get('/labels/data_labels', {params: { label_type: labelType }})
      .then((response) => {
        setLabels(response.data.labels);
      });
  };

  useEffect(() => {
    getLabels(labelType);
  }, [labelType]);

  const renderLabels = () => {
    switch(labelType) {
      case "shop":
        return renderShopLabels();
      case "item":
        return renderItemLabels();
      case "sales_item":
        return renderSalesItemLabels();
      case "vendor":
        return renderVendorLabels();
      default:
        return null;
    }
  };

  const renderShopLabels = () => {
    return (
      <table className="table align-middle table-row-bordered gy-5 gs-9">
        <thead>
          <tr className="fw-bolder fs-8 text-gray-500">
            <th className="min-w-100px pt-6 pb-4">店舗ID</th>
            <th className="min-w-200px pt-6 pb-4">店舗名</th>
            <th className="min-w-100px pt-6 pb-4">ラベル名</th>
          </tr>
        </thead>
        <tbody>
          {labels.map((label, i) => {
            return (
              <tr key={`${labelType}-${i}`}>
                <td className="text-gray-600">{label.shop_id}</td>
                <td>{label.shop_name}</td>
                <td>
                  <div className="badge badge-light-gray fw-bold fs-7">{label.label_name}</div>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    );
  };

  const renderItemLabels = () => {
    return (
      <table className="table align-middle table-row-bordered gy-5 gs-9">
        <thead>
          <tr className="fw-bolder fs-8 text-gray-500">
            <th className="min-w-100px pt-6 pb-4">商品ID</th>
            <th className="min-w-200px pt-6 pb-4">商品名</th>
            <th className="min-w-200px pt-6 pb-4">費目</th>
            <th className="min-w-100px pt-6 pb-4">ラベル名</th>
          </tr>
        </thead>
        <tbody>
          {labels.map((label, i) => {
            return (
              <tr key={`${labelType}-${i}`}>
                <td className="text-gray-600">{label.shop_item_id}</td>
                <td>{label.shop_item_name}</td>
                <td>{label.expenditure_type}</td>
                <td>
                  <div className="badge badge-light-gray fw-bold fs-7">{label.label_name}</div>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    );
  };

  const renderSalesItemLabels = () => {
    return (
      <table className="table align-middle table-row-bordered gy-5 gs-9">
        <thead>
          <tr className="fw-bolder fs-8 text-gray-500">
            <th className="min-w-100px pt-6 pb-4">商品ID</th>
            <th className="min-w-200px pt-6 pb-4">商品名</th>
            <th className="min-w-100px pt-6 pb-4">ラベル名</th>
          </tr>
        </thead>
        <tbody>
          {labels.map((label, i) => {
            return (
              <tr key={`${labelType}-${i}`}>
                <td className="text-gray-600">{label.head_office_sales_item_id}</td>
                <td>{label.head_office_sales_item_name}</td>
                <td>
                  <div className="badge badge-light-gray fw-bold fs-7">{label.label_name}</div>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    );
  };

  const renderVendorLabels = () => {
    return (
      <table className="table align-middle table-row-bordered gy-5 gs-9">
        <thead>
          <tr className="fw-bolder fs-8 text-gray-500">
            <th className="min-w-100px pt-6 pb-4">発注先ID</th>
            <th className="min-w-200px pt-6 pb-4">発注先名</th>
            <th className="min-w-100px pt-6 pb-4">ラベル名</th>
          </tr>
        </thead>
        <tbody>
          {labels.map((label, i) => {
            return (
              <tr key={`${labelType}-${i}`}>
                <td className="text-gray-600">{label.shop_vendor_id}</td>
                <td>{label.vendor_name}</td>
                <td>
                  <div className="badge badge-light-gray fw-bold fs-7">{label.label_name}</div>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    );
  };

  return (
    <div className="card mb-5">
      <div className="card mb-0">
        <div className="card-header position-relative border-bottom-1 pt-6 px-9 pb-4">
          <div className="card-title d-flex align-items-center">
            <i className="ki-duotone ki-add-item fs-1 text-primary me-3 lh-0">
              <span className="path1"></span><span className="path2"></span><span className="path3"></span>
            </i>
            <h3 className="fw-bolder m-0 text-gray-800 fs-5 lh-1 my-2">ラベルデータ一覧</h3>
          </div>
          <div className="card-title me-0">
            <select id="label-type" defaultValue={labelType} className="form-select py-2 w-md-175px" data-control="select2" data-hide-search="true">
              {scope == 'Company' && (
                <option value="shop">店舗ラベル</option>
              )}
              <option value="item">仕入商品ラベル</option>
              <option value="sales_item">売上商品ラベル</option>
              <option value="vendor">発注先ラベル</option>
            </select>
          </div>
        </div>
        <div className="card-body p-0">
          <div className="table-responsive">
            {renderLabels()}
          </div>
        </div>
      </div>
    </div>
  );
}

export default LabelDataList;
