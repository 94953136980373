import React from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'

export default class CsvOrderItemImportForm extends React.Component {
    constructor(props) {
      super(props)
      this.state = {status: 'waiting', error: null}
      this.handleSubmit = this._handleSubmit.bind(this);
      this.fileInput = React.createRef();
    }

    _handleSubmit(event) {
      event.preventDefault();
      if(this.fileInput.current.files.length == 0){
        alert('CSVファイルを選択してください。');
        return null;
      }
      
      this.setState({status: 'sending'});
      const token = document.getElementsByName('csrf-token').item(0).content
      let params = new FormData()
      params.append("_method", 'post')
      params.append("authenticity_token", token)
      params.append("resource_name", this.props.resourceName)
      params.append("template", this.props.template)
      params.append("file", this.fileInput.current.files[0])

      let config = {
        headers: {
          Authorization: `Bearer vendor ${this.props.vendorId} ${this.props.authenticationToken}`,
        }
      }

      return axios.post('/api/v1/csv_import_requests', params, config)
        .then((response) => {   
          this.setState({status: 'done', error: null});       
        })
        .catch((e) => {
          this.setState({status: 'waiting', error: e.response.data.error_message});
          alert('インポートの受付に失敗しました。エラーを確認して再度お試しください。')
        })
    }

    _render_csv_form(){
      return (
        <div>
          <form onSubmit={this.handleSubmit}>
            <label>
            <input
              type="file" 
              accept='.csv'
              required={true}
              ref={this.fileInput} />
            </label>
            <button type='submit'>送信する</button>
          </form>
        </div>
      )
    }

    render() {
      return (
        <div>
          <div align="center">
            <div className="heading card-title">「{this.props.templateTitle}」CSVインポート</div>
          </div>
          <div>            
            1. まずはサンプルのCSVファイルをダウンロードしてください。
            <br />
            <a href="https://rakmy-csv-templates.s3.amazonaws.com/unit_template.csv">unit_template.csv</a>
            <br />
            <br />
            2. CSVファイルの2行目以降の内容をインポートしたいコンテンツに置き換え、アップロードしてください。
            <br />
            <br />
          </div>
          { this.state.error && <div style={{color: 'red'}}>{this.state.error}</div>}
          { this.state.status == 'waiting' && this._render_csv_form() }
          { this.state.status == 'sending' && '送信中...' }     
          { this.state.status == 'done' && 'CSVインポートの受付が完了しました。インポート状況は「CSVインポート履歴」よりご確認ください。' }     
        </div>
      )
    }
}

CsvOrderItemImportForm.protoTypes = {
  template: PropTypes.string.isRequired,
  templateTitle: PropTypes.string.isRequired,    
  resourceName: PropTypes.string.isRequired,
  vendorId: PropTypes.number.isRequired,
  authenticationToken: PropTypes.string.isRequired,
}