import React, { useEffect, useState, useMemo, useCallback } from 'react';
import Price from './cards/price';
import Variable from './cards/variable';
import Margin from './cards/margin';
import Qty from './cards/qty';
import PriceQty from './cards/price_qty';
import VariableQty from './cards/variable_qty';
import MarginQty from './cards/margin_qty';
import FixedCost from './cards/fixed_cost';
import Gain from './cards/gain';
import ModalCompositionRatio from './modal_composition_ratio';
import { Decimal } from 'decimal.js';
import moment from 'moment';

const ShowSetting = ({ monthlyBudget, monthlyGoal, businessDays, otherCost, otherCostRate, months }) => {
  const currentMonth = useMemo(() => moment(`${monthlyBudget.year_month}01`));

  useEffect(() => {
    // 月選択用
    $('#target-month').select2({
      minimumResultsForSearch: Infinity
    });
    $('#target-month').on('select2:select', (e) => {
      const month = moment(e.currentTarget.value);
      const y = month.year();
      const m = month.month() + 1;

      location.href = `/shops/monthly_budgets/${y}/${m}/setting`;
    });
  }, []);

  // Margin
  const marginRate = useCallback(() => {
    if (monthlyBudget.id != null) {

      const oneHundredDecimal = new Decimal(100);
      const foodCostRateDecimal = new Decimal(monthlyBudget.food_cost_rate);
      const laborCostRateDecimal = new Decimal(monthlyBudget.labor_cost_rate);
      const otherCostRateDecimal = new Decimal(otherCostRate ?? 0);

      return oneHundredDecimal.minus(foodCostRateDecimal.plus(laborCostRateDecimal).plus(otherCostRateDecimal)).toNumber();
    }

    return null;
  }, [monthlyBudget]);

  // Margin x Qty
  const marginQty = useCallback(() => {
    if (monthlyBudget.id != null) {
      return (otherCost ?? 0) + monthlyBudget.profit;
    }

    return null;
  }, [otherCost, monthlyBudget]);

  // Price x Qty
  const priceQty = useCallback(() => {
    if (marginRate() != null && marginQty() != null) {
      if (marginRate() == 0) {
        return 0;
      }
      return Math.round(marginQty() / (marginRate() / 100));
    }

    return null;
  }, [marginRate, marginQty]);

  //  Variable x Qty
  const variableQty = useCallback(() => {
    if (marginRate() != null && priceQty() != null) {
      return priceQty() - marginQty();
    }

    return null;
  }, [priceQty, marginQty]);

  // Qty
  const qty = useCallback(() => {
    if (priceQty() != null && monthlyBudget.id != null) {
      return Math.round(priceQty() / monthlyBudget.sales_per_customer);
    }

    return null;
  }, [priceQty, monthlyBudget]);

  const onClickEdit = useCallback(() => {
    location.href = `/shops/monthly_budgets/${currentMonth.year()}/${currentMonth.month()+1}/setting/edit`;
  }, []);

  return (
    <div className="card card-flush">
      <div className="card-header border-0 d-flex">
        <div className="card-title mt-2 mt-lg-4">
          <h3 className="fw-bolder me-2 fs-5">月次予算目標</h3>
        </div>
        <div className="col-12 justify-content-between flex-row-fluid d-flex mt-0 mb-6 mobile-flex-column gap-6">


          <div className="d-flex align-items-center gap-2 gap-lg-3">
            <div className="text-muted fs-8 me-1 nowrap">対象月</div>
            <select id="target-month" className="form-select form-select-sm form-select-solid mobile-w-100" data-control="select2" data-hide-search="true" defaultValue={currentMonth.format('YYYY-MM-DD')}>
              {months.map((month) => {
                return (
                  <option value={month[1]} key={`month-option-${month[1]}`}>{month[0]}</option>
                )
              })}
            </select>
          </div>
          <div className="d-flex gap-3">
            {monthlyBudget.id != null && (
                <button type="button" className="btn btn-sm btn-primary"  data-bs-toggle="modal" data-bs-target="#modal_revenue">
                  <i className="ki-outline ki-chart-pie-3 fs-3 ms-0 me-1 mb-1"></i>
                  売上構成比設定
                </button>
            )}
            <button type="button" className="btn btn-sm btn-gray" onClick={onClickEdit}>
              <i className="ki-outline ki-finance-calculator fs-3 ms-0 me-1 mb-1"></i>
              {monthlyBudget.id == null ? '設定する' : '修正する'}
            </button>
          </div>
        </div>
      </div>

      <div className="card-body pt-4 border-top">
        <div className="row justify-content-evenly text-center fw-bold fs-7 text-gray-600 mobile-hide tablet-hide mt-2">
          <div className="col-3">客単価</div>
          <div className="col-3">×　　　客数　　　=</div>
          <div className="col-6">売上（税抜）</div>
        </div>
        <div className="row g-4 justify-content-evenly mt-4 gap-4 gap-lg-0 px-2">
          <div className="col-md-12 col-lg-6 col-xl-6 col-xxl-6 row gy-2 gx-0 mt-0 justify-content-evenly me-0">
            <div className="col-4 px-0 gy-0 mt-0">
              <Price active={true} price={monthlyBudget.id == null ? null : monthlyBudget.sales_per_customer} />
            </div>
            <div className="col-4 px-0 mt-0">
              <Variable
                active={true}
                otherCostRate={otherCostRate ?? 0}
                foodCostRate={monthlyBudget.id == null ? null : monthlyBudget.food_cost_rate}
                laborCostRate={monthlyBudget.id == null ? null : monthlyBudget.labor_cost_rate}
                price={monthlyBudget.id == null ? null : monthlyBudget.sales_per_customer}
              />
              <Margin
                active={true}
                marginRate={marginRate()}
                price={monthlyBudget.id == null ? null : monthlyBudget.sales_per_customer}
                style={{ height: '204px' }}
              />
            </div>
            <div className="col-4 ps-2 mt-0 gy-0">
              <Qty active={true} qty={qty()} />
            </div>
          </div>
          <div className="col-md-12 col-lg-6 col-xl-6 col-xxl-6 row g-0 mt-0 justify-content-evenly pe-1">
            <div className="col-4 justify-content-evenly px-0 gy-0 mt-0 pe-1">
              <PriceQty
                active={true}
                priceQty={priceQty()}
                showCompositionRatio={true}
                monthlyBudget={monthlyBudget}
              />
            </div>
            <div className="col-8 justify-content-evenly px-0">
              <div className="row gx-2">
                <div className="col-12 gx-0 mt-0">
                  <VariableQty active={true} variableQty={variableQty()} />
                </div>
              </div>
              <div className="row gx-2 justify-content-evenly">
                <div className="col-6 gx-3 mt-0 gy-0 px-0">
                  <MarginQty active={true} marginQty={marginQty()} />
                </div>
                <div className="col-6 gx-3 flex-column px-0">
                  <FixedCost active={true} fixedCost={otherCost ?? 0} />
                  <Gain active={true} gain={monthlyBudget.id == null ? null : monthlyBudget.profit} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ModalCompositionRatio monthlyBudget={monthlyBudget} />
    </div>
  );
};

export default ShowSetting;
