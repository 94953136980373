import React, { useState } from 'react';
import Chart from 'react-apexcharts';

// Material Colors
const COLORS = [
  '#ED64A6',
  '#9F7AEA',
  '#4299E1',
  '#0BC5EA',
  '#38B2AC',
  '#48BB78',
  '#ECC94B',
  '#ED8936',
  '#F56565',
  '#A1AEBF',
  '#999999'
];

const CostChart = ({series, labels}) => {
  const options = {
    chart: {
      height: 400,
      fontFamily: 'inherit',
      type: 'donut',
      toolbar: {
        show: false
      }
    },
    dataLabels: {
      enabled: true,
      style: {
        fontFamily: 'inherit',
        fontWeight: 'bold',
        colors: [KTUtil.getCssVariableValue("--bs-white")]
      }
    },
    tooltip: {
      enabled: true,
      shared: true,
      intersect: false,
      fillSeriesColor: [KTUtil.getCssVariableValue("--bs-white")],
      style: {
        fontSize: '12px',
        fontFamily: 'inherit'
      },
      onDatasetHover: {
        highlightDataSeries: false,
      },
      x: {
        show: true
      },
      y: {
        formatter: function(val) {
          return val + '%'
        },
      },
      marker: {
        show: true,
      }
    },
    legend: {
      show: true,
      position: 'bottom',
      fontFamily: "Gilroy"
    },
    labels: labels,
    colors: COLORS,
    responsive: [{
      breakpoint: 480,
      options: {
        chart: {
          width: 250
        },
        legend: {
          position: 'bottom'
        }
      }
    }]
  };

  return (
    <div>
      <Chart type="donut" options={options} series={series}/>
    </div>
  );
}

export default CostChart;
