import React from 'react';

const Gain = ({ gain, trialGain }) => {
  return (
    <div class="card card-stretch card-bordered rounded-0 min-h-175px">
      <div class="card-header px-4 pt-1 border-bottom-0">
        <div class="card-title fs-9 text-gray-400">
          <div class="symbol symbol-30px me-3">
            <div class="symbol-label fs-6 fw-bolder bg-gray text-white">G</div>
          </div>
          <div class="flex-column">
        <div class="text-gray-500 fw-bold fs-9 opacity-75">Gain</div>
        <div class="text-gray-600 fw-bolder fs-8 lh-sm">利益</div>
      </div>
        </div>
      </div>
      <div class="card-body px-4 pb-5 pt-0">
        <div class="fs-4 fw-bolder text-gray-700 mb-2 opacity-75">
          ¥{Math.round(gain).toLocaleString()}
        </div>
        <div class="fs-1 fw-bolder my-6 pb-0">
          ¥{trialGain != null ? trialGain.toLocaleString() : '--'}
        </div>
      </div>
    </div>
  );
};

export default Gain;
