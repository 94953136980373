import React, { useState } from 'react'
import PropTypes from 'prop-types'
import axios from '../../../lib/axios'

const OperationRequestBox = ({ shopId, operationType, initialRequestState }) => {
  const [requestState, setRequestState] = useState(initialRequestState.find((r) => r.operation_type === operationType) || {} );
  const [updating, setUpdating] = useState(false);

  const getTimeLabel = (timeStr) => {
    const date = new Date(timeStr);
    return `${date.getFullYear()}年${date.getMonth()+1}月${date.getDay()}日 ${date.getHours()}:${date.getMinutes()}`;
  } 

  const getOperationTypeLabel = () => {
    switch(operationType) {
      case "inventory":
        return "棚卸";
      case "warehouse":
        return "入庫"
      default: 
        return ""
    }
  }

  const applyAPIResponse = (requests) => {
    const request = requests.find((r) => r.operation_type === operationType);

    if( request ) {
      setRequestState(request);
    }
    else {
      setRequestState({});
    }
  }

  const handleClickRequestButton = () => {
    if( ! confirm("指示を送ります。よろしいですか？") ) {
      return;
    }

    setUpdating(true);
    axios.put(`/api/operation_requests/${shopId}`, {
      operation_type: operationType
    } ).then((response)=>{
      setUpdating(false);
      applyAPIResponse(response.data.operation_requests);
    })
    .catch(() => {
      setUpdating(false);
      alert('指示の送信に失敗しました');
    })
  }

  const handleClickRemoveRequestButton = () => {
    if( ! confirm("指示を取り消します。よろしいですか？") ) {
      return;
    }

    setUpdating(true);
    axios.delete(`/api/operation_requests/${shopId}`, {
      data: { operation_type: operationType }
    } ).then((response) => {
      setUpdating(false);
      applyAPIResponse(response.data.operation_requests);
    })
    .catch(() => {
      setUpdating(false);
      alert('指示の解除に失敗しました');
    })
  }

  return (
    <div>
      {requestState && requestState.updated_at ? (
        <button className="btn btn-sm btn-danger" onClick={handleClickRemoveRequestButton} disabled={updating}>{getOperationTypeLabel()}指示を取り消す</button>
      ): (
        <button className="btn btn-sm btn-primary" onClick={handleClickRequestButton} disabled={updating}>{getOperationTypeLabel()}指示を出す</button>
      )}
    </div>
  )

};

OperationRequestBox.propTypes = {
  initialRequestState: PropTypes.array.isRequired,
  shopId: PropTypes.number.isRequired,
  operationType: PropTypes.string.isRequired
};

export default OperationRequestBox;
