import React from 'react';

const IndexCompany = ({ month, shops, keys, stockingTransfersIn, stockingTransfersOut, totalIn, totalOut }) => {
  const onChangeMonth = (e) => {
    window.location.href = `/stocking_transfers?month=${e.currentTarget.value}`;
  };

  const renderMonths = () => {
    const currentDate = moment();
    const months = [];

    for (let i = 0; i < 12; i++) {
      const month = currentDate.clone().subtract(i, 'months');
      const monthString = month.format('YYYY年MM月');
      months.push({ value: month.format('YYYY-MM-01'), label: monthString });
    }

    return (
      <select
        className="form-select mw-auto"
        data-control="select2"
        data-hide-search="true"
        onChange={onChangeMonth}
        value={month ?? ''}
      >
        {months.map((month) => <option key={`month-${month.value}`} value={month.value}>{month.label}</option>)}
      </select>
    );
  };

  const renderShop = (baseTransfer, counterTransfer) => {
    let name = '';
    if (baseTransfer) {
      name = shops[baseTransfer.shop_id] ?? '';
    };

    if (name.length === 0) {
      if (counterTransfer) {
        name = shops[counterTransfer.counter_party_shop_id] ?? '';
      };
    }

    return name;
  };

  const renderList = () => {
    const rows = keys.map((key) => {
      const [date, shop_id, counter_party_shop_id] = key.split('_');
      const outTransfer = stockingTransfersOut[key];
      const inTransfer = stockingTransfersIn[key];

      return (
        <tr>
          <td className="text-gray-600 nowrap pe-4">{moment(date).format('YYYY年M月D日')}</td>
          <td>
            {renderShop(outTransfer, inTransfer)}
          </td>
          <td>
            {outTransfer ? `¥${outTransfer.price_excluded_tax.toLocaleString()}` : ''}
          </td>
          <td>
            {renderShop(inTransfer, outTransfer)}
          </td>
          <td>
            {inTransfer ? `¥${inTransfer.price_excluded_tax.toLocaleString()}` : ''}
          </td>
          <td className="text-end">
            {outTransfer?.price_excluded_tax !== inTransfer?.price_excluded_tax && (
              <i className="ki-solid ki-information-3 text-danger fs-2"></i>
            )}
          </td>
        </tr>
      );
    });

    return (
      <table className="table align-middle table-row-bordered gy-5 gs-7">
        <thead>
          <tr className="fw-bold fs-8 text-gray-500">
            <th className="pt-6 pb-3">日付</th>
            <th className="min-w-150px pt-6 pb-3 nowrap">移動元店舗</th>
            <th className="min-w-100px pt-6 pb-3">金額</th>                            
            <th className="min-w-150px pt-6 pb-3 nowrap">移動先店舗</th>
            <th className="min-w-100px pt-6 pb-3">金額</th>
            <th className="min-w-150px pt-6 pb-3 text-end">要確認</th>
          </tr>
        </thead>
        <tbody className="fw-bold">
          {rows}
        </tbody>
        <tfoot>
          <tr className="fw-bold fs-6 border-top">
            <th colSpan="2" className="fw-bolder text-nowrap text-gray-600 pt-4">合計:</th>
            <th colSpan="1" className="fw-bolder text-gray-700 fs-5 pe-2">¥{totalOut.toLocaleString()}</th>
            <th colSpan="1"></th>
            <th colSpan="1" className="fw-bolder text-gray-700 fs-5 pe-2">¥{totalIn.toLocaleString()}</th>
            <th colSpan="2"></th>
          </tr>
        </tfoot>
      </table>
    );
  };

  return (
    <>
      <div className="content d-flex flex-column flex-column-fluid">
        <div className="mt-8 mb-6" id="kt_toolbar">
          <div id="kt_toolbar_container" className="container-xxl flex-stack mobile-flex-column">
            <div className="page-title d-flex align-items-center flex-wrap mb-6">
              <h1 className="d-flex text-dark fw-bolder fs-4 align-items-center my-1">店舗間商品移動</h1>
            </div>
            <div className="d-flex align-items-center gap-2 gap-lg-3 flex-wrap mobile-w-100">
              <div className="d-flex align-items-center gap-2 gap-lg-3 mobile-w-100">
                <div className="text-muted fs-8 me-1 nowrap">期間</div>
                {renderMonths()}
              </div>
            </div>
          </div>
        </div>

        <div className="app-content flex-column-fluid">
          <div className="app-container container-xxl">
            <div className="card mb-5 mb-xl-10">
              <div className="card-body p-0">
                <div className="table-responsive">
                  {renderList()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default IndexCompany;
