import React from 'react';

const EditableGain = ({ gain, changeHandler }) => {
  const changeGainHandler = (e) => {
    const newValue = e.currentTarget.value;
    changeHandler(newValue ? parseInt(newValue) : null);
  };

  return (
    <div className="card h-150px card-bordered rounded-0">
      <div className="card-header px-4 pt-1 border-bottom-0">
        <div className="card-title fs-9 text-gray-400">
          <div className="symbol symbol-30px me-3">
            <div className={`symbol-label fs-6 fw-bolder bg-gray text-white`}>G</div>
          </div>
          <div className="flex-column">
            <div className="text-gray-500 fs-9">Gain</div>
            <div className={`text-gray-600 fs-8 lh-sm`}>利益</div>
          </div>
        </div>
      </div>
      <div className="card-body px-4 pb-4 pt-0">
        <div className="card-title mt-2">
          <h3 className="fw-bolder me-2 fs-8">目標月次利益</h3>
        </div>
        <div className="pt-0 pb-0">
          <div className="input-group input-group-solid">
            <span className="input-group-text">¥</span>
            <input
              type="number"
              className="form-control text-end pe-4 number-input"
              placeholder="入力"
              value={gain}
              onChange={changeGainHandler}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditableGain;
