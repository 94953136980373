import React, { useEffect, useState } from 'react';
import axios from "../../../lib/axios";

const ModalForm = ({ shops, employee }) => {
  const [draftEmployee, setDraftEmployee] = useState({...employee});

  useEffect(() => {
    setDraftEmployee(employee);
  }, [employee]);

  useEffect(() => {
    if (draftEmployee && draftEmployee.classification === 'hourly') {
      if (draftEmployee.total_salary !== 0 ||
          draftEmployee.prescribed_working_hours !== 0 ||
          draftEmployee.deemed_overtime) {
        setDraftEmployee({
          ...draftEmployee,
          total_salary: 0,
          prescribed_working_hours: 0,
          deemed_overtime_hours: 0
        });
      }
    }
  }, [draftEmployee]);

  const onChangeValue = (e) => {
    const { name, value } = e.currentTarget;

    const newEmployee = {
      ...draftEmployee,
      [name]: value
    };
    setDraftEmployee(newEmployee);
  };

  const onClickSubmit = () => {
    if (draftEmployee.id) {
      // 更新
      axios.patch(`/employees/${draftEmployee.id}`, {
        employee: draftEmployee
      }).then((response) => {
        window.location.reload();                                                                                                    }).catch((error) => {
        if (error.response.status === 422) {
          alert(error.response.data.errors.join("\n"));
        } else {
          alert('保存に失敗しました');
        }
      });
    } else {
      // 新規登録
      axios.post('/employees', {
        employee: draftEmployee
      }).then((response) => {
        window.location.reload();
      }).catch((error) => {
        if (error.response.status === 422) {
          alert(error.response.data.errors.join("\n"));
        } else {
          alert('登録に失敗しました');
        }
      });
    }
  };

  return (
    <div className="modal fade" id="modal_add_staff" tabIndex="-1" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered mobile-w-auto">
        <div className="modal-content">
          <div className="modal-header align-items-start p-8">
            <div className="flex-column">
              <div className="fs-3 fw-bolder">
                {draftEmployee?.id ? '従業員データ編集' : '従業員情報登録'}
              </div>
            </div>
            <div className="btn btn-icon btn-sm ms-2 me-n4 mt-n2" data-bs-dismiss="modal">
              <i className="ki-outline ki-cross text-gray-500 fs-1"></i>
            </div>
          </div>
          <div className="modal-body p-8">
            <div className="row d-flex mb-6">
              <div className="d-flex flex-column col-6">
                <label className="required d-flex align-items-center fs-7 fw-bold mb-2 text-gray-600">
                  従業員コード
                </label>
                <input
                  type="text"
                  name="employee_code"
                  className="form-control form-control-solid fs-6"
                  placeholder="従業員コードを入力"
                  value={draftEmployee?.employee_code ?? ''}
                  onChange={onChangeValue}
                />
              </div>
              <div className="d-flex flex-column col-6">
                <label className="required d-flex align-items-center fs-7 fw-bold mb-2 text-gray-600">
                  所属コード
                </label>
                <input
                  type="text"
                  name="division_code"
                  className="form-control form-control-solid fs-6"
                  placeholder="所属コードを入力"
                  value={draftEmployee?.division_code ?? ''}
                  onChange={onChangeValue}
                />
              </div>
            </div>
            <div className="row d-flex mb-6">
              <div className="d-flex flex-column col-6">
                <label className="required d-flex align-items-center fs-7 fw-bold mb-2 text-gray-600">
                  店舗名
                </label>
                <div className="input-group input-group-lg me-4">
                  <select
                    className="form-select"
                    data-control="select2"
                    data-placeholder="選択してください"
                    data-hide-search="true"
                    name="shop_id"
                    value={draftEmployee?.shop_id ?? ''}
                    onChange={onChangeValue}
                  >
                    <option value="">選択してください</option>
                    {Object.keys(shops).map((shopId) => (
                      <option key={`shop-${shopId}`} value={shopId}>{shops[shopId]}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="d-flex flex-column col-6">
                <label className="required d-flex align-items-center fs-7 fw-bold mb-2 text-gray-600">
                  区分
                </label>
                <div className="input-group input-group-lg me-4">
                  <select
                    className="form-select"
                    data-control="select2"
                    data-placeholder="選択してください"
                    data-hide-search="true"
                    name="classification"
                    value={draftEmployee?.classification ?? ''}
                    onChange={onChangeValue}
                  >
                    <option value="">選択してください</option>
                    <option value="monthly">月給</option>
                    <option value="hourly">時給</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="row d-flex mb-2">
              <div className="d-flex flex-column col-4">
                <label className="required d-flex align-items-center fs-7 fw-bold mb-2 text-gray-600">
                  1日あたり交通費
                </label>
                <input
                  type="number"
                  name="daily_transportation_expense"
                  className="form-control form-control-solid fs-6 number-input"
                  placeholder="金額を入力"
                  value={draftEmployee?.daily_transportation_expense ?? ''}
                  onChange={onChangeValue}
                />
              </div>
              {draftEmployee?.classification === 'monthly' && (
                <>
                  <div className="d-flex flex-column col-4">
                    <label className="required d-flex align-items-center fs-7 fw-bold mb-2 text-gray-600">
                      総支給額
                    </label>
                    <input
                      type="number"
                      name="total_salary"
                      className="form-control form-control-solid fs-6"
                      placeholder="金額を入力"
                      value={draftEmployee?.total_salary ?? ''}
                      onChange={onChangeValue}
                    />
                  </div>
                  <div className="d-flex flex-column col-4">
                    <label className="required d-flex align-items-center fs-7 fw-bold mb-2 text-gray-600">
                      所定労働時間
                    </label>
                    <input
                      type="number"
                      name="prescribed_working_hours"
                      className="form-control form-control-solid fs-6"
                      placeholder="時間数を入力"
                      value={draftEmployee?.prescribed_working_hours ?? ''}
                      onChange={onChangeValue}
                    />
                  </div>
                  <div className="d-flex flex-column col-4">
                    <label className="required d-flex align-items-center fs-7 fw-bold mb-2 text-gray-600">
                      みなし残業時間
                    </label>
                    <input
                      type="number"
                      name="deemed_overtime_hours"
                      className="form-control form-control-solid fs-6"
                      placeholder="時間数を入力"
                      value={draftEmployee?.deemed_overtime_hours ?? ''}
                      onChange={onChangeValue}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-light" data-bs-dismiss="modal">キャンセル</button>
            <button type="button" className="btn btn-primary" onClick={onClickSubmit}>
              {draftEmployee?.id ? '保存する' : '登録する'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalForm;
