import React from 'react';
import Chart from 'react-apexcharts';

const DonutChart = ({id, ratioArray, totalLabel}) => {
  const series = ratioArray.map((obj) => obj.ratio);
  const options = {
    chart: {
      height: 360,
      width: '100%',
      fontFamily: 'Open Sans, Noto Sans Japanese, Sans-Serif',
      type: 'donut',
      toolbar: {
        show: false
      }
    },
    dataLabels: {
      enabled: true,
      style: {
        fontFamily: 'Open Sans, Noto Sans Japanese, Sans-Serif',
        fontWeight: 700,
        colors: [KTUtil.getCssVariableValue("--bs-white")]
      },
      dropShadow: {
        enabled: false
      }
    },
    tooltip: {
      enabled: true,
      shared: true,
      intersect: false,
      fillSeriesColor: [KTUtil.getCssVariableValue("--bs-white")],
      style: {
        fontSize: '12px',
        fontFamily: 'inherit'
      },
      onDatasetHover: {
        highlightDataSeries: false,
      },
      x: {
        show: true
      },
      y: {
        formatter: function(val) {
          return '¥' + val.toLocaleString(); // Adds ¥ symbol to the tooltip value
        }
      },
      marker: {
        show: true,
      }
    },
    legend: {
      show: true,
      position: 'bottom',
      fontFamily: 'Open Sans, Noto Sans Japanese, Sans-Serif'
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            name: {
              show: true,
              fontSize: '1rem',
              fontFamily: 'Open Sans, Noto Sans Japanese, Sans-Serif',
              fontWeight: 600,
              formatter: function (val) {
                return val
              }
            },
            value: {
              show: true,
              fontSize: '2rem',
              fontFamily: 'Open Sans, Noto Sans Japanese, Sans-Serif',
              fontWeight: 600,
              offsetY: 5,
              formatter: function (val) {
                return '¥' + Number(val).toLocaleString(); // Adds ¥ symbol and formats number with commas
              }
            },
            total: {
              show: true,
              showAlways: false,
              label: totalLabel,
              fontSize: '1rem',
              fontFamily: 'Open Sans, Noto Sans Japanese, Sans-Serif',
              fontWeight: 600,
              color:  KTUtil.getCssVariableValue("--bs-gray-600"),
              formatter: function (w) {
                var total = w.globals.seriesTotals.reduce((a, b) => {
                  return a + b;
                }, 0);
                return '¥' + total.toLocaleString(); // Adds ¥ symbol and formats number with commas
              }
            }
          }
        }
      }
    },
    labels: ratioArray.map((obj) => obj.label),
    colors: [KTUtil.getCssVariableValue("--bs-teal"), KTUtil.getCssVariableValue("--bs-blue")],
    responsive: [{
      breakpoint: 576,
      options: {
        legend: {
          position: 'bottom'
        }
      }
    }]
  };

  return (
    <div className="mx-4 mt-9 mb-10 min-h-300px">
      <Chart id={id} options={options} series={series} type="donut" height="360" />
    </div>
  );
};

export default DonutChart;
