import React, { useCallback, useMemo, useState } from "react";
import axios from "../../../lib/axios";

const NonfoodSwitch = ({shopItemId, initialValue}) => {
  const [checked, setChecked] = useState(initialValue);
  const [loading, setLoading] = useState(false);
  const apiPath = useMemo(() => `/api/shop_items/${shopItemId}`, [shopItemId]);

  const handleOnChange = useCallback((e) => {
    if( loading ) { return; }

    setLoading(true);

    const value = e.target.checked;

    axios({
      method: "put",
      url: apiPath,
      data: { shop_item: {
        nonfood: value ? 1 : 0 
      } }
    }).then((res) => {
      res.status === 200 && setChecked(value);
    })
    .catch((e) => {
      console.log(e);
      window.alert("エラーが発生しました。");
    })
    .then(() => setLoading(false));
  }, [loading, checked, shopItemId]);
  
  return (
    <input type="checkbox" className="form-check-input" checked={checked} disabled={loading} onChange={handleOnChange} />
  )
}

export default NonfoodSwitch;

