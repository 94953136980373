import React from 'react'
import PropTypes from 'prop-types'

export default class ItemImporter extends React.Component {
  constructor(props) {
    super(props)

    // 初期化
    const items = props.items.map((item, i) => {
      return {
        name: item.name ? item.name : '',
        code: item.code ? item.code : '',
        unit_id: item.unit_id ? item.unit_id : '',
        price: item.price ? item.price : 0,
        tax_type: item.tax_type ? item.tax_type : 'external',
        quantity: item.quantity ? item.quantity : 1
      }
    })

    this.state = {
      items: items
    }

    this._handleAddItem = this._handleAddItem.bind(this)
    this._handleRemoveItem = this._handleRemoveItem.bind(this)
    this._handleChangeName = this._handleChangeName.bind(this)
    this._handleChangeCode = this._handleChangeCode.bind(this)
    this._handleChangeUnit = this._handleChangeUnit.bind(this)
    this._handleChangePrice = this._handleChangePrice.bind(this)
    this._handleChangeTaxType = this._handleChangeTaxType.bind(this)
    this._handleChangeQuantity = this._handleChangeQuantity.bind(this)
    this._handleKeyPress = this._handleKeyPress.bind(this)
  }

  render() {
    const items = this._renderFaxItems()

    return (
      <React.Fragment>
        <div className="form-group">
          <table className="table no-footer">
            <thead>
            <tr>
              <th>商品コード</th>
              <th>商品名</th>
              <th>単位</th>
              <th>価格</th>
              <th>税区分</th>
              <th>入数</th>
              <th>操作</th>
            </tr>
            </thead>
            <tbody>
            {items}
            </tbody>
          </table>

          <button className="btn btn-primary">送信</button>
          <div className="form-group float-right">
            <button className="btn btn-info" onClick={this._handleAddItem}>商品追加</button>
          </div>
        </div>
      </React.Fragment>
    )
  }

  // FaxItemのフォーム
  _renderFaxItems() {
    const units = this.props.units.map((unit, i) => {
      return (
        <option value={unit.id} key={i}>{unit.name}</option>
      )
    })
    const taxTypes = this.props.taxTypes.map((taxType, i) => {
      return (
        <option value={taxType.id} key={i}>{taxType.name}</option>
      )
    })

    return this.state.items.map((item, i) => {
      const buttonClass = this.state.items.length > 1 ? "btn btn-danger" : "btn btn-secondary"
      return (
        <tr key={i} data-index={i} onKeyPress={this._handleKeyPress}>
          <td>
            <input type="text" name="unofficial_item[items][][code]" value={item.code} className="form-control" onChange={this._handleChangeCode} />
          </td>
          <td>
            <input type="text" name="unofficial_item[items][][name]" value={item.name} className="form-control" onChange={this._handleChangeName} required="required" />
          </td>
          <td>
            <select
              name="unofficial_item[items][][unit_id]"
              value={item.unit_id}
              className="form-control select optional"
              required="required"
              onChange={this._handleChangeUnit}
            >
              <option value="">選択してください</option>
              {units}
            </select>
          </td>
          <td>
            <input type="number" name="unofficial_item[items][][price]" value={item.price} className="form-control" onChange={this._handleChangePrice} required="required" />
          </td>
          <td>
            <select
              name="unofficial_item[items][][tax_type]"
              value={item.tax_type}
              className="form-control select optional"
              required="required"
              onChange={this._handleChangeTaxType}
            >
              <option value="">選択してください</option>
              {taxTypes}
            </select>
          </td>
          <td>
            <input type="number" name="unofficial_item[items][][quantity]" value={item.quantity} className="form-control" onChange={this._handleChangeQuantity} />
          </td>
          <td>
            <button className={buttonClass} data-index={i} onClick={this._handleRemoveItem}>削除</button>
          </td>
        </tr>
      )
    })
  }

  // 商品フォーム追加
  _handleAddItem(e) {
    e.preventDefault()

    const item = {
      name: '',
      code: '',
      unit_id: '',
      price: ''
    }
    let items = this.state.items
    items.push(item)

    this.setState({items: items})
  }

  // 商品フォーム削除
  _handleRemoveItem(e) {
    e.preventDefault()

    if (this.state.items.length < 2) {
      return
    }

    const index = e.currentTarget.closest("tr").dataset.index
    const items = this.state.items.filter((item, i) => i != index)

    this.setState({items: items})
  }

  // 商品名を変更
  _handleChangeName(e) {
    const index = e.currentTarget.closest("tr").dataset.index
    const value = e.currentTarget.value

    let items = this.state.items
    items[index].name = value

    this.setState({items: items})
  }

  // 商品コードを変更
  _handleChangeCode(e) {
    const index = e.currentTarget.closest("tr").dataset.index
    const value = e.currentTarget.value

    let items = this.state.items
    items[index].code = value

    this.setState({items: items})
  }

  // 単位を変更
  _handleChangeUnit(e) {
    const index = e.currentTarget.closest("tr").dataset.index
    const value = e.currentTarget.value

    let items = this.state.items
    items[index].unit_id = value

    this.setState({items: items})
  }

  // 価格を変更
  _handleChangePrice(e) {
    const index = e.currentTarget.closest("tr").dataset.index
    const value = e.currentTarget.value

    let items = this.state.items
    items[index].price = value

    this.setState({items: items})
  }

  // 税区分を変更
  _handleChangeTaxType(e) {
    const index = e.currentTarget.closest("tr").dataset.index
    const value = e.currentTarget.value

    let items = this.state.items
    items[index].tax_type = value

    this.setState({items: items})
  }

  // 入数を変更
  _handleChangeQuantity(e) {
    const index = e.currentTarget.closest("tr").dataset.index
    const value = e.currentTarget.value

    let items = this.state.items
    items[index].quantity = value

    this.setState({items: items})
  }

  // key を押した
  _handleKeyPress(e) {
    // enter で最寄りのbuttonがclickされないように
    if (e.key == "Enter") {
      e.preventDefault()
    }
  }
}

ItemImporter.propTypes = {
  units: PropTypes.array.isRequired,
  items: PropTypes.array.isRequired,
  taxTypes: PropTypes.array.isRequired
}
