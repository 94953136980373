import React from 'react'
import PropTypes from 'prop-types'

export default class FaxItemImporter extends React.Component {
  constructor(props) {
    super(props)

    // 初期化
    const fax_items = props.fax_items.map((fax_item, i) => {
      return {
        name: fax_item.name ? fax_item.name : '',
        code: fax_item.code ? fax_item.code : '',
        unit_id: fax_item.unit_id ? fax_item.unit_id : ''
      }
    })

    this.state = {
      fax_items: fax_items
    }

    this._handleAddItem = this._handleAddItem.bind(this)
    this._handleRemoveItem = this._handleRemoveItem.bind(this)
    this._handleChangeName = this._handleChangeName.bind(this)
    this._handleChangeCode = this._handleChangeCode.bind(this)
    this._handleChangeUnit = this._handleChangeUnit.bind(this)
    this._handleKeyPress = this._handleKeyPress.bind(this)
  }

  render() {
    const fax_items = this._renderFaxItems()

    return (
      <React.Fragment>
        <div className="form-group">
          <table className="table no-footer">
            <thead>
              <tr>
                <th>商品コード</th>
                <th>商品名</th>
                <th>単位</th>
                <th>操作</th>
              </tr>
            </thead>
            <tbody>
              {fax_items}
            </tbody>
          </table>

          <button className="btn btn-primary">送信</button>
          <div className="form-group float-right">
            <button className="btn btn-info" onClick={this._handleAddItem}>商品追加</button>
          </div>
        </div>
      </React.Fragment>
    )
  }

  // FaxItemのフォーム
  _renderFaxItems() {
    const units = this.props.units.map((unit, i) => {
      return (
        <option value={unit.id} key={i}>{unit.name}</option>
      )
    })

    return this.state.fax_items.map((fax_item, i) => {
      const buttonClass = this.state.fax_items.length > 1 ? "btn btn-danger" : "btn btn-secondary"
      return (
        <tr key={i} data-index={i} onKeyPress={this._handleKeyPress}>
          <td>
            <input type="text" name="fax_item[items][][code]" value={fax_item.code} className="form-control" onChange={this._handleChangeCode} />
          </td>
          <td>
            <input type="text" name="fax_item[items][][name]" value={fax_item.name} className="form-control" onChange={this._handleChangeName} required="required" />
          </td>
          <td>
            <select
              name="fax_item[items][][unit_id]"
              value={fax_item.unit_id}
              className="form-control select optional"
              required="required"
              onChange={this._handleChangeUnit}
            >
              <option value="">選択してください</option>
              {units}
            </select>
          </td>
          <td>
            <button className={buttonClass} data-index={i} onClick={this._handleRemoveItem}>削除</button>
          </td>
        </tr>
      )
    })
  }

  // 商品フォーム追加
  _handleAddItem(e) {
    e.preventDefault()

    const fax_item = {
      name: '',
      code: '',
      unit_id: ''
    }
    let fax_items = this.state.fax_items
    fax_items.push(fax_item)

    this.setState({fax_items: fax_items})
  }

  // 商品フォーム削除
  _handleRemoveItem(e) {
    e.preventDefault()

    if (this.state.fax_items.length < 2) {
      return
    }

    const index = e.currentTarget.closest("tr").dataset.index
    const fax_items = this.state.fax_items.filter((fax_item, i) => i != index)

    this.setState({fax_items: fax_items})
  }

  // 商品名を変更
  _handleChangeName(e) {
    const index = e.currentTarget.closest("tr").dataset.index
    const value = e.currentTarget.value

    let fax_items = this.state.fax_items
    fax_items[index].name = value

    this.setState({fax_items: fax_items})
  }

  // 商品コードを変更
  _handleChangeCode(e) {
    const index = e.currentTarget.closest("tr").dataset.index
    const value = e.currentTarget.value

    let fax_items = this.state.fax_items
    fax_items[index].code = value

    this.setState({fax_items: fax_items})
  }

  // 単位を変更
  _handleChangeUnit(e) {
    const index = e.currentTarget.closest("tr").dataset.index
    const value = e.currentTarget.value

    let fax_items = this.state.fax_items
    fax_items[index].unit_id = value

    this.setState({fax_items: fax_items})
  }

  // key を押した
  _handleKeyPress(e) {
    // enter で最寄りのbuttonがclickされないように
    if (e.key == "Enter") {
      e.preventDefault()
    }
  }
}

FaxItemImporter.propTypes = {
  units: PropTypes.array.isRequired,
  fax_items: PropTypes.array.isRequired
}
