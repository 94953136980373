import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import Calendar from 'react-calendar';
import axios from '../../../lib/axios';
import RegularHolidayForm from './regular_holiday_form';
import moment from 'moment';

const updatedBusinessClosedDays = {};
const HolidayPage = ({ holidayData }) => {
  const holidays = holidayData.holidays;
  const [regularHolidays, updateRegularHoliday] = useState(holidayData.regular_holidays);
  const [businessClosedDays, updateBusinessClosedDays] = useState(holidayData.business_closed_days);
  const [sending, updateSending] = useState(false); 
  const holidayClosed = useMemo(() => regularHolidays.includes(7), [regularHolidays]);

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    return `${year}-${month < 10 ? "0" + month : month}-${day < 10 ? "0" + day: day}`;
  }

  const checkHoliday = (date) => {
    const dateStr = formatDate(date);
    const businessClosedDay = businessClosedDays.find((d) => d.date == dateStr);
    if( businessClosedDay ) {
      return businessClosedDay.day_type == "closed";
    }
    
    return regularHolidays.includes(date.getDay()) || (holidayClosed && holidays[dateStr] );
  }

  const tileClassName = ({ activeStartDate, date, view }) => {
    const result = [`day${date.getDay()}`, (checkHoliday(date) ? "closed" : "business")];
    if( holidays[formatDate(date)] ) {
      result.push("holiday");
    }

    return result;
  }

  // 定休日を変更した際に、日付指定の休業日も更新する
  const updateBusinessClosedDaysWithRegularHoliday = (weekday, isClosed) => {
    const newBusinessClosedDays = businessClosedDays.map((day) => {
      const newDay = {...day};
      if (weekday == 7) {
        if (holidays[newDay.date]) {
           newDay.day_type = isClosed ? "closed" : "business";
        }
      } else {
        if (moment(newDay.date).day() == weekday) {
           newDay.day_type = isClosed ? "closed" : "business";
        }
      }
      // グローバルな updateBusinessClosedDays にも反映する
      updatedBusinessClosedDays[newDay.date] = newDay.day_type;
      return newDay;
    });
    updateBusinessClosedDays(newBusinessClosedDays);
  };

  const handleOnChangeRegularHoliday = (day) => {
    const newHolidays = [...regularHolidays];
    const index = regularHolidays.indexOf(day);
    let isClosed = false;
    if ( index > -1) {
      newHolidays.splice(index, 1);
    }
    else {
      newHolidays.push(day);
      isClosed = true; // 定休日に指定された
    }

    updateRegularHoliday(newHolidays);
    // ここで、businessClosedDaysにも反映する
    updateBusinessClosedDaysWithRegularHoliday(day, isClosed);
  }

  const handleClickDay = (date) => {
    const dateStr = formatDate(date);
    const newDays = [...businessClosedDays];
    const index = businessClosedDays.findIndex(d => d.date == dateStr);
    const newType = (checkHoliday(date) ? "business" : "closed");
    const newData = { date: dateStr, day_type: newType };
    if( index > -1 ) {
      newDays.splice(index, 1, newData)
    }
    else {
      newDays.push(newData);
    }

    updatedBusinessClosedDays[dateStr] = newType;
    updateBusinessClosedDays(newDays);
  }

  const handleClickSave = () => {
    if(sending){ return } 
    updateSending(true);
    axios.post('/api/shops/holidays', {
      regular_holidays: regularHolidays,
      business_closed_days: Object.keys(updatedBusinessClosedDays).map((key) => { return {date: key, day_type: updatedBusinessClosedDays[key]} })
    } )
    .then((response) => {
      alert("休業日の設定が完了しました。予算に反映するには、再度、予算設定を行って下さい。");
      updateSending(false);
    })
    .catch((error) => {
      if (error.response.data.error_message) {
        alert(error.response.data.error_message[0]);
      } else {
        alert('保存に失敗しました');
      }
      updateSending(false);
    })
  }

  return (
    <div>
      <div className="card-header border-0">
        <div className="card-title m-0">
          <h3 className="fw-bolder me-2">定休日</h3>
        </div>
      </div>
      <RegularHolidayForm days={regularHolidays} onChange={(day) => handleOnChangeRegularHoliday(day)}></RegularHolidayForm>
      <div className="card-header border-0">
        <div className="card-title m-0">
          <h3 className="fw-bolder me-2">日付指定</h3>
        </div>
      </div>
      <div className="card-body border-top p-9">
        <Calendar
          minDetail="month"
          minDate={new Date(holidayData.from)}
          maxDate={new Date(holidayData.to)}
          tileClassName={tileClassName}
          onClickDay={handleClickDay}
          prev2Label={null}
          next2Label={null}
        />
        <div className="d-flex py-4 ms-1">
          <span className="badge badge-square badge-light me-3"></span>
          <span className="fs-7 pt-1 me-4">営業日</span>
          <span className="badge badge-square badge-light-gray me-3"></span>
          <span className="fs-7 pt-1 me-4">休業日</span>
        </div>
        <p className="fs-7 text-gray-600">※日付指定は定休日より優先されます。</p>
      </div>
      <div className="card-footer d-flex justify-content-end py-6 px-9">
        <button id="kt_holiday_submit" className="btn btn-primary" onClick={handleClickSave} disabled={sending}>保存</button>
      </div>
    </div>    
  )
};


HolidayPage.propTypes = {
  holidayData: PropTypes.object.isRequired
}

export default HolidayPage;
