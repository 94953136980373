import React from 'react';
import Chart from 'react-apexcharts';

const LineAreaChart = ({categories, trackName, goalName, tracks, goals, unitPrefix = ""}) => {
  const series = [
    {
      name: trackName,
      type: 'area',
      data: tracks
    },
    {
      name: goalName,
      type: 'line',
      data: goals
    }
  ];
  const options = {
    chart: {
      type: 'area',
      height: '200',                                                                                                                 fontFamily: 'Open Sans, Noto Sans Japanese, Sans-Serif',                                                                       toolbar: {
        show: false
      }
    },
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: [4],
        columnWidth: '35%',
        endingShape: 'rounded'
      }
    },
    stroke: {
      curve: 'straight',
    },
    fill: {
      type:'solid',
      opacity: [0.3, 0.6],
    },
    markers: {
      size: 3,
      hover: {
        size: 5
      }
    },
    legend: {
      show: false
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: categories,
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      },
      labels: {
        show: true,
        rotate: 0,
        hideOverlappingLabels: true,
        style: {
          fontFamily: 'Open Sans, Noto Sans Japanese, Sans-Serif',
          colors: KTUtil.getCssVariableValue("--bs-gray-500"),
          fontSize: "11px"
        }
      }
    },
    yaxis: {
      labels: {
      show: true,
      rotate: 0,
      hideOverlappingLabels: true,
      style: {
        fontFamily: 'Open Sans, Noto Sans Japanese, Sans-Serif',
        colors: KTUtil.getCssVariableValue("--bs-gray-500"),
        fontSize: "11px"
        }
      }
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: [{
        formatter: (y) => {
          return unitPrefix + y.toLocaleString();
        }
      }, {
        formatter: (y) => {
          return unitPrefix + y.toLocaleString();
        }
      }]
    },
    colors: [KTUtil.getCssVariableValue("--bs-primary"), KTUtil.getCssVariableValue("--bs-gray-500")],
    grid: {
      borderColor: KTUtil.getCssVariableValue("--bs-gray-200"),
      strokeDashArray: 2,
      yaxis: {
        lines: {
          show: true
        }
      },
      padding: {
        top: 0,
        right: 24,
        bottom: 0,
        left: 24
      }
    }
  };

  return (
    <div className="mx-n9 pt-0 pb-0">
      <Chart options={options} series={series} type="area" height="200" />
    </div>
  );
};

export default LineAreaChart;
