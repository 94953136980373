import React from 'react'
import PropTypes from 'prop-types'
import Form from './form'
import axios from 'axios'
import arrayMove from 'array-move';

export default class UnofficialItemsEditor extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      sortable: this.props.sortable,
      unofficialItems: props.unofficialItems // 商品達
    }

    this._handleSortEnd = this._handleSortEnd.bind(this)
  }

  render() {
    return (
      <div className="columns">
        <div className="column is-fullwidth">
          <Form sortable={this.state.sortable} items={this.state.unofficialItems} onSortEnd={this._handleSortEnd} />
        </div>
      </div>
    )
  }

  // 並び替え完了
  _handleSortEnd(oldIndex, newIndex) {
    const items = arrayMove(this.state.unofficialItems, oldIndex, newIndex)
    this.setState({unofficialItems: items})

    // request for saving orders
    // ajax 商品追加
    const token = document.getElementsByName('csrf-token').item(0).content

    const url = Routes.cs_shop_vendor_unofficial_items_sort_path(this.props.shopVendorId)
    let params = new FormData()
    params.append("from", oldIndex)
    params.append("to", newIndex)
    params.append("_method", "put")
    params.append("authenticity_token", token)
    return axios.post(url, params)
      .then((response) => {
        // 何もしない
      })
      .catch((e) => {
        alert('並び替えに失敗しました')
      })
  }
}

UnofficialItemsEditor.propTypes = {
  shopVendorId: PropTypes.number.isRequired,
  unofficialItems: PropTypes.array.isRequired
}
