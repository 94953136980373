import React, { useEffect, useState }  from "react";
import CostPerSalesRate from "./fd/cost_per_sales_rate";
import SalesRate from "./fd/sales_rate";
import CostRate from "./fd/cost_rate";
import axios from "../../../lib/axios";
import Loading from '../spinners/loading';

const Fd = ({from, to}) => {
  const [food, setFood] = useState(null);
  const [drink, setDrink] = useState(null);
  const [foodCost, setFoodCost] = useState(0);
  const [foodSales, setFoodSales] = useState(0);
  const [drinkCost, setDrinkCost] = useState(0);
  const [drinkSales, setDrinkSales] = useState(0);
  const [foodCurrentMonthStock, setFoodCurrentMonthStock] = useState(0);
  const [foodPreviousMonthStock, setFoodPreviousMonthStock] = useState(0);
  const [drinkCurrentMonthStock, setDrinkCurrentMonthStock] = useState(0);
  const [drinkPreviousMonthStock, setDrinkPreviousMonthStock] = useState(0);
  const [totalCost, setTotalCost] = useState(0);
  const [totalSales, setTotalSales] = useState(0);
  const [totalCurrentMonthStock, setTotalCurrentMonthStock] = useState(0);
  const [totalPreviousMonthStock, setTotalPreviousMonthStock] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    axios.get('/detail_reports/fd_data', {params: { from, to }}).then((response) => {
      setFood(response.data.food);
      setDrink(response.data.drink);
      setFoodCurrentMonthStock(response.data.food_current_month_stock);
      setFoodPreviousMonthStock(response.data.food_previous_month_stock);
      setDrinkCurrentMonthStock(response.data.drink_current_month_stock);
      setDrinkPreviousMonthStock(response.data.drink_previous_month_stock);
      setTotalSales(response.data.total_sales);
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    if (food === null || food.cost === null) {
      setFoodCost(0);
      return;
    }

    let cost = 0;
    Object.keys(food.cost).forEach((key) => {
      cost = cost + food.cost[key].cost;
    });
    Object.keys(food.petty_cache).forEach((key) => {
      cost = cost + food.petty_cache[key].cost;
    });
    Object.keys(food.stocking_transfer).forEach((key) => {
      cost = cost + food.stocking_transfer[key].cost;
    });

    setFoodCost(cost);

    if (food === null || food.sales === null) {
      setFoodSales(0);
      return;
    }

    let sales = 0;
    Object.keys(food.sales).forEach((key) => {
      sales = sales + food.sales[key].sales;
    });

    setFoodSales(sales);
  }, [food]);

  useEffect(() => {
    if (drink === null || drink.cost === null) {
      setDrinkCost(0);
      return;
    }

    let cost = 0;
    Object.keys(drink.cost).forEach((key) => {
      cost = cost + drink.cost[key].cost;
    });
    Object.keys(drink.petty_cache).forEach((key) => {
      cost = cost + drink.petty_cache[key].cost;
    });
    Object.keys(drink.stocking_transfer).forEach((key) => {
      cost = cost + drink.stocking_transfer[key].cost;
    });

    setDrinkCost(cost);

    if (drink === null || drink.sales === null) {
      setDrinkSales(0);
      return;
    }

    let sales = 0;
    Object.keys(drink.sales).forEach((key) => {
      sales = sales + drink.sales[key].sales;
    });

    setDrinkSales(sales);
  }, [drink]);

  return (
    <div className="card-body pt-2 row g-5">
      {loading ? (
          <Loading />
        ) : (
          <>
            <CostPerSalesRate foodCost={foodCost}
                        drinkCost={drinkCost}
                        foodSales={foodSales}
                        drinkSales={drinkSales}
                        foodCurrentMonthStock={foodCurrentMonthStock}
                        foodPreviousMonthStock={foodPreviousMonthStock}
                        drinkCurrentMonthStock={drinkCurrentMonthStock}
                        drinkPreviousMonthStock={drinkPreviousMonthStock}
                        totalCost={foodCost + drinkCost}
                        totalSales={totalSales}
                        totalCurrentMonthStock={foodCurrentMonthStock + drinkCurrentMonthStock}
                        totalPreviousMonthStock={foodPreviousMonthStock + drinkPreviousMonthStock} />                        
      <SalesRate foodSales={foodSales} drinkSales={drinkSales} />
      <CostRate foodCost={foodCost} drinkCost={drinkCost} />
          </>
        )}      
    </div>
  );
};

export default Fd;
