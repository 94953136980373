import React, { useState } from 'react';

const LabelRow = ({ label, index, changeLabelHandler, changeDefaultHandler, clickAppendHandler, clickRemoveHandler }) => {
  return (
    <tr>
      <td className="text-gray-800 fw-bolder">
        <input
          type="text"
          className="form-control form-control-lg form-control-solid"
          name={`label[${index}][name]`}
          placeholder="ラベル名を入力"
          value={label.name == null ? '' : label.name}
          required
          onChange={changeLabelHandler}
        />
      </td>
      <td>
        <div className="d-flex align-items-center">
          <div className="form-check form-check-custom form-check-solid form-check-lg me-6">
            <input
              type="radio"
              id={`label_${index}_default_true`}
              className="form-check-input"
              name={`label[${index}][default]`}
              value="true"
              checked={label.default == true}
              onChange={changeDefaultHandler}
            />
            <label className="form-check-label" htmlFor={`label_${index}_default_true`}>デフォルト</label>
          </div>
          <div className="form-check form-check-custom form-check-solid form-check-lg">
            <input
              type="radio"
              id={`label_${index}_default_false`}
              className="form-check-input"
              name={`label[${index}][default]`}
              value="false"
              checked={label.default == false}
              onChange={changeDefaultHandler}
            />
            <label className="form-check-label" htmlFor={`label_${index}_default_false`}>通常</label>
          </div>
        </div>
      </td>
      <td>
        <div className="d-flex align-items-center gap-2 gap-lg-3">
          <button className="btn btn-sm btn-light-primary" onClick={clickAppendHandler}>
            <i className="fas fa-plus" />
          </button>
          <button className="btn btn-sm btn-light-primary" onClick={clickRemoveHandler}>
            <i className="fas fa-minus" />
          </button>
        </div>
      </td>
    </tr>
  );
};

const BulkCreate = ({ defaultLabels }) => {
  const [labels, setLabels] = useState(defaultLabels);

  const changeLabelHandler = (e, index) => {
    const name = e.currentTarget.value;
    labels[index].name = name;
    const newLabels = [...labels];
    setLabels(newLabels);
  };

  const changeDefaultHandler = (e, index) => {
    const newLabel = {
      ...labels[index],
      default: labels[index].default == true ? false : true
    };

    // 一旦全部default falseにする
    const newLabels = labels.map((label, index) => {
      label.default = false;
      return label;
    });

    newLabels[index] = newLabel;
    setLabels([...newLabels]);
  };

  const clickAppendHandler = (e, index) => {
    e.preventDefault();
    const newLabels = [...labels, {name: '', default: false}];
    setLabels(newLabels);
  };

  const clickRemoveHandler = (e, index) => {
    e.preventDefault();
    if (labels.length < 2) {
        return;
    }

    labels.splice(index, 1);
    const newLabels = [...labels];
    setLabels(newLabels);
  };

  const renderLabels = () => {
    return labels.map((label, index) => {
      return (
        <LabelRow
          key={`label-${index}`}
          label={label}
          index={index}
          changeLabelHandler={(e) => { changeLabelHandler(e, index) }}
          changeDefaultHandler={(e) => { changeDefaultHandler(e, index) }}
          clickAppendHandler={(e) => { clickAppendHandler(e, index) }}
          clickRemoveHandler={(e) => { clickRemoveHandler(e, index) }}
        />
      )
    });
  };

  return (
    <div className="table-responsive">
      <table className="table align-middle table-row-bordered gy-3 gs-7">
        <thead>
          <tr className="fw-bolder fs-7 text-muted">
            <th className="min-w-150px pt-5">ラベル名</th>
            <th className="min-w-100px pt-5">デフォルトラベル</th>
            <th className="min-w-100px text-end pt-5"></th>
          </tr>
        </thead>
        <tbody>
          {renderLabels()}
        </tbody>
      </table>
    </div>
  );
};

export default BulkCreate;
