import React, { useEffect, useState, useCallback, useMemo } from 'react';
import Step0 from './steps/step0';
import Step1 from './steps/step1';
import Step2 from './steps/step2';
import Step3 from './steps/step3';
import Step4 from './steps/step4';
import MessageAboutCost from './message_about_cost';
import Price from './cards/price';
import Variable from './cards/variable';
import Margin from './cards/margin';
import Qty from './cards/qty';
import PriceQty from './cards/price_qty';
import VariableQty from './cards/variable_qty';
import MarginQty from './cards/margin_qty';
import FixedCost from './cards/fixed_cost';
import Gain from './cards/gain';
import axios from '../../../lib/axios';
import moment from "moment";
import { Decimal } from 'decimal.js';

const NewSetting = ({ defaultMonthlyBudget, businessDays, otherCost, otherCostRate }) => {
  const [step, setStep] = useState(0); // ステップ
  const [monthlyBudget, setMonthlyBudget] = useState(defaultMonthlyBudget);
  const [gain, setGain] = useState(null); // 月次利益目標
  const [foodCostRate, setFoodCostRate] = useState(null); // 仕入比率
  const [laborCostRate, setLaborCostRate] = useState(null); // PA人件費率
  const [price, setPrice] = useState(null); // 客単価
  const [dailyBudgets, setDailyBudgets] = useState({}); // 日次予算

  const currentMonth = useMemo(() => moment(`${defaultMonthlyBudget.year_month}01`));
  const days = useMemo(() => currentMonth.daysInMonth()); // 月の日数
  const businessDaysNum = useMemo(() => businessDays.length); // 営業日数

  useEffect(() => {
    if (step == 4) {
      calculateDailyBudgets();
    }
  }, [step, calculateDailyBudgets]);

  // Margin
  const marginRate = useCallback(() => {
    if (foodCostRate != null && laborCostRate != null) {
      const oneHundredDecimal = new Decimal(100);
      const foodCostRateDecimal = new Decimal(foodCostRate);
      const laborCostRateDecimal = new Decimal(laborCostRate);
      const otherCostRateDecimal = new Decimal(otherCostRate ?? 0);

      return oneHundredDecimal.minus(foodCostRateDecimal.plus(laborCostRateDecimal).plus(otherCostRateDecimal)).toNumber();
    }

    return null;
  }, [foodCostRate, laborCostRate, otherCostRate]);

  // Margin x Qty
  const marginQty = useCallback(() => {
    if (gain != null) {
      return (otherCost ?? 0) + gain;
    }

    return null;
  }, [otherCost, gain]);

  // Price x Qty
  const priceQty = useCallback(() => {
    if (marginRate() != null && marginQty() != null) {
      if (marginRate() == 0) {
        return 0;
      }
      return parseInt(marginQty() / (marginRate() / 100));
    }

    return null;
  }, [marginRate, marginQty]);

  //  Variable x Qty
  const variableQty = useCallback(() => {
    if (marginRate() != null && priceQty() != null) {
      return priceQty() - marginQty();
    }

    return null;
  }, [priceQty, marginQty]);

  // Qty
  const qty = useCallback(() => {
    if (priceQty() != null && price != null) {
      return Math.round(priceQty() / price);
    }

    return null;
  }, [priceQty, price]);

  // 日次を算出
  const calculateDailyBudgets = () => {
    if (qty() == null || priceQty() == null || foodCostRate == null || laborCostRate == null) {
      // 入力が揃っていない時は日時は作成しない
      setDailyBudgets({});
      return;
    }

    // 日次を計算
    const dailyCustomers = Math.round(qty() / businessDaysNum); // 目標日次客数
    const dailySales = Math.round(priceQty() / businessDaysNum); // 目標日次売上
    // 食材原価目標
    const monthlyFoodCost = priceQty() * (foodCostRate / 100);
    const dailyFoodCost = Math.round(monthlyFoodCost / businessDaysNum);
    // PA人件費目標
    const monthlyLaborCost = priceQty() * (laborCostRate / 100);
    const dailyLaborCost = Math.round(monthlyLaborCost / businessDaysNum);

    // 日次予算に設定
    let newDailyBudgets = {};
    const year = currentMonth.year();
    const month = currentMonth.month();
    for (let day = 1; day <= days; day++) {
      const date = moment({ year: year, month: month, date: day });
      const key = date.format('YYYY-MM-DD');
      if (businessDays.indexOf(key) === -1) {
        continue;
      }

      newDailyBudgets[key] = {
        customers: dailyCustomers,
        sales: dailySales,
        food_cost: dailyFoodCost,
        labor_cost: dailyLaborCost,
      }
    }
    setDailyBudgets(newDailyBudgets);
  };

  const clickPrevHandler = () => {
    if (step < 1) {
      // nothing todo
      return;
    }

    setStep(step - 1);
  };

  const clickNextHandler = () => {
    if (step > 4) {
      // nothing todo
      return;
    }

    setStep(step + 1);
  };

  const clickSaveHandler = () => {
    const year = currentMonth.year();
    const month = currentMonth.month() + 1;
    const url = `/shops/monthly_budgets/${year}/${month}/setting`;
    let daily = [];
    Object.keys(dailyBudgets).forEach((key) => {
        daily.push({
          date: key,
          ...dailyBudgets[key],
        });
    });

    axios.post(url, {
      monthly_budget: {
        profit: gain,
        food_cost_rate: foodCostRate,
        labor_cost_rate: laborCostRate,
        sales_per_customer: price
      },
      daily_budget: daily
    })
    .then((_response) => {
      window.location.href = url;
    })
    .catch((_error) => {
      alert('保存に失敗しました。設定内容をご確認ください');
    });
  };

  const renderSteps = () => {
    return (
      <div className="card d-flex justify-content-center justify-between flex-row-auto col-md-12 col-lg-12 col-xl-3 col-xxl-3">
        <div className="card-header border-bottom-1">
          <div className="card-title m-0">
            <h3 className="fw-bolder me-2 fs-4 mt-10 mb-5">月次予算設定ステップ</h3>
          </div>
        </div>
        <div className="card-body px-9 px-lg-9 px-xxl-9 py-10">
          <div className="stepper-nav">
            <Step0 step={step} otherCost={otherCost} otherCostRate={otherCostRate} setStep={setStep} />
            <Step1 step={step} gain={gain} changeHandler={setGain} setStep={setStep} />
            <Step2
              step={step} 
              foodCostRate={foodCostRate}
              laborCostRate={laborCostRate}
              changeFoodCostRateHandler={setFoodCostRate}
              changeLaborCostRateHandler={setLaborCostRate}
              setStep={setStep}
            />
            <Step3 step={step} price={price} changeHandler={setPrice} setStep={setStep} />
            <Step4 step={step} setStep={setStep} />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid gap-4 pe-4" id="wizard_stepper">
      {renderSteps()}
      <div className="card col-md-12 col-lg-12 col-xl-9 col-xxl-9">
        <form className="card-body py-10 px-9">
          <div className="current flex-column" data-kt-stepper-element="content">
            {step == 0 && (
              <MessageAboutCost otherCost={otherCost} otherCostRate={otherCostRate} />
            )}
            <div className="row justify-content-evenly text-center fw-bold fs-7 text-gray-600 mobile-hide tablet-hide">
              <div className="col-3">客単価</div>
              <div className="col-3">×　　　客数　　　=</div>
              <div className="col-6">売上（税抜）</div>
            </div>

            <div className="row g-4 justify-content-evenly mt-1 mx-n1">
              <div className="col-md-12 col-lg-12 col-xl-12 col-xxl-6 row g-2 mt-0 justify-content-evenly mb-4 ms-0">
                <div className="col-4 px-0 gy-0 mt-2">
                  <Price active={step > 3} price={price} />
                </div>
                <div className="col-4 p-0">
                  <Variable
                    active={step > 2 ? true : false}
                    otherCostRate={otherCostRate ?? 0}
                    foodCostRate={foodCostRate}
                    laborCostRate={laborCostRate}
                    price={price}
                  />
                  <Margin
                    active={step > 2}
                    marginRate={marginRate()}
                    price={price}
                    style={{ height: '204px' }}
                  />
                </div>
                <div className="col-4 ps-2">
                  <Qty active={step > 3} qty={qty()} />
                </div>
              </div>
              <div className="col-md-12 col-lg-12 col-xl-12 col-xxl-6 row g-2 mt-0 justify-content-evenly mb-4 me-1">
                <div className="col-4 justify-content-evenly px-0 gy-0 mt-2">
                  <PriceQty active={step > 2} priceQty={priceQty()} />
                </div>
                <div className="col-8 justify-content-evenly px-0">
                  <div className="row gx-2">
                    <div className="col-12 gx-0 mt-0">
                      <VariableQty active={step > 2} variableQty={variableQty()} />
                    </div>
                  </div>
                  <div className="row gx-2 justify-content-evenly">
                    <div className="col-6 gx-3 mt-0 gy-0 px-0">
                      <MarginQty active={step > 1} marginQty={marginQty()} />
                    </div>
                    <div className="col-6 gx-3 flex-column px-0">
                      <FixedCost active={step > 0} fixedCost={otherCost ?? 0} />
                      <Gain active={step > 1} gain={gain} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex flex-stack pt-3">
            <div className="mr-2">
              {step > 0 && (
                <button
                  type="button"
                  className="btn btn-md btn-light-primary"
                  onClick={clickPrevHandler}
                >
                  <i className="ki-solid ki-arrow-left fs-4 ms-n1 me-1 mb-1"></i>戻る
                </button>
              )} 
            </div>
            <div>
              {step == 4 && (
                <button
                  type="button"
                  className="btn btn-md btn-primary"
                  onClick={clickSaveHandler}
                >
                  <span className="indicator-label">
                    設定登録する
                    <i className="ki-solid ki-arrow-right fs-3 ms-2 me-n1 mb-1"></i>
                  </span>
                </button>
              )}
              {step < 4 && (
                <button
                  type="button"
                  className="btn btn-md btn-primary"
                  onClick={clickNextHandler}
                >
                  次へ
                  <i className="ki-solid ki-arrow-right fs-3 ms-1 me-n1 mb-1"></i>
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default NewSetting;
