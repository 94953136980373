import React, { useCallback } from 'react';
import { Decimal } from 'decimal.js';

const EditableVariable = ({ foodCostRate, laborCostRate, otherCostRate, price, changeFoodCostRateHandler, changeLaborCostRateHandler }) => {
  const variableRate = useCallback(() => {
        
    if (foodCostRate != null && laborCostRate != null && otherCostRate != null) {
      const foodCostRateDecimal = new Decimal(foodCostRate);
      const laborCostRateDecimal = new Decimal(laborCostRate);
      const otherCostRateDecimal = new Decimal(otherCostRate);

      return foodCostRateDecimal.plus(laborCostRateDecimal).plus(otherCostRateDecimal).toNumber();
    }    

    return null;
  }, [foodCostRate, laborCostRate, otherCostRate]);

  const variableCost = useCallback(() => {
    if (price == null) {
      return null;
    }

    return price * variableRate() / 100;
  }, [price, variableRate]);

  const changeFoodHandler = (e) => {
    const newValue = e.currentTarget.value;
    changeFoodCostRateHandler(newValue ? parseFloat(newValue) : null);
  };

  const changeLaborHandler = (e) => {
    const newValue = e.currentTarget.value;
    changeLaborCostRateHandler(newValue ? parseFloat(newValue) : null);
  };

  return (
    <div className="card h-375px card-bordered rounded-0">
      <div className="card-header px-4 pt-1 border-bottom-0">
        <div className="card-title fs-9 text-gray-400">
          <div className="symbol symbol-30px me-3">
            <div className={`symbol-label bg-gray fs-6 fw-bolder text-white`}>V</div>
          </div>
          <div className="flex-column">
            <div className="text-gray-500 fs-9">Variable</div>
            <div className={`text-gray-600 fs-8`}>変動費単価</div>
          </div>
        </div>
      </div>
      <div className="card-body px-4 pb-4 pt-0">
        <div className={`fs-3 fw-bolder mb-5 ${variableCost() == null ? "text-gray-500" : ""}`}>
          ¥{variableCost() == null ? '--' : variableCost().toLocaleString()}
        </div>
        <div className={`fs-9 fw-bold ${variableRate() == null ? "text-gray-600" : ""} mb-0`}>変動費率</div>
        <div className={`fs-4 fw-bolder ${variableRate() == null ? "text-gray-600" : ""} mb-3`}>{variableRate() == null ? '--' : variableRate()}%</div>
        <div className="card-title mt-6">
          <h3 className="fw-bolder me-2 fs-8">仕入費率<small> ※小数点以下1桁</small></h3>          
        </div>
        <div className="pt-0 pb-0">
          <div className="input-group input-group-solid">
            <input
              type="number"
              step="0.1"
              className="form-control number-input"
              placeholder="入力"
              value={foodCostRate ?? ''}
              onChange={changeFoodHandler}
            />
            <span className="input-group-text">%</span>
          </div>
        </div>
        <div className="card-title mt-4">
          <h3 className="fw-bolder me-2 fs-8">PA人件費率<small> ※小数点以下1桁</small></h3>
        </div>
        <div className="pt-0 pb-0">
          <div className="input-group input-group-solid">
            <input
              type="number"
              step="0.1"
              className="form-control number-input"
              placeholder="入力"
              value={laborCostRate ?? ''}
              onChange={changeLaborHandler}
            />
            <span className="input-group-text">%</span>
          </div>
        </div>
        <div className={`fs-9 ${otherCostRate == null ? "text-gray-500" : "text-gray-600"} mb-0 mt-6`}>その他変動費率</div>
        <div className={`fs-6 fw-bolder ${otherCostRate == null ? "text-gray-500" : "text-gray-600"} mb-1`}>{otherCostRate == null ? '--' : otherCostRate}%</div>
      </div>
    </div>
  );
};

export default EditableVariable;
