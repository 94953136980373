import React from 'react';

const CostPerSalesRate = ({ foodCost, drinkCost, foodSales, drinkSales, foodCurrentMonthStock, foodPreviousMonthStock, drinkCurrentMonthStock, drinkPreviousMonthStock, totalCost, totalSales, totalCurrentMonthStock, totalPreviousMonthStock }) => {
  const rate = (cost, sales) => {
    if (sales <= 0) {
        return 0.0;
    }

    // 小数点1位まで
    return Math.round((cost / sales) * 1000) / 10;
  };

  return (
    <>
      <div className="col-xl-4 col-lg-4 col-sm-12 col-xs-12">
        <div className="card rounded card-bordered">
          <div className="card-body d-flex justify-content-between flex-column pb-10 px-9 pt-10">
            <div className="d-flex flex-stack pb-8">
              <div className="symbol symbol-30px me-4">
                <span className="symbol-label bg-light-primary">
                  <span className="svg-icon svg-icon-2 svg-icon-primary">
                    <span className="section-icon">
                      <i className="ki-duotone ki-chart-pie-too">
                      <i className="path1"></i>
                      <i className="path2"></i>
                      </i>
                    </span>
                  </span>
                </span>
              </div>
              <div className="d-flex align-items-center flex-stack flex-wrap flex-row-fluid d-grid gap-2">
                <div className="me-5">
                  <span className="text-gray-800 fw-bolder fs-5">食材仕入率/食材原価率</span>
                </div>
              </div>
            </div>
            <div className="row g-5">														
              <div className="d-flex align-items-center">
                <div className="col-sm-6 col-xs-6 pb-0">
                  <div className="d-flex mt-2 align-items-baseline">
                    <span className="fs-4qx fs-lg-3x fw-bolder text-gray-800 me-1 lh-1 ls-0">{rate(totalPreviousMonthStock + totalCost - totalCurrentMonthStock, totalSales)}</span>
                    <span className="fs-3 fw-bolder text-gray-800 me-0 lh-1">%</span>
                  </div>
                </div>
                <div className="col-sm-6 col-xs-6 pb-0 text-end">
                  <div className="mb-4">
                    <div className="fs-8 fw-bold text-gray-600 mt-0">食材前月在庫高</div>
                    <div className="fs-2 fs-lg-5 fs-md-3 fs-xl-4 fw-bolder lh-1 ls-0 mt-1 text-gray-700">¥{totalPreviousMonthStock.toLocaleString()}</div>
                  </div>
                  <div className="mb-4">
                    <div className="fs-8 fw-bold text-gray-600 mt-0">食材当月仕入</div>
                    <div className="fs-2 fs-lg-5 fs-md-3 fs-xl-4 fw-bolder lh-1 ls-0 mt-1 text-gray-700">¥{totalCost.toLocaleString()}</div>
                  </div>
                  <div className="mb-4">
                    <div className="fs-8 fw-bold text-gray-600 mt-0">食材当月在庫高</div>                
                    <div className="fs-2 fs-lg-5 fs-md-3 fs-xl-4 fw-bolder lh-1 ls-0 mt-1 text-gray-700">¥{totalCurrentMonthStock.toLocaleString()}</div>
                  </div>
                  <div>
                    <div className="fs-8 fw-bold text-gray-600 mt-0">売上</div>
                    <div className="fs-2 fs-lg-5 fs-md-3 fs-xl-4 fw-bolder lh-1 ls-0 mt-1 text-gray-700">¥{totalSales.toLocaleString()}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-xl-4 col-lg-4 col-sm-12 col-xs-12">
        <div className="card rounded card-bordered">
          <div className="card-body d-flex justify-content-between flex-column pb-10 px-9 pt-10">
            <div className="d-flex flex-stack pb-8">
              <div className="symbol symbol-30px me-4">
                <span className="symbol-label bg-light-primary">
                  <span className="svg-icon svg-icon-2 svg-icon-primary">
                    <span className="section-icon">
                      <i className="ki-duotone ki-moon">
                      <i className="path1"></i>
                      <i className="path2"></i>
                      </i>
                    </span>
                  </span>
                </span>
              </div>
              <div className="d-flex align-items-center flex-stack flex-wrap flex-row-fluid d-grid gap-2">
                <div className="me-5">
                  <span className="text-gray-800 fw-bolder fs-5">フード仕入率/フード原価率</span>
                </div>
              </div>
            </div>
            <div className="row g-5">														
              <div className="d-flex align-items-center">
                <div className="col-sm-6 col-xs-6 pb-0">
                  <div className="d-flex mt-2 align-items-baseline">
                    <span className="fs-4qx fs-lg-3x fw-bolder text-gray-800 me-1 lh-1 ls-0">{rate(foodPreviousMonthStock + foodCost - foodCurrentMonthStock, foodSales)}</span>
                    <span className="fs-3 fw-bolder text-gray-800 me-0 lh-1">%</span>
                  </div>
                </div>
                <div className="col-sm-6 col-xs-6 pb-0 text-end">
                  <div className="mb-4">
                    <div className="fs-8 fw-bold text-gray-600 mt-0">フード前月在庫高</div>
                    <div className="fs-2 fs-lg-5 fs-md-3 fs-xl-4 fw-bolder lh-1 ls-0 mt-1 text-gray-700">¥{foodPreviousMonthStock.toLocaleString()}</div>
                  </div>
                  <div className="mb-4">
                    <div className="fs-8 fw-bold text-gray-600 mt-0">フード当月仕入</div>
                    <div className="fs-2 fs-lg-5 fs-md-3 fs-xl-4 fw-bolder lh-1 ls-0 mt-1 text-gray-700">¥{foodCost.toLocaleString()}</div>
                  </div>
                  <div className="mb-4">
                    <div className="fs-8 fw-bold text-gray-600 mt-0">フード当月在庫高</div>                
                    <div className="fs-2 fs-lg-5 fs-md-3 fs-xl-4 fw-bolder lh-1 ls-0 mt-1 text-gray-700">¥{foodCurrentMonthStock.toLocaleString()}</div>
                  </div>
                  <div>
                    <div className="fs-8 fw-bold text-gray-600 mt-0">フード売上</div>
                    <div className="fs-2 fs-lg-5 fs-md-3 fs-xl-4 fw-bolder lh-1 ls-0 mt-1 text-gray-700">¥{foodSales.toLocaleString()}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-xl-4 col-lg-4 col-sm-12 col-xs-12">
        <div className="card rounded card-bordered">
          <div className="card-body d-flex justify-content-between flex-column pb-10 px-9 pt-10">
            <div className="d-flex flex-stack pb-8">
              <div className="symbol symbol-30px me-4">
                <span className="symbol-label bg-light-primary">
                  <span className="svg-icon svg-icon-2 svg-icon-primary">
                    <span className="section-icon">
                      <i className="ki-duotone ki-drop">
                      <i className="path1"></i>
                      <i className="path2"></i>
                      </i>
                    </span>
                  </span>
                </span>
              </div>
              <div className="d-flex align-items-center flex-stack flex-wrap flex-row-fluid d-grid gap-2">
                <div className="me-5">
                  <span className="text-gray-800 fw-bolder fs-5">ドリンク仕入率/ドリンク原価率</span>
                </div>
              </div>
            </div>
            <div className="row g-5">														
              <div className="d-flex align-items-center">
                <div className="col-sm-6 col-xs-6 pb-0">
                  <div className="d-flex mt-2 align-items-baseline">
                    <span className="fs-4qx fs-lg-3x fw-bolder text-gray-800 me-1 lh-1 ls-0">{rate(drinkPreviousMonthStock + drinkCost - drinkCurrentMonthStock, drinkSales)}</span>
                    <span className="fs-3 fw-bolder text-gray-800 me-0 lh-1">%</span>
                  </div>
                </div>
                <div className="col-sm-6 col-xs-6 pb-0 text-end">
                  <div className="mb-4">
                    <div className="fs-8 fw-bold text-gray-600 mt-0">ドリンク前月在庫高</div>
                    <div className="fs-2 fs-lg-5 fs-md-3 fs-xl-4 fw-bolder lh-1 ls-0 mt-1 text-gray-700">¥{drinkPreviousMonthStock.toLocaleString()}</div>
                  </div>
                  <div className="mb-4">
                    <div className="fs-8 fw-bold text-gray-600 mt-0">ドリンク当月仕入</div>
                    <div className="fs-2 fs-lg-5 fs-md-3 fs-xl-4 fw-bolder lh-1 ls-0 mt-1 text-gray-700">¥{drinkCost.toLocaleString()}</div>
                  </div>
                  <div className="mb-4">
                    <div className="fs-8 fw-bold text-gray-600 mt-0">ドリンク当月在庫高</div>                
                    <div className="fs-2 fs-lg-5 fs-md-3 fs-xl-4 fw-bolder lh-1 ls-0 mt-1 text-gray-700">¥{drinkCurrentMonthStock.toLocaleString()}</div>
                  </div>
                  <div>
                    <div className="fs-8 fw-bold text-gray-600 mt-0">ドリンク売上</div>
                    <div className="fs-2 fs-lg-5 fs-md-3 fs-xl-4 fw-bolder lh-1 ls-0 mt-1 text-gray-700">¥{drinkSales.toLocaleString()}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CostPerSalesRate;
