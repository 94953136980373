import React, { useCallback } from 'react';
import { Decimal } from 'decimal.js';

const Variable = ({ active, foodCostRate, laborCostRate, otherCostRate, price }) => {
  const variableRate = useCallback(() => {
    if (foodCostRate != null && laborCostRate != null && otherCostRate != null) {
      const foodCostRateDecimal = new Decimal(foodCostRate);
      const laborCostRateDecimal = new Decimal(laborCostRate);
      const otherCostRateDecimal = new Decimal(otherCostRate);

      return foodCostRateDecimal.plus(laborCostRateDecimal).plus(otherCostRateDecimal).toNumber();
    }

    return null;
  }, [foodCostRate, laborCostRate, otherCostRate]);

  const variableCost = useCallback(() => {
    if (price == null) {
      return null;
    }

    return Math.round(price * variableRate() / 100);
  }, [price, variableRate]);

  return (
    <div className="card h-275px card-bordered rounded-0">
      <div className="card-header px-4 pt-1 border-bottom-0">
        <div className="card-title fs-9 text-gray-400">
          <div className="symbol symbol-30px me-3">
            <div className={`symbol-label ${active ? "bg-gray" : ""} fs-6 fw-bolder text-white`}>V</div>
          </div>
          <div className="flex-column">
            <div className="text-gray-500 fs-9">Variable</div>
            <div className={`${active ? "text-gray-600" : "text-gray-500"} fs-8`}>変動費単価</div>
          </div>
        </div>
      </div>
      <div className="card-body px-4 pb-4 pt-0">
        <div className={`fs-3 fw-bolder ${variableCost() == null ? "text-gray-500" : ""} mb-3`}>
          ¥{variableCost() == null ? '--' : variableCost().toLocaleString()}
        </div>
        <div className={`fs-9 fw-bold ${variableRate() == null ? "text-gray-500" : ""} mb-0`}>変動費率</div>
        <div className={`fs-4 fw-bolder ${variableRate() == null ? "text-gray-500" : ""} mb-3`}>{variableRate() == null ? '--' : variableRate()}%</div>
        <div className={`fs-9 ${foodCostRate == null ? "text-gray-500" : "text-gray-600"} mb-0`}>仕入費率</div>
        <div className={`fs-6 fw-bolder ${foodCostRate == null ? "text-gray-500" : "text-gray-600"} mb-1`}>{foodCostRate == null ? '--' : foodCostRate}%</div>
        <div className={`fs-9 ${laborCostRate == null ? "text-gray-500" : "text-gray-600"} mb-0`}>PA人件費率</div>
        <div className={`fs-6 fw-bolder ${laborCostRate == null ? "text-gray-500" : "text-gray-600"} mb-1`}>{laborCostRate == null ? '--' : laborCostRate}%</div>
        <div className={`fs-9 ${otherCostRate == null ? "text-gray-500" : "text-gray-600"} mb-0`}>その他変動費率</div>
        <div className={`fs-6 fw-bolder ${otherCostRate == null ? "text-gray-500" : "text-gray-600"} mb-1`}>{otherCostRate == null ? '--' : otherCostRate}%</div>
      </div>
    </div>
  );
};

export default Variable;
