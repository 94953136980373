import React, { useEffect, useState } from 'react';
import moment from 'moment';
import ModalForm from './modal_form';
import axios from "../../../lib/axios";

const IndexShop = ({ month, shops, stockingTransfers, totalIn, totalOut }) => {
  const [draftStockingTransfer, setDraftStockingTransfer] = useState(null);

  const onClickNew = () => {
    setDraftStockingTransfer({
      id: '',
      transfer_date: '',
      counter_party_shop_id: '',
      transfer_type: 'out',
      price_excluded_tax: '',
      item_type: '',
    });
  };

  const onClickEdit = (transferId) => {
    const transfer = stockingTransfers.find((transfer) => {
      return transfer.id === transferId;
    });

    if (! transfer) {
      return;
    }

    setDraftStockingTransfer({
      ...transfer
    });
  };

  const deleteStockingTransfer = (transfer) => {
    axios.delete(`/stocking_transfers/${transfer.id}`).then((response) => {
      window.location.reload();
    }).catch((error) => {
      alert('削除に失敗しました');
    });
  };

  const onClickDelete = (transferId) => {
    const transfer = stockingTransfers.find((transfer) => {
      return transfer.id === transferId;
    });

    if (! transfer) {
      return;
    }

    Swal.fire({
      text: "この店舗間商品移動の記録を削除します。削除実行してもいいですか？",
      icon: "warning",
      buttonsStyling: false,
      showCancelButton: true,
      confirmButtonText: "削除する",
      cancelButtonText: 'キャンセル',
      customClass: {
        confirmButton: "btn btn-danger",
        cancelButton: 'btn btn-light-dark'
      }
    }).then((result) => {
      if (result.isConfirmed) {
        deleteStockingTransfer(transfer);
      }
    });
  };

  const onChangeMonth = (e) => {
    window.location.href = `/stocking_transfers?month=${e.currentTarget.value}`;
  };

  const renderMonths = () => {
    const currentDate = moment();
    const months = [];

    for (let i = 0; i < 12; i++) {
      const month = currentDate.clone().subtract(i, 'months');
      const monthString = month.format('YYYY年MM月');
      months.push({ value: month.format('YYYY-MM-01'), label: monthString });
    }

    return (
      <select
        className="form-select mw-auto"
        data-control="select2"
        data-hide-search="true"
        onChange={onChangeMonth}
        value={month ?? ''}
      >
        {months.map((month) => <option key={`month-${month.value}`} value={month.value}>{month.label}</option>)}
      </select>
    );
  };

  const renderList = () => {
    const transfers = [];
    stockingTransfers.forEach((transfer) => {
      const row = (
        <tr key={`transfer-${transfer.id}`}>
          <td className="text-gray-600 nowrap pe-4">{moment(transfer.transfer_date).format('YYYY年M月D日')}</td>
          <td>{transfer.counter_party_shop_name}</td>
          <td>
            {transfer.transfer_type === 'in' && (
              <>
                ¥{transfer.price_excluded_tax.toLocaleString()}
              </>
            )}
          </td>
          <td>
            {transfer.transfer_type === 'out' && (
              <>
                ¥{transfer.price_excluded_tax.toLocaleString()}
              </>
            )}
          </td>
          <td>{transfer.item_type_name}</td>
          <td className="text-end">
            <button
              className="btn btn-sm btn-icon btn-light-dark w-35px h-35px"
              data-bs-toggle="modal"
              data-bs-target="#modal_add_transaction"
              onClick={() => onClickEdit(transfer.id)}
            >
              <i className="ki-duotone ki-pencil fs-3 text-gray-600">
                <span className="path1"></span>
                <span className="path2"></span>
              </i>
            </button>
            <button
              className="btn btn-sm btn-icon btn-light-danger w-35px h-35px modal_delete_transaction"
              onClick={() => onClickDelete(transfer.id)}
            >
              <i className="ki-duotone ki-trash fs-3">
                <span className="path1"></span>
                <span className="path2"></span>
                <span className="path3"></span>
                <span className="path4"></span>
                <span className="path5"></span>
              </i>
            </button>
          </td>
        </tr>
      );
      transfers.push(row);
    });

    return (
      <table className="table align-middle table-row-bordered gy-3 gs-7">
        <thead>
          <tr className="fw-bold fs-8 text-gray-500">
            <th className="pt-6 pb-3">日付</th>
            <th className="min-w-150px pt-6 pb-3 nowrap">相手店舗</th>
            <th className="min-w-100px pt-6 pb-3">仕入金額（税抜）</th>
            <th className="min-w-100px pt-6 pb-3">提供金額（税抜）</th>
            <th className="min-w-150px pt-6 pb-3">商品タイプ</th>
            <th className="min-w-150px pt-6 pb-3 text-end">アクション</th>
          </tr>
        </thead>
        <tbody className="fw-bold">
          {transfers}
        </tbody>
        <tfoot>
          <tr className="fw-bold fs-6 border-top">
            <th colSpan="2" className="fw-bolder text-nowrap text-gray-600 pt-4">合計:</th>
            <th colSpan="1" className="fw-bolder text-gray-700 fs-5 pe-2">¥{totalIn.toLocaleString()}</th>
            <th colSpan="1" className="fw-bolder text-gray-700 fs-5 pe-2">¥{totalOut.toLocaleString()}</th>
            <th colSpan="2"></th>
          </tr>
        </tfoot>
      </table>
    );
  };

  return (
    <>
      <div className="content d-flex flex-column flex-column-fluid">
        <div className="mt-8 mb-6" id="kt_toolbar">
          <div id="kt_toolbar_container" className="container-xxl flex-stack">
            <div className="d-flex justify-content-between mb-6 align-items-center">
              <div className="page-title d-flex align-items-center flex-wrap mb-6 mb-sm-0">
                <h1 className="d-flex text-dark fw-bolder fs-4 align-items-center my-1">店舗間商品移動</h1>
              </div>
              <div className="d-flex align-items-center gap-2 gap-lg-3 flex-wrap">
                <div className="w-auto">
                  <button type="button"
                    className="btn btn-sm fs-7 fw-bold btn-primary nowrap"
                    data-bs-toggle="modal"
                    data-bs-target="#modal_add_transaction"
                    onClick={onClickNew}
                  >
                    <i className="ki-duotone ki-plus fs-4 ms-n1 me-0 mb-1"></i>
                    新規登録
                  </button>
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center">
              <div className="d-flex align-items-center gap-2 mobile-w-100">
                <div className="text-gray-600 fs-8 me-3 nowrap">表示データ</div>
                {renderMonths()}
              </div>
            </div>
          </div>
        </div>

        <div className="app-content flex-column-fluid">
          <div className="app-container container-xxl">
            <div className="card mb-5 mb-xl-10">
              <div className="card-body p-0">
                <div className="table-responsive">
                  {renderList()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ModalForm shops={shops} stockingTransfer={draftStockingTransfer} />
    </>
  );
};

export default IndexShop;
