import React, { Component } from 'react';
import PropTypes from 'prop-types'

export default class OrderFormData extends Component {  
  render() {
    const { shopVendor, baskets } = this.props;
    return (
      <>
        {baskets.map((basket, i) => {
          return (
            <div key={i}>
              { shopVendor ? <input type="hidden" name={"arrivals[" + i + "][shop_vendor_id]"} value={shopVendor.id} readOnly/> : "" }
              <input type="hidden" name={"arrivals[" + i + "][arrival_type]"} value={basket.arrivalType} readOnly/>
              { basket.order ? <input type="hidden" name={"arrivals[" + i + "][order_id]"} value={basket.order ? basket.order.id : ""} readOnly/> : "" }
              { basket.arrivalItems.map((arrivalItem, j) => {
                return (
                  <div key={j}>
                    <input type="hidden" name={"arrival_items[" + i + "][][id]"} value={arrivalItem.id} readOnly/>
                    <input type="hidden" name={"arrival_items[" + i + "][][name]"} value={arrivalItem.name} readOnly/>
                    <input type="hidden" name={"arrival_items[" + i + "][][official_item_id]"} value={arrivalItem.officialItemId} readOnly/>
                    { arrivalItem.orderNum ? <input type="hidden" name={"arrival_items[" + i + "][][order_num]"} value={arrivalItem.orderNum} readOnly/> : "" }
                    <input type="hidden" name={"arrival_items[" + i + "][][arrival_num]"} value={arrivalItem.arrivalNum} readOnly/>
                    <input type="hidden" name={"arrival_items[" + i + "][][price]"} value={arrivalItem.price} readOnly/>
                    { arrivalItem.taxRate ? <input type="hidden" name={"arrival_items[" + i + "][][tax_rate]"} value={arrivalItem.taxRate} readOnly/> : "" }
                    <input type="hidden" name={"arrival_items[" + i + "][][subtotal]"} value={arrivalItem.subtotal} readOnly/>
                    <input type="hidden" name={"arrival_items[" + i + "][][subtotal_tax_excluded]"} value={arrivalItem.subtotalTaxExcluded} readOnly/>
                    <input type="hidden" name={"arrival_items[" + i + "][][tax]"} value={arrivalItem.tax} readOnly/>
                  </div>
                )})
              }
            </div>
          )
        })}
      </>
    )
  }
}

OrderFormData.propTypes = {
  shopVendor: PropTypes.object,
  baskets: PropTypes.array.isRequired
}