import React, { useEffect, useState } from 'react';
import moment from "moment";

const Modal = ({ date, dailyBudget }) => {
  const dateString = () => {
    const d = moment(date);

    const dayOfWeeks = ['日', '月', '火', '水', '木', '金', '土'];

    return d.format('Y年 MM月DD日') + ` (${dayOfWeeks[d.weekday()]})`;
  };

  return (
    <div className="modal fade" id="kt_modal_daily_detail" tabIndex="-1" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered mw-500px">
        <div className="modal-content rounded">
          <div className="modal-header pb-0 border-0 justify-content-end">
            <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
              <span className="svg-icon svg-icon-2">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="currentColor" />
                  <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="currentColor" />
                </svg>
              </span>
            </div>
          </div>
          <div className="modal-body scroll-y px-10 px-lg-15 pt-0 pb-8">
            <div className="mb-13 d-flex align-items-center">
              <i className="ki-outline ki-calendar fs-1 me-3"></i>
              <div className="flex-column">
                <div className="fs-9 text-gray-500 fw-bolder">日次実績:</div>
                <div className="fs-3 fw-bolder">{dateString()}</div>
              </div>
            </div>
            <div className="d-flex flex-wrap d-grid mb-0 row">
              <div className="col-md-6 pb-6">
                <span className="fs-7 fw-bold text-gray-600">客数目標</span>
                <div className="d-flex mt-2 align-items-end">
                  <span className="fs-2x fw-boldest text-gray-800 me-1 lh-1 ls-0">
                    {dailyBudget == null ? '' : dailyBudget.customers.toLocaleString()}
                  </span>
                  <span className="fs-3 fw-boldest text-gray-800 lh-1">人</span>
                </div>
                <div className="separator mt-6"></div>
              </div>
              <div className="col-md-6 pb-6">
                <span className="fs-7 fw-bold text-gray-600">売上目標</span>
                <div className="d-flex mt-2">
                  <span className="fs-3 fw-boldest text-gray-800 me-1 lh-1">¥</span>
                  <span className="fs-2x fw-boldest text-gray-800 me-2 lh-1 ls-0">
                    {dailyBudget == null ? '' :  dailyBudget.sales.toLocaleString()}
                  </span>
                </div>
                <div className="separator mt-6"></div>
              </div>
              <div className="col-md-6 pb-6">
                <span className="fs-7 fw-bold text-gray-600">食材原価目標</span>
                <div className="d-flex mt-2">
                  <span className="fs-3 fw-boldest text-gray-800 me-1 lh-1">¥</span>
                  <span className="fs-2x fw-boldest text-gray-800 me-2 lh-1 ls-0">
                    {dailyBudget == null ? '' :  dailyBudget.food_cost.toLocaleString()}
                  </span>
                </div>
                <div className="separator mt-6"></div>
              </div>
              <div className="col-md-6 pb-6">
                <span className="fs-7 fw-bold text-gray-600">PA人件費目標</span>
                <div className="d-flex mt-2">
                  <span className="fs-3 fw-boldest text-gray-800 me-1 lh-1">¥</span>
                  <span className="fs-2x fw-boldest text-gray-800 me-2 lh-1 ls-0">
                    {dailyBudget == null ? '' :  dailyBudget.labor_cost.toLocaleString()}
                  </span>
                </div>
                <div className="separator mt-6"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
