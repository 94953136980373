import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ReactSpeedometer from "react-d3-speedometer"

const COLORS = ['#0088FE', '#FFFFFF'];

export default class SpeedmeterChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      result: props.result,
      budget: props.budget,
      values: [
        { name: 'Group A', value: props.value },
        { name: 'Group B', value: 100 - props.value }
      ]
    };
  }

  render() {
    return (
      <div align="center">
        { this.props.value === "development" ? (
          <div>
            <i class="fas fa-wrench" />
            &nbsp;開発中
          </div>
        ) : (
          <>
            <ReactSpeedometer
            maxValue={200}
            ringWidth={20}
            width={200}
            height={140}
            customSegmentStops={[
              0,
              50,
              100,
              150,
              200
            ]}
            segmentColors={["#ff422c", "#fc9536", "#eeda44", "#5fd744"]}
            needleHeightRatio={0.4}
            valueTextFontSize="18px"
            needleTransitionDuration={1000}
            needleTransition="ease"
            needleColor="#4a4a4a"
            currentValueText="${value}%"
            value={this.state.values[0]["value"]}
          />
          <small>
            実績：{this.state.result.toLocaleString()}円
            <br />
            予算：{this.state.budget.toLocaleString()}円      
          </small>
          </>
        )}
        
      </div>      
    );
  }
}
