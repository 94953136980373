import React from 'react';

const Loading = () => {
  return (
    <div style={{
      bottom: 0,
      display: 'flex',
      left: 0,
      position: 'fixed',
      right: 0,
      top: 0,
      zIndex: 100,
      overflow: 'hidden',
      alignItems: 'center',
      flexDirection: 'column',
      justifyContent: 'center'
    }}>
      <div className="modal-background" style={{ bottom: 0, left: 0, position: 'absolute', right: 0, top: 0, backgroundColor: 'rgba(0, 0, 0, 0.5)' }}></div>
      <div className="modal-content" style={{ margin: '0 auto', padding: '20px', width: '300px' }}>Loading...</div>
    </div>
  );
};

export default Loading;
