import React from 'react';
import Step0 from './step0';
import Step1 from './step1';

const Steps = ({ step, otherCost, otherCostRate, gain, changeGainHandler, foodCostRate, laborCostRate, changeFoodCostRateHandler, changeLaborCostRateHandler }) => {
  return (
    <div className="card d-flex justify-content-center justify-between flex-row-auto col-md-12 col-lg-12 col-xl-3 col-xxl-3">
      <div className="card-header border-bottom-1">
        <div className="card-title m-0">
          <h3 className="fw-bolder me-2 fs-4 mt-10 mb-5">月次予算設定ステップ</h3>
        </div>
      </div>
      <div className="card-body px-9 px-lg-9 px-xxl-9 py-10">
        <div className="stepper-nav">
          <Step0 step={step} otherCost={otherCost} otherCostRate={otherCostRate} />
          <Step1 step={step} gain={gain} changeGainHandler={changeGainHandler} />
          <div className={`stepper-item ${step == 2 ? 'current' : ''} pb-40`} data-kt-stepper-element="nav" data-kt-stepper-action="step">
            <div className="stepper-wrapper">
              <div className="stepper-icon w-40px h-40px">
                <i className="ki-duotone ki-check fs-2 stepper-check"></i>
                <span className="stepper-number">2</span>
              </div>
              <div className="stepper-label">
                <h3 className="stepper-title my-1">食材費比率 &amp; <br />PA人件費率</h3>
              </div>
            </div>
            <div className="stepper-line h-40px"></div>
          </div>
          <div className={`stepper-item ${step == 3 ? 'current' : ''} pb-40`} data-kt-stepper-element="nav" data-kt-stepper-action="step">
            <div className="stepper-wrapper">
              <div className="stepper-icon w-40px h-40px">
                <i className="ki-duotone ki-check fs-2 stepper-check"></i>
                <span className="stepper-number">3</span>
              </div>
              <div className="stepper-label">
                <h3 className="stepper-title my-1">客単価</h3>
              </div>
            </div>
            <div className="stepper-line h-40px"></div>
          </div>
          <div className={`stepper-item ${step == 4 ? 'current' : ''} pb-40`} data-kt-stepper-element="nav" data-kt-stepper-action="step">
            <div className="stepper-wrapper">
              <div className="stepper-icon w-40px h-40px">
                <i className="ki-duotone ki-check fs-2 stepper-check"></i>
                <span className="stepper-number">4</span>
              </div>
              <div className="stepper-label">
                <h3 className="stepper-title my-1">客数算出</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Steps;
